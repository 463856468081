import React, { useState } from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { Box, Grid, Typography } from "@mui/material";
import { filterDataByType } from "../hook/arrange";
import { GET_GALLERY } from "../graphql/other-queries";
import { useQuery } from "@apollo/client";

const Reprimanded = () => {
  const [gallery, setGallery] = useState([]);

  const { data: gallerydata } = useQuery(GET_GALLERY);

  const fileteredDataz = gallerydata?.getGallery
    ? filterDataByType(gallerydata.getGallery, "reprimanded")
    : [];

  // Get All Gallery
  React.useEffect(() => {
    if (gallerydata) {
      // console.log(gallerydata.getGallery);
      setGallery(fileteredDataz);
    }
  }, [gallerydata, fileteredDataz]);

  const isBigScreen = window.innerWidth > 700; // Adjust the breakpoint as needed

  // State variables to manage pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = isBigScreen ? 4 : 1; // Change this to the number of items you want per page

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = gallery.slice(startIndex, endIndex);
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/rules_lbjqwa.jpeg`}
        header="Reprimanded Content"
        text="Delving into previously criticized content for valuable insights and growth.        "
      />

      <Grid
        container
        spacing={{ xs: 0, sm: 2, md: 2 }}
        className="py-12 px-0 !mx-auto"
      >
        {currentPageData &&
          currentPageData.map((item) => (
            <Box className=" place-content-center !mx-auto py-4">
              <Grid item xs={12} md={6} className="mx-auto hidden md:block">
                <iframe
                  width="560"
                  height="315"
                  src={item.imgSrc}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                <Box className="w-fit">
                  <Typography
                    variant="caption"
                    fontSize={{ xs: "20px", sm: "20px" }}
                    letterSpacing={"0.02em"}
                    color="text.primary"
                    className="line-clamp-2 capitalize text-start w-full md:w-[500px]"
                    style={{ overflow: "hidden" }}
                  >
                    Title: {item.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} className="mx-auto md:hidden block">
                <iframe
                  width="300"
                  height="500"
                  src={item.imgSrc}
                  className="object-cover overflow-hidden mx-auto"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>

                <Box className="w-fit mx-auto items-center">
                  <Typography
                    variant="caption"
                    fontSize={{ xs: "20px", sm: "20px" }}
                    letterSpacing={"0.02em"}
                    color="text.primary"
                    className="line-clamp-2 !mx-auto capitalize text-center w-[90%] md:w-[500px]"
                    style={{ overflow: "hidden" }}
                  >
                    Title: {item.title}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          ))}
      </Grid>

      {/* Pagination controls */}
      <Box className="text-center mt-4 mx-auto flex  place-content-center ">
        {currentPage > 1 && (
          // <CRCbutton
          //   variant="outlined"
          //   color="primary"
          //   onClick={() => setCurrentPage(currentPage - 1)}
          //   label={"Prev"}
          // />
          <Box
            color="primary"
            aria-label="Next"
            onClick={() => setCurrentPage(currentPage - 1)}
            className="ml-2 !mr-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            Prev
          </Box>
        )}
        {endIndex < gallery.length && (
          <Box
            color="primary"
            aria-label="Next"
            onClick={() => setCurrentPage(currentPage + 1)}
            className="mr-2 !ml-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Reprimanded;
