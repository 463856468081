import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

const CRCTextFieldOutline = ({
  label,
  type,
  onChange,
  startAdornment,
  endAdornment,
  value,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    // Update the input value
    setInputValue(newValue);

    // Check for empty input and set error state
    if (newValue.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }

    // For email type, validate email format
    if (type === "email") {
      const isValidEmail =
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);
      setError(!isValidEmail);
    }

    // Call the provided onChange function
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Box
      component="div"
      sx={{
        "& .MuiTextField-root": {
          width: "100%",
          // maxWidth: "100%",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "20px",
        },
        "& .MuiInputLabel-root": {
          fontFamily: "Bricolage Grotesque",
        },
      }}
      noValidate
      autoComplete="on"
    >
      <TextField
        id="outlined"
        label={label}
        variant="outlined"
        value={value}
        type={"text"}
        onChange={handleInputChange}
        // onBlur={handleBlur}
        // error={error}
        // required
        // fullWidth
        multiline
        minRows={1}
        maxRows={3}
        // helperText={
        //   contentpic ? (
        //     <Typography
        //       variant="subtitle"
        //       lineHeight={{ xs: "200%", sm: "180%" }}
        //       letterSpacing={"0.02em"}
        //       color="error"
        //     >
        //       {contentpic}
        //     </Typography>
        //   ) : (
        //     ""
        //   )
        // }
        InputProps={{
          sx: {
            fontFamily: "Bricolage Grotesque",
            borderRadius: "30px",
            width: "100%",
          },
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: type === "text" && endAdornment,
        }}
        inputProps={{
          minLength: 1,
        }}
      />
    </Box>
  );
};

export default CRCTextFieldOutline;
