import React, { useContext, useState } from "react";
import {
  Box,
  FormGroup,
  Grid,
  Alert,
  CircularProgress,
  TextField,
} from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { LoaderContext } from "../../context/loader.context";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { USER_CHANGE_EMAIL } from "../../graphql/user-queries";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/app.context";

const ChangeEmailForm = () => {
  const { userData } = useAuth();
  let navigate = useNavigate();
  const loaderContext = useContext(LoaderContext);
  const [msg, setmsg] = useState("");
  const [email, setEmail] = useState("");
  const [newemail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  // Using the LOGIN_ADMIN query conditionally based on submitClicked
  // const { loading, error, data } = useQuery(USER_CHANGE_EMAIL, {
  //   variables: { email: email, newemail: newemail, password: password },
  //   skip: !submitClicked,
  //   onCompleted: (data) => {
  //     if (data && data.changeUserEmail) {
  //       openLoader();
  //       const newEmail = data.changeUserEmail.email;
  //       console.log("Data", data);
  //       console.log("New Email", newEmail);
  //       setmsg(newEmail);
  //       closeLoader();
  //     }
  //   },
  // });

  const [changeUserEmail, { loading, error, data }] =
    useMutation(USER_CHANGE_EMAIL);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newemail === "" || password === "") {
      // console.log("Empty fields:");
      setmsg("Please input all fields");
      return;
    }

    try {
      const {
        loading,
        error: emailError,
        data,
      } = await changeUserEmail({
        variables: {
          email: userData.email === "" ? email : userData.email,
          newemail: newemail,
          password: password,
        },
      });

      openLoader();
      console.log("Error", emailError);

      if (data.changeUserEmail.email === null) {
        console.log("Error", emailError);
        setmsg("Email / Password does not exist or New Email is taken");
        console.log(msg, "Why me");
        closeLoader();
      } else {
        closeLoader();
        setmsg("Email updated successfully");
        setTimeout(() => {
          navigate(`/signin`);
        }, 3000);
      }
    } catch (error) {
      // Display error alert for server error
      console.error("Update error:", error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full md:w-3/4 lg:w-2/5">
      <FormGroup className="">
        {msg === "Email / Password does not exist or New Email is taken" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : msg === "Please input all fields" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : msg === "Email updated successfully" ? (
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        ) : null}
        {loading && <CircularProgress />} {/* Display loading indicator */}
        <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className="">
          <Grid item xs={12} md={12}>
            {userData.email === "" ? (
              <CRCTextField
                label="Old Email"
                name="email"
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setmsg("");
                }}
              />
            ) : (
              <Box className="pl-2">
                <TextField
                  fullWidth
                  variant="standard"
                  // value={userData.email}
                  placeholder={userData.email}
                  label={userData.email === "" ? "" : userData.email}
                  name="dob"
                  type="name"
                  disabled
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <CRCTextField
              label="New Email"
              name="newemail"
              type="newemail"
              onChange={(e) => {
                setNewEmail(e.target.value);
                setmsg("");
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CRCTextField
              label="New Password"
              name="password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
                setmsg("");
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box className="mx-auto mt-8">
              <CRCbutton label={"Submit"} type={"submit"} />
            </Box>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default ChangeEmailForm;
