// Function to filter data for the current month
export const filterDataByCurrentMonth = (data) => {
  const currentDate = new Date();
  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.dob);
    return itemDate.getMonth() === currentDate.getMonth();
  });
  return filteredData;
};

export const sortByMutableDescending = (data, date) => {
  // Convert the data array to a mutable array using slice()
  const mutableData = data.slice();

  // Sort the mutable array
  mutableData.sort((a, b) => {
    const dateA = new Date(a[date]);
    const dateB = new Date(b[date]);
    return dateB - dateA;
  });

  return mutableData;
};

export const sortByMutableAscending = (data, date) => {
  // Convert the data array to a mutable array using slice()
  const mutableData = data.slice();

  // Sort the mutable array
  mutableData.sort((a, b) => {
    const dateA = new Date(a[date]);
    const dateB = new Date(b[date]);
    return dateA - dateB;
  });

  return mutableData;
};

export const sortByCreatedAtDescending = (data, sortOption) => {
  return data.sort((a, b) => {
    const dateA = new Date(a[sortOption]);
    const dateB = new Date(b[sortOption]);
    return dateB - dateA;
  });
};

export const sortByCreatedAtAscending = (data) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  });
};

export function filterDataByNotType(data, targetType) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data
  const filteredData = data.filter((item) => item.type !== targetType);
  return filteredData;
}

export function filterDataByType(data, targetType) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the targetType
  const filteredData = data.filter((item) => item.type === targetType);
  return filteredData;
}

export function filterArrayDataByNotTypes(data, targetTypes) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the target types
  const filteredData = data.filter((item) => !targetTypes.includes(item.type));
  return filteredData;
}

export function filterArrayDataByTypes(data, targetTypes) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the target types
  const filteredData = data.filter((item) => targetTypes.includes(item.type));
  return filteredData;
}

export function filterDataByCateg(data, targetCateg) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the targetCateg
  const filteredData = data.filter((item) => item.category === targetCateg);
  return filteredData;
}

// date function e.g OCT 12, 2023
export function convertUTCToDate(utcTimestamp) {
  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const date = new Date(utcTimestamp);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear(); // Get the year

  const formattedDateTime = `${month.slice(0, 3)} ${day}, ${year}`;
  return formattedDateTime;
}
