import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import "../../../src/CustomEditor.css";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import { useParams } from "react-router-dom";
import { ADD_ARTICLE_COMMENTS, GET_ARTICLE } from "../../graphql/other-queries";
import {
  Box,
  CssBaseline,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ForumHeader from "../../pages/user/forum/ForumHeader";
import { formatDay } from "../../hook/date-format";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CRCTextFieldOutline from "../../custom/Forms/CRCTextFieldOutline";
import { EmojiEmotions, Send } from "@mui/icons-material";
import CRCbutton from "../../custom/Forms/CRCbutton";
import CRCTextField from "../../custom/Forms/CRCTextField";
import dayjs from "dayjs";
import Cookies from "universal-cookie";
import ShareContent from "../../custom/Layout/ShareContent";

const ViewArticle = () => {
  const params = useParams();

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [show, setShow] = useState(true);
  const cookies = new Cookies();

  const token = cookies.get("crc-artCommenter-token");

  const [formData, setFormData] = useState({
    content: "",
    name: token || "", // Set to an empty string if token is falsy
    articleId: Number(params.id),
  });

  const [commentContent, setCommentContent] = useState("");
  const [commenterName, setCommenterName] = useState(token || "");

  const handleEmojiClick = (emoji) => {
    const emojiNative = emoji.native || "";
    setCommentContent((prevContent) => prevContent + emojiNative);
  };

  //   Query to fetch getArticlesByID
  const {
    loading,
    error,
    data: articleData,
    refetch,
  } = useQuery(GET_ARTICLE, {
    variables: { id: params.id },
    pollInterval: 500,
  });
  const checkToken = () => {
    if (token || token === "") {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  React.useEffect(() => {
    // Call checkToken immediately
    checkToken();

    // Set up an interval to call checkToken every 5 seconds
    const intervalId = setInterval(checkToken, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [token]);

  // Add a comment on Post
  const [
    addArtComment,
    { loading: loadingComment, error: errorComment, data: dataComment },
  ] = useMutation(ADD_ARTICLE_COMMENTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  let post = articleData.getArticlesById;
  // console.log(post.artcomments);

  const handleCommentContentChange = (e) => {
    setCommentContent(e.target.value);
  };

  const handleCommenterNameChange = (e) => {
    setCommenterName(e.target.value);
  };

  const commenter = async (event) => {
    event.preventDefault();

    // Check if any of the form fields are empty
    if (!commentContent) {
      // Display an error or prevent the submission
      return;
    }

    try {
      const { data } = await addArtComment({
        variables: {
          content: commentContent,
          name: commenterName,
          articleId: Number(params.id),
        },
      });
      refetch();

      if (!token) {
        cookies.set("crc-artCommenter-token", commenterName, { path: "/" });
      }

      // Clear the comment content field after submission
      setCommentContent("");

      // Handle successful form submission (if needed)
    } catch (error) {
      // Display error alert for server error
      console.error("Commenting error:", error.message);
    }
  };

  // console.log(artCommenterToken, "Token");

  return (
    <React.Fragment>
      <Box className={`relative quillEditor`}>
        {/* <Box className={`relative ${styles.customEdit}`}> */}
        <ForumHeader
          imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/desks_eriscm.jpg`}
          header={post.title}
          date={formatDay(post.createdAt)}
          author={post.author}
        />
        <CssBaseline />

        <Box className="bg-white lg:px-52 md:px-16 px-6 py-12">
          <Box className=" mx-auto place-content-center w-full h-full py-4">
            <img
              className="object-cover bg-cover place-content-center mx-auto h-full md:w-full"
              src={post.contentpic}
              alt={post.title}
              title="Click to view more"
            />
          </Box>
          <Box className="flex place-content-end pt-3 pb-10">
            <ShareContent
              contentUrl={`https://celestialrealitycheck.org/blog/${post.slug}/${post.id}`}
            />
          </Box>

          {/* Article Editor Content */}

          <Box className="overflow-hidden">
            <div
              style={{ wordWrap: "break-word", whiteSpace: "normal" }}
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </Box>
          <Box className="py-10 grid ">
            <Typography
              variant="header"
              fontSize={{ xs: "20px", sm: "30px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="mx-auto capitalize underline opacity-60 text-center place-content-center pt-2 "
            >
              Comments
            </Typography>
          </Box>
          {post.artcomments &&
            post.artcomments.map((artcomment, index) => (
              <Box key={index}>
                <Box className=" my-6 bg-white shadow-lg drop-shadow-lg rounded-md mr-2 p-4">
                  <Typography
                    variant="body"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "13px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                  >
                    {artcomment.content}
                  </Typography>

                  <Box
                    className=" place-content-end right-0 flex gap-2 overflow-hidden  "
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Box className="ml-auto mr-0">
                      <Typography
                        variant="caption"
                        lineHeight={{ xs: "230%", sm: "102%" }}
                        letterSpacing={"0.1em"}
                        fontSize={{ xs: "13px", sm: "13px" }}
                        className=" opacity-60"
                      >
                        {`${artcomment.name}`} |{" "}
                        {dayjs(artcomment.createdAt).format("h:mma")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}

          <Box className=" md:px-32 lg:px-40 gap-4">
            <Box
              className={`relative ${
                formData.content === ""
                  ? "w-[80%] md:w-[90%] ease-in duration-300"
                  : "w-[80%] md:w-[90%]"
              }`}
            >
              {show === true ? (
                <CRCTextField
                  label="Full Name"
                  name="name"
                  type="name"
                  value={commenterName}
                  onChange={handleCommenterNameChange}
                />
              ) : null}
              {/* Adjust the column layout as needed */}
              <CRCTextFieldOutline
                label={`${commentContent === "" ? "" : ""}`}
                sx={{ width: "10%" }}
                name="content"
                type="text"
                value={commentContent}
                onChange={handleCommentContentChange}
                endAdornment={
                  <IconButton
                    color="primary"
                    onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}
                  >
                    <EmojiEmotions className="!text-gray-400 !text-2xl" />
                  </IconButton>
                }
              />
              <Fab
                color="primary"
                aria-label="add"
                // className=""
                className="!absolute bottom-2 -right-20 md:-right-16"
                onClick={commenter}
                title="Add a comment"
              >
                <Send className="text-white !text-3xl" title="Add a comment" />
              </Fab>
            </Box>
          </Box>

          {isEmojiPickerOpen && (
            <Picker
              data={data}
              onEmojiSelect={handleEmojiClick}
              style={{
                position: "fixed", // Change this to "fixed"
                marginTop: "-280px", // Adjust the positioning as needed
                right: "12px", // Position it next to the input
                maxWidth: "320px",
                borderRadius: "20px",
                zIndex: 1, // Ensure it's above other elements
              }}
              theme="auto" // auto or dark or light
            />
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ViewArticle;
