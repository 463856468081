import React, { useState } from "react";
import { createContext } from "react";

export const LoaderContext = createContext();
export default function LoaderProvider({ children }) {
  const [showState, setShowState] = useState(false);

  return (
    <LoaderContext.Provider value={{ showState, setShowState }}>
      {children}
    </LoaderContext.Provider>
  );
}
