import React from "react";
import Header from "../components/Home/Header";
import Features from "../components/Home/Features";
import { Box } from "@mui/material";
import ArtEvents from "../components/Home/ArtEvents";
import SubscribeCard from "../components/Home/SubscribeCard";
import { LoaderContext } from "../context/loader.context";
import Celebrant from "../components/Home/Celebrant";
import { HotGist } from "../components/Home/HotGist";
import Reprimanded from "../components/Home/Reprimanded";
import { Carousel } from "../components/Home/Carousel";
import PartnersMarquee from "../components/Home/PartnersMarquee";

const Home = () => {
  const loaderContext = React.useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };
  return (
    <React.Fragment>
      <Box className="relative">
        {/* <Header /> */}
        <Carousel />
        <Features />
        <HotGist />
        <PartnersMarquee />
        <ArtEvents />
        <Celebrant />
        <Reprimanded />
        <SubscribeCard />

        {/* <Box className="mx-auto mt-8 p-4 flex gap-24 place-content-center">
          <CRCbutton rounded label={"Open Loader"} onClick={openLoader} />
          <CRCbutton rounded onClick={closeLoader} label={"Close Loader"} />
        </Box> */}
      </Box>
    </React.Fragment>
  );
};

export default Home;
