import { Box, Typography } from "@mui/material";
import React from "react";

const ProfileInteraction = ({ count, text }) => {
  return (
    <React.Fragment>
      <Box className="flex flex-col items-center p-6 mt-1 md:p-2 lg:p-4 ">
        <Typography
          variant="header"
          fontSize={{ xs: "20px", sm: "32px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          color="primary"
          className=" "
        >
          {count}
        </Typography>
        <Typography
          variant="body"
          fontSize={{ xs: "12px", sm: "16px", md: "20px" }}
          lineHeight={{ xs: "100%", sm: "120%" }}
          letterSpacing={"0.02em"}
          color="primary"
          className=" text-center w-20 md:w-full"
        >
          {text}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default ProfileInteraction;
