import { Box, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { mainABoutData } from "../../static/static";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PARTNERS } from "../../graphql/user-queries";
import { sortByMutableAscending } from "../../hook/arrange";

const MainCard = () => {
  let location = useLocation();
  //   Query to fetch getEventsByID
  const { loading, error, data } = useQuery(GET_PARTNERS, {
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // Ensure data.getEvents exists before using it
  const partners = data?.getPartners || [];

  // Use the sorting function to sort the data
  const newData = sortByMutableAscending(partners, "createdAt");
  // console.log(newData);

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid
        container
        spacing={2}
        className="py-12 px-12 mx-auto place-content-center"
      >
        {newData &&
          newData.map((item, index) => (
            <>
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  elevation={3}
                  className="py-6 rounded-2xl shadow-md drop-shadow-md h-80 w-full"
                >
                  <Link
                    to={`${location.pathname}/${item.slug}/${item.id}`}
                    className=" !no-underline"
                  >
                    <img
                      className="object-cover bg-cover rounded-2xl place-content-center mx-auto h-32 w-32"
                      src={item.imgSrc}
                      alt={item.name}
                      title={item.name}
                    />
                  </Link>
                  <Box className=" py-4 px-10 md:px-4 mx-auto text-center grid">
                    <Link
                      to={`${location.pathname}/${item.slug}/${item.id}`}
                      className=" !no-underline"
                    >
                      <Typography
                        variant="subheader"
                        fontSize={{ xs: "18px", sm: "18px" }}
                        lineHeight={{ xs: "120%", sm: "120%" }}
                        letterSpacing={"0.02em"}
                        className=" capitalize "
                      >
                        {item.name}
                      </Typography>
                    </Link>

                    <Typography
                      variant="body"
                      fontSize={{ xs: "16px", sm: "16px" }}
                      lineHeight={{ xs: "150%", sm: "150%" }}
                      letterSpacing={"0.02em"}
                      className="opacity-60 line-clamp-3 text-center"
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </>
          ))}
      </Grid>
    </React.Fragment>
  );
};

export default MainCard;
