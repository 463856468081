import React from "react";
import {
  Paper,
  Grid,
  CssBaseline,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import ChatUI from "./ChatUI";
import OtherPosts from "./OtherPosts";
import { Chat, EmojiEmotions, Favorite, ThumbUp } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_POST,
  GET_POSTS,
  GET_POST_REACTIONS,
} from "../../../graphql/other-queries";
import ForumHeader from "./ForumHeader";
import CRCbutton from "../../../custom/Forms/CRCbutton";
import ReadMore from "../../../components/Article/ReadMore";
import OtherPostsMobile from "./OtherPostsMobile";
import CRCDialog from "../../../custom/Dialog/CRCDialog";

const ForumRoom = () => {
  const params = useParams();
  // console.log("Params Slug", params.slug);
  // console.log("Params ID ", params.id);
  // // Query to fetch getPostsID
  // Use the useQuery hook for the second query
  const {
    loading: reactionLoading,
    data: reactionData,
    error: reactionError,
  } = useQuery(GET_POST_REACTIONS, {
    variables: { categoryId: params.id },
  });

  // Query to fetch getPostsID
  const {
    loading: postsLoading,
    error: postsError,
    data: postsData,
  } = useQuery(GET_POSTS, {
    pollInterval: 500,
  });

  const [modal, setModal] = React.useState(false);

  // console.log("postArray", postArray);

  // Query to fetch getPostsID
  const { loading, error, data } = useQuery(GET_POST, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // console.log("Post by params", data.getPostById);
  let post = data.getPostById;

  if (reactionLoading) return <p>Loading...</p>;
  if (reactionError) return <p>Error : {reactionError.message}</p>;

  // console.log(reactionData.getPostReaction);

  //   Filter reactions by like
  const totalLike = reactionData.getPostReaction.filter(
    (like) => like.type === "like"
  ).length;

  //   Filter reactions by like
  const totalLaugh = reactionData.getPostReaction.filter(
    (laugh) => laugh.type === "laugh"
  ).length;

  //   Filter reactions by like
  const totalLove = reactionData.getPostReaction.filter(
    (love) => love.type === "love"
  ).length;

  //   Filter Commennts
  const totalComment = post && post.comments ? post.comments.length : 0;

  const handleOpen = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <ForumHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/forum_mbfwym.jpg`}
        header={post.title}
        text={post.content}
        icon={
          <Box display={"flex"} alignItems="center">
            <Box>
              <IconButton
                type="submit"
                color="primary"
                // className="!p-3 !bg-blue-500 drop-shadow-2xl shadow-2xl"
              >
                <EmojiEmotions className="!text-gray-200 !text-xl " />
                <Typography
                  variant="subtitle"
                  fontSize={{ xs: "16px", sm: "20px" }}
                  // lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  color="white"
                  className="opacity-80 pl-1"
                >
                  {totalLaugh}
                </Typography>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                type="submit"
                color="primary"
                // className="!p-3 !bg-blue-500 drop-shadow-2xl shadow-2xl"
              >
                <ThumbUp className="!text-gray-200 !text-xl " />
                <Typography
                  variant="subtitle"
                  fontSize={{ xs: "16px", sm: "20px" }}
                  // lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  color="white"
                  className="opacity-80 pl-1"
                >
                  {totalLike}
                </Typography>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                type="submit"
                color="primary"
                // className="!p-3 !bg-blue-500 drop-shadow-2xl shadow-2xl"
              >
                <Favorite className="!text-gray-200 !text-xl " />
                <Typography
                  variant="subtitle"
                  fontSize={{ xs: "16px", sm: "20px" }}
                  // lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  color="white"
                  className="opacity-80 pl-1"
                >
                  {totalLove}
                </Typography>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                type="submit"
                color="primary"
                // className="!p-3 !bg-blue-500 drop-shadow-2xl shadow-2xl"
              >
                <Chat className="!text-gray-200 !text-xl" />
                <Typography
                  variant="subtitle"
                  fontSize={{ xs: "16px", sm: "20px" }}
                  // lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  color="white"
                  className="opacity-80 pl-1"
                >
                  {totalComment}
                </Typography>
              </IconButton>
            </Box>
          </Box>
        }
      />
      <CssBaseline />
      <Paper elevation={3} className="">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4} md={3}>
            {/* Other Posts */}
            {/* <div className="hidden md:block"> */}
            <OtherPosts />
            {/* </div> */}
            <div className="block md:hidden w-fit mx-auto mt-2 !drop-shadow-2xl p-2 rounded bg-slate-300">
              <ReadMore
                // to={`${location.pathname}/${post.slug}/${post.id}`}
                className="cursor-pointer "
                label={"Other Posts"}
                onClick={() => handleOpen()}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            {/* pass comments as props for ForumComment according to Post by id used in Post details */}
            <ChatUI />
          </Grid>
        </Grid>

        <CRCDialog title={"All Posts"} onClose={handleClose} open={modal}>
          <OtherPostsMobile handleClose={handleClose} />
        </CRCDialog>
      </Paper>
    </>
  );
};

export default ForumRoom;
