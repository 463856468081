import React, { useState } from "react";
import { createContext } from "react";

export const SessionModalContext = createContext();
export default function SessionModalProvider({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <SessionModalContext.Provider value={{ open, setOpen }}>
      {children}
    </SessionModalContext.Provider>
  );
}
