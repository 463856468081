import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green, red } from "@mui/material/colors";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { formatDay } from "../../hook/date-format";
import CRCDialog from "../../custom/Dialog/CRCDialog";
import ReadMore from "../Article/ReadMore";
import { convertUTCToDate } from "../../hook/arrange";

export default function EventCard({ church, date, image, content, venue }) {
  const [open, setOpen] = React.useState(false);
  // const [programme, setProgramme] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className=" grid">
      <Card
        // sx={{ width: 400, height: 450 }}
        className="mx-auto shadow-2xl drop-shadow-2xl w-72  md:w-[350px] md:h-[440px] lg:w-[400px]"
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "secondary.light" }} aria-label="recipe">
              <EventOutlinedIcon />
            </Avatar>
          }
          title={
            <Typography
              variant="subheader"
              fontSize={{ xs: "13px", sm: "15px", md: "15px" }}
              // lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className=" capitalize line-clamp-1"
              title="Click to view more"
            >
              {church}
            </Typography>
          }
          subheader={convertUTCToDate(date)}
        />
        <CardMedia
          component="img"
          image={image}
          alt="Event Image"
          className="h-64 w-full object-cover"
        />
        <CardContent className="grid">
          <Typography
            variant="body"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className=" opacity-60 line-clamp-1"
          >
            <b>Theme:</b> {content}
          </Typography>
          <Typography
            variant="body"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="opacity-60 line-clamp-1"
          >
            <b> Venue: </b>
            {venue.includes("http://") || venue.includes("https://") ? (
              <Link
                to={venue}
                target="_blanc"
                className="!no-underline text-purple-500"
              >
                {venue}
              </Link>
            ) : (
              venue
            )}
          </Typography>
          <ReadMore
            onClick={() => handleClickOpen()}
            className="cursor-pointer sidebar-glide w-[37%] lg:w-[26%] md:w-[30%]"
            label={"Read More"}
          />
        </CardContent>
      </Card>

      <CRCDialog title={church} onClose={handleClose} open={open}>
        <Card
          sx={{ maxWidth: 500 }}
          className="mx-auto shadow-2xl drop-shadow-2xl"
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "secondary.light" }} aria-label="recipe">
                <EventOutlinedIcon />
              </Avatar>
            }
            subheader={convertUTCToDate(date)}
          />
          <CardMedia component="img" image={image} alt="Event Image" />
          <CardContent className="grid">
            <Typography
              variant="body"
              fontSize={{ xs: "13px", sm: "13px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="opacity-60"
            >
              <b>Theme:</b> {content}
            </Typography>
            <Typography
              variant="body"
              fontSize={{ xs: "13px", sm: "13px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="opacity-60"
            >
              <b> Venue: </b>
              {venue.includes("http://") || venue.includes("https://") ? (
                <Link
                  to={venue}
                  target="_blanc"
                  className=" !no-underline text-purple-500"
                >
                  {venue}
                </Link>
              ) : (
                venue
              )}
            </Typography>
          </CardContent>
        </Card>
      </CRCDialog>
    </Box>
  );
}
