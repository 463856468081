import { Box, Typography } from "@mui/material";
import React from "react";

const TextHeader = ({ header, text }) => {
  return (
    <React.Fragment>
      <Box className=" grid text-center ml-auto mr-auto">
        <Typography
          variant="subheader"
          fontSize={{ xs: "25px", sm: "30px" }}
          lineHeight={{ xs: "120%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="mx-auto capitalize place-content-center pt-2 "
        >
          {header}
        </Typography>
        <Typography
          variant="subtitle"
          fontSize={{ xs: "15px", sm: "18px" }}
          lineHeight={{ xs: "150%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="!mx-auto place-content-center opacity-60 md:w-[80%] w-[95%] pt-2"
        >
          {text}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default TextHeader;
