import { Box, CssBaseline, Grid, Typography } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";
import CRCDialog from "../../custom/Dialog/CRCDialog";
import { Link } from "react-router-dom";

const UserCard = ({ users }) => {
  const [open, setOpen] = React.useState(false);
  const [person, setPerson] = React.useState("");

  const handleClickOpen = (clickedUser) => {
    setPerson(clickedUser);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid
        container
        spacing={2}
        className="!mt-24 md:!mx-auto place-content-center"
      >
        {users.map((user, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Link onClick={() => handleClickOpen(user)}>
              <img
                className="object-cover shadow-md drop-shadow-md bg-cover rounded-2xl place-content-center mx-auto h-64 w-64"
                src={user.avatar}
                alt={user.firstname}
                title="Click to view more"
              />
            </Link>
            <Box className=" py-4 mx-auto text-center flex place-content-center">
              <Link
                onClick={() => handleClickOpen(user)}
                className=" !no-underline"
              >
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "18px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" capitalize sidebar-glide"
                  title="Click to view more"
                >
                  {user.firstname} {user.lastname}
                </Typography>
              </Link>

              <Box className=" pt-1 pl-1">
                {user.social && (
                  <a
                    href={user.social}
                    target="_blank"
                    rel="noopener noreferrer "
                    className="hover:cursor-pointer"
                  >
                    <FacebookOutlinedIcon sx={{ color: "#1877F2" }} />
                  </a>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <CRCDialog
        title={`${person.firstname} ${person.lastname}`}
        onClose={handleClose}
        open={open}
      >
        <Box className="grid">
          <Typography
            variant="body"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className=""
          >
            {person.bio}
          </Typography>
        </Box>
      </CRCDialog>
    </React.Fragment>
  );
};

export default UserCard;
