// AuthContext.js
import React, { createContext, useContext, useState } from "react";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../graphql/user-queries";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const cookies = new Cookies();
  const initialToken = cookies.get("crc-user-token") || null;

  const [token, setToken] = useState(initialToken);

  const [shouldReload, setShouldReload] = useState(false);

  React.useEffect(() => {
    if (shouldReload) {
      setShouldReload(false); // Reset shouldReload once the page reloads
      window.location.reload();
    }
  }, [shouldReload]);

  const loginUser = (newToken) => {
    setToken(newToken);
    cookies.set("crc-user-token", newToken, { path: "/" });
  };

  const logoutUser = () => {
    setToken(null);
    cookies.remove("crc-user-token");
    setShouldReload(true);
  };

  const isAuthenticated = !!token;

  React.useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      const jwtToken = cookies.get("crc-user-token");
      if (jwtToken) {
        // Parse the JWT token to get its payload
        const tokenParts = jwtToken.split(".");
        if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));
          // Get the expiration timestamp from the payload
          const expirationTime = payload.exp * 1000; // Convert to milliseconds
          // console.log("Expiration time", expirationTime);
          // Compare with current time
          if (expirationTime <= Date.now()) {
            // Token has expired, log the user out
            logoutUser();
          }
        }
      }
    };

    // Check token expiration every 10sec
    const interval = setInterval(checkTokenExpiration, 100);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  let secretKey = process.env.REACT_APP_SECRET_KEY;

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token, secretKey);
      const userId = decodedToken.userId;
      return userId;
    } catch (error) {
      // console.error("Error decoding token:", error);
      return null;
    }
  };

  const userId = getUserIdFromToken(token);

  // Using the GET_USER query
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: userId },
    pollInterval: 500,
  });

  // if (error) {
  //   console.error("GraphQL Error", error);
  // }

  // console.log("Debug", data);

  const [userData, setUserData] = useState({
    email: "",
    firstname: "",
    lastname: "",
    id: "",
    avatar: "",
    gender: "",
    country: "",
    dob: "",
    social: "",
    createdAt: "",
    totalInteractions: 0,
  });
  // if (data) {
  //   console.log("My User Data", data.getUserById);
  // }

  React.useEffect(() => {
    if (data && data.getUserById) {
      const user = data.getUserById; // Assuming getUserById is the correct field name
      setUserData((prevUserData) => ({
        ...prevUserData,
        id: user.id,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        avatar: user.avatar,
        gender: user.gender,
        dob: user.dob,
        country: user.country,
        social: user.social,
        createdAt: user.createdAt,
        totalInteractions: user.totalInteractions,
      }));
    }
  }, [data]);

  React.useEffect(() => {
    if (data && data.getUserById === null) {
      // console.log("User Not Found");
      setToken(null);
      cookies.remove("crc-user-token");
    }
  }, [data]);

  React.useEffect(() => {
    if (userData.firstname !== "") {
      let name = userData.firstname + " " + userData.lastname;
      // console.log("Name", name);
      cookies.set("crc-artCommenter-token", name, { path: "/" });
    }
  }, [userData]);

  return (
    <AuthContext.Provider
      value={{
        token,
        userData,
        isAuthenticated,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
