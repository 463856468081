import React, { useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Theaters from "@mui/icons-material/Theaters";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { pagesExtraIcon } from "../../static/static";

const AppDrawerSelector = ({ label, values, placeholder, onClick }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [toggleState, setToggleState] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleToggle = (event) => {
    setToggleState(!toggleState);
  };

  const isBigScreen = window.innerWidth > 700; // Adjust the breakpoint as needed

  return (
    <React.Fragment>
      <Link
        style={{
          marginTop: 8,
          color: "#FFC107",
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "transparent",
            color: "#FFC107",
          },
        }}
        onClick={handleToggle}
      >
        <Typography
          variant={isBigScreen ? "subtitle" : "body"}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          color={"primary"}
          className=" mx-auto pl-3"
        >
          More
        </Typography>
        <IconButton
          style={{
            padding: 0,
            marginRight: 5,
            color: "primary",
          }}
        >
          <ArrowDropDownOutlinedIcon className="!text-xl" />
        </IconButton>
      </Link>

      {toggleState === true ? (
        <Box className=" relative p-2 bg-white h-auto w-40">
          <ul className="">
            {pagesExtraIcon.map((page, index) => (
              <li className="list-none hover:bg-slate-100 opacity-60 hover:opacity-100 p-1 md:p-2">
                <Link
                  key={page.index}
                  component={Link}
                  to={page.path}
                  onClick={() => setToggleState(!toggleState)}
                  className=" no-underline"
                >
                  <IconButton
                    style={{
                      padding: 0,
                      marginRight: 5,
                      color: "primary",
                    }}
                  >
                    {page.icon}
                  </IconButton>
                  <Typography
                    variant="subtitle"
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    color={"primary"}
                    //   className="py-4"
                  >
                    {page.name}
                  </Typography>
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      ) : null}
    </React.Fragment>
  );
};

export default AppDrawerSelector;
