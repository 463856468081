import { Box, Typography } from "@mui/material";
import React from "react";

const MainPagesHeader = ({ header, text, imgSrc, icon }) => {
  let image = imgSrc;

  return (
    <>
      <Box className="relative flex h-[400px] content-center -z-50 items-center justify-center">
        <Box
          className={`absolute top-0 h-[100%] w-full `}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box className="absolute top-0 h-[100%] w-full bg-black/75 bg-cover bg-center" />
        <Box className="max-w-8xl container relative mx-auto -mt-1">
          <Box className="flex flex-wrap items-center place-content-center">
            <Box className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
              <Typography
                variant="subheader"
                color="white"
                fontSize={{ xs: "40px", sm: "60px" }}
                lineHeight={{ xs: "100%", sm: "80%" }}
                letterSpacing={"0.02em"}
                className="mb-6 font-black"
              >
                {header}
              </Typography>
            </Box>
            <Box className=" w-[80%] lg:w-[50%] text-center lg:py-2">
              <Typography
                variant="subtitle"
                // fontSize={{ xs: "30px", sm: "30px" }}
                // lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                color="white"
                className="opacity-80 line-clamp-6 md:line-clamp-2"
              >
                {text}
              </Typography>
            </Box>
          </Box>
          <Box className="flex flex-wrap items-center place-content-center">
            {icon}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MainPagesHeader;
