import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { CCCHistoryCard } from "../components/About/CCCHistoryCard";

const CCChistory = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={
          "https://alchetron.com/cdn/celestial-city-imeko-12c5442c-2818-4a2a-9f2e-222eadbcf4e-resize-750.jpeg"
        }
        header="CCC History"
        text="The Celestial Church of Christ, its mission, sacraments, and worship practices have evolved through a concise historical journey, shaping its sacred traditions and rituals over time."
      />
      <CCCHistoryCard />
    </React.Fragment>
  );
};

export default CCChistory;
