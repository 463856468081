import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { LoaderContext } from "../../context/loader.context";

export default function PageLoader() {
  const loaderContext = useContext(LoaderContext);

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  return (
    <div>
      {/* <Button  onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderContext.setShowState}
        onClick={closeLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
