import React, { useState } from "react";
import AboutCRCmini from "../components/About/AboutCRCmini";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PARTNER } from "../graphql/user-queries";
import ExtraView from "../components/Partners/ExtraView";
import ExtraViewMobile from "../components/Partners/ExtraViewMobile";

const ViewPartners = () => {
  const [partner, setPartner] = useState("");
  const params = useParams();

  const { data: partnersData } = useQuery(GET_PARTNER, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  React.useEffect(() => {
    if (partnersData) {
      // console.log("EVENTS", partnersData.getArticlesById);
      //   Filter Articles by like
      const partner = partnersData.getPartnerById;
      setPartner(partner);
    }
  }, [partnersData]);
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/partnership.jpg`}
        header="Partners"
        text="Dedicated partners of our organization, united by faith, strive to serve our community through love, compassion, and unwavering commitment to God's work."
      />
      <AboutCRCmini name={partner.name} bio={partner.description} />

      {partner.length === 0 ? null : (
        <>
          <ExtraView gallerytype={partner.slug} />
          <ExtraViewMobile gallerytype={partner.slug} />
        </>
      )}
    </React.Fragment>
  );
};

export default ViewPartners;
