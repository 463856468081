import React, { useContext, useState } from "react";
import PlainUserHeader from "./PlainUserHeader";
import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../context/app.context";
import { LoaderContext } from "../../../context/loader.context";
import CRCTextField from "../../../custom/Forms/CRCTextField";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCradio from "../../../custom/Forms/CRCradio";
import { formatDBdate, formatDay } from "../../../hook/date-format";
import CRCSelect from "../../../custom/Forms/CRCSelect";
import { countries } from "../../../static/constant";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_AVATAR } from "../../../graphql/user-queries";

const AvatarSettings = () => {
  const { isAuthenticated, userData, loginUser, logoutUser } = useAuth();
  const loaderContext = useContext(LoaderContext);
  let navigate = useNavigate();
  const [msg, setMsg] = useState("");

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [newAvatar, setNewAvatar] = useState("");

  const [updateUserAvatar, { loading, error, data }] =
    useMutation(UPDATE_USER_AVATAR);

  const [inputAvatarValue, setInputAvatarValue] = useState("");

  const handleAvatarChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      setNewAvatar(selectedFile);
      setInputAvatarValue(selectedFile.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newAvatar.length <= 0) {
      // Display error alert for empty fields
      return;
    }

    try {
      openLoader();
      const { data } = await updateUserAvatar({
        variables: {
          email: userData.email,
          avatar: newAvatar,
        },
        // variables: {
        //   firstname: avatar === "" ? userData.avatar : avatar,
        // },
      });

      // Handle successful form submission (if needed)
      console.log("Update success:", data);

      if (data.updateUserAvatar.email === null) {
        closeLoader();
        setMsg("Email already exists");
      } else {
        closeLoader();
        setMsg("Avatar updated successfully");
        setInputAvatarValue("");
        setTimeout(() => {
          navigate(`/profile`);
        }, 3000);
      }
    } catch (error) {
      // Display error alert for server error
      console.error("Update error:", error.message);
    }
  };

  return (
    <React.Fragment>
      <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto">
        <form onSubmit={handleSubmit}>
          <FormGroup className="w-full md:w-2/3 lg:w-1/2 mx-auto">
            {msg === "Avatar updated successfully" ? (
              <Alert variant="filled" severity="success">
                {msg}
              </Alert>
            ) : null}
            <br />
            {/* Display error alert */}

            <Grid
              container
              spacing={{ xs: 0, sm: 2, md: 3 }}
              className="py-2 md:flex items-center "
            >
              <Grid item xs={12} md={12}>
                <CRCFileUpload
                  label="Select an Image"
                  name="avatar"
                  type="file"
                  value={inputAvatarValue}
                  onChange={handleAvatarChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="">
              <Box className="mx-auto mt-8">
                <CRCbutton label={"Submit"} type={"submit"} />
              </Box>
            </Grid>
          </FormGroup>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AvatarSettings;
