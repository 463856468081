import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green, red } from "@mui/material/colors";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { Link, useLocation } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import { GET_EVENTS } from "../../graphql/other-queries";
import { useQuery } from "@apollo/client";
import ReadMore from "../Article/ReadMore";
import { formatDay } from "../../hook/date-format";
import { convertUTCToDate, sortByMutableAscending } from "../../hook/arrange";

export default function Events() {
  // Inside your component
  const isMobileOrTablet = useMediaQuery("(max-width: 600px)"); // Adjust the max-width as needed
  const avatarSize = isMobileOrTablet ? 400 : 600;

  let location = useLocation();
  //   Query to fetch getArticlesByID
  const { loading, error, data } = useQuery(GET_EVENTS, {
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // Ensure data.getArticles exists before using it
  const events = data?.getEvents || [];

  const today = new Date(); // Get the current date and time

  // Filter events to get only those that are greater than or equal to today
  const upcomingEvents = events.filter((event) => {
    const eventDate = new Date(event.date); // Assuming you have a 'date' property in your event object
    return eventDate >= today;
  });

  // If there are no upcoming events, you can handle it as needed
  if (upcomingEvents.length === 0) {
    return <p>No upcoming events.</p>;
  }

  // Sort the filtered events by date
  const sortedEvent = sortByMutableAscending(upcomingEvents, "date");

  // Access the first event in the upcomingEvents array
  const latestEvent = sortedEvent[0];

  return (
    <Box className=" grid">
      {latestEvent && (
        <Box
          className=" text-center mx-auto pb-4"
          display={"flex"}
          alignItems={"center"}
        >
          <Typography
            variant="subheader"
            fontSize={{ xs: "18px", sm: "26px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="pl-4 capitalize"
          >
            Upcoming Events
          </Typography>
        </Box>
      )}
      {latestEvent && (
        <Card
          sx={{ maxWidth: avatarSize }}
          className="mx-auto shadow-2xl drop-shadow-2xl"
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: "secondary.light" }} aria-label="recipe">
                <EventOutlinedIcon />
              </Avatar>
            }
            // action={
            //   <IconButton aria-label="settings">
            //     <MoreVertIcon />
            //   </IconButton>
            // }
            title={latestEvent.title}
            subheader={convertUTCToDate(latestEvent.date)}
          />
          <CardMedia
            component="img"
            image={latestEvent.imgSrc}
            alt="Event Image"
            className="h-80 w-full object-cover"
          />
          <CardContent className="grid">
            <Typography
              variant="body"
              fontSize={{ xs: "13px", sm: "13px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className=" line-clamp-2 opacity-60"
            >
              <b>Theme:</b> {latestEvent.content}
            </Typography>
            <Typography
              variant="body"
              fontSize={{ xs: "13px", sm: "13px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="opacity-60 line-clamp-2"
            >
              <b> Venue: </b>
              {latestEvent.venue.includes("http://") ||
              latestEvent.venue.includes("https://") ? (
                <Link
                  to={latestEvent.venue}
                  target="_blanc"
                  className=" !no-underline text-purple-500"
                >
                  {latestEvent.venue}
                </Link>
              ) : (
                latestEvent.venue
              )}
            </Typography>
            <ReadMore
              to={`/events`}
              className="cursor-pointer sidebar-glide w-[37%] lg:w-[30%] md:w-[30%]"
              label="More Events"
            />
          </CardContent>
        </Card>
      )}
    </Box>
  );
}
