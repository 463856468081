import React, { Fragment } from "react";
import { Box, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { missionListData } from "../../static/static";
import { Link } from "react-router-dom";

export const CCCHistoryCardMini = ({
  text,
  miniTitle,
  title,
  listData = [],
}) => {
  return (
    <Box className="pt-8 grid">
      <Typography
        variant="header"
        fontSize={{ xs: "25px", sm: "30px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        className=" capitalize"
        title="Click to view more"
      >
        {title}
      </Typography>
      <Typography
        variant="subheader"
        fontSize={{ xs: "20px", sm: "20px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        className=" capitalize"
        title="Click to view more"
      >
        {miniTitle}
      </Typography>
      <Typography
        variant="body"
        fontSize={{ xs: "16px", sm: "16px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        className="opacity-60 text-start"
      >
        {text}
      </Typography>
      <List className="">
        {listData.map((item) => (
          <ListItem key={item.id} component="div">
            <ListItemIcon>{item.id}</ListItemIcon>
            <Typography
              variant="body"
              fontSize={{ xs: "16px", sm: "16px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="opacity-60 text-start"
            >
              {item.text}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export const CCCHistoryCard = () => {
  return (
    <Fragment>
      <Box className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <img
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/church_ne53cf.jpeg`}
          alt="CCC History"
          className="w-full h-72 md:h-[600px] object-cover "
        />
        {/* <Box className=" space-x-4">
          <Link
            to={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/CCC-Contitution.pdf`}
            target="_blank"
            download
          >
            Download CCC Constitutions
          </Link>
          <Link
            to={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/CCC-Hymns.pdf`}
            target="_blank"
            download
          >
            Download CCC Hymnals
          </Link>
        </Box> */}

        <CCCHistoryCardMini
          title="The Church"
          text="Celestial Church of Christ is a spiritual, world-wide, united,
            indivisible Holy Church which came into the world from heaven by
            DIVINE ORDER on the 29th of September 1947 in Porto Novo, Republic
            of Benin through the founder of the Church, the Late Reverend,
            Pastor, Prophet, Founder Samuel Bilehou Joseph Oshoffa. The Church
            is well known with Parishes, Dioceses all over the world with its
            International Headquarters in Nigeria."
        />
        <CCCHistoryCardMini
          miniTitle="vision of the church"
          text="To evangelize the world through the proclamation of the Word – the
          Good News of the Messiah Jesus Christ, and of the Kingdom of God; in
          the mighty name of Jesus Christ, proclaiming deliverance to those
          bound by the shackles of Satan, healing the brokenhearted, the sick,
          the informed, restoring hope to the hopeless and those who are bound,
          while reinforcing the faith of all – both feeble and strong in the
          unfailing righteousness and faithfulness of God Almighty and Jesus
          Christ, the Lord & Savior."
        />
        <CCCHistoryCardMini
          miniTitle="Primary Mission"
          text="To evangelize the world through the proclamation of the Word – the
          Good News of the Messiah Jesus Christ, and of the Kingdom of God; in
          the mighty name of Jesus Christ, proclaiming deliverance to those
          bound by the shackles of Satan, healing the brokenhearted, the sick,
          the informed, restoring hope to the hopeless and those who are bound,
          while reinforcing the faith of all – both feeble and strong in the
          unfailing righteousness and faithfulness of God Almighty and Jesus
          Christ, the Lord & Savior."
          listData={missionListData}
        />

        <CCCHistoryCardMini
          miniTitle="Purpose"
          text="The purposes for which the church is organized are exclusively
          religious, charitable and educational."
        />

        <CCCHistoryCardMini
          title="The Divine Order"
          text="God called Rev., Pastor, Prophet Oshoffa on the day of total Eclipse
          of the sun in West Africa, when he was in the forest of Dahomey (now
          Republic of Benin), to purchase Ebony wood being a carpenter and
          timber trader, just like his father. He was in the wilderness for
          forty days and forty nights. Just as our Lord Jesus was led by the
          spirit into the wilderness for forty days and forty nights. St. Matt.
          4: 1-11, Mark 1:12-13, Luke 4:1-13. Just as our Lord started his
          mission of redemption alone, so also did our highly esteemed Rev.,
          Pastor, Founder, S.B.J. Oshoffa started alone, on the 29th September
          1947."
        />
        <CCCHistoryCardMini
          title="The Spiritual Message"
          text="In the deep mystery of divine appearance, during prayer on the 19th
          September 1947, a winged Angel bathed in intense light, word came from
          God to the founder as follows:
        
          “It is the wish of God to send you on an errand of preaching into the
          World. Many nominal Christians there who were confronted by
          difficulties and problems of this world. They run after fetish priests
          and other power of darkness for all kinds of assistance. Consequently,
          on their death, they cannot see Christ because, by their action, Satan
          has left his spiritual mark on them. To assist you in in your work so
          that men may listen to the fact that God has sent you.”"
        />
        <CCCHistoryCardMini
          title="Tenet and Mode of Worship"
          text="The Church under the absolute dictatorship of Holy Spirit is hereby
          emphasized for:"
        />

        <CCCHistoryCardMini
          miniTitle="The Name of the Church"
          text="The name of the Church “Celestial Church of Christ” was revealed by
          the Holy Spirit through a Prophet who was held in trance for seven
          days."
        />
        <CCCHistoryCardMini
          miniTitle="The Tenets And Mode Of Worship Of The Church"
          text="Ordained by the Holy Spirit. The rules and regulations written in the
          booklet of “Tents and Admission into the membership of Celestial
          Church of Christ.” Mode of worship includes the setting of the Altar, and the seat
          therein, the number of candles used for various types of services and
          the seating arrangement in particular, it should be noted that the
          seating arrangements was revealed through a Prophetess who under the
          influence of the Holy Spirit in the wilderness on Friday 5th of
          October, 1947, sketched the seating arrangement using oranges."
        />
        <CCCHistoryCardMini
          miniTitle="The Service Of The Church"
          text="Revealed through the Holy Spirit as promised by our Lord Jesus Christ (John 14:25-26) 
          There is an order of service laid down in the Celestial Church of Christ for different 
          occasions such as Marriage, Funeral, Naming Ceremony, Mass in Remembrance of the dead, etc. 
          The order for the respective occasion is strictly as revealed by the Holy Spirit 
          and as set out in the booklet titled “Order of Service”."
        />
        <CCCHistoryCardMini
          title="Holy Rites or Sacraments"
          text="There are a number of Holy Rites or Sacrament within the form of
          worship in Celestial Church of Christ utmost importance. Members shall
          avail themselves of the opportunity to partake in these rites or
          sacraments: Baptism, Holy Communion, Annual Washing of Feet, Annual
          Pilgrimage to Imeko, Ogun State, Nigeria at Christmas Eve, Annual
          Harvest Thanksgiving Service, and Holy Mary’s Day."
        />
      </Box>
    </Fragment>
  );
};
