import { Box, CssBaseline, Grid, Typography } from "@mui/material";
import React from "react";
import { mainABoutData } from "../../static/static";
import { Link, useLocation } from "react-router-dom";

const MainCard = () => {
  let location = useLocation();

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid
        container
        spacing={1}
        className="pt-24 md:!mx-auto place-content-center"
      >
        {mainABoutData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Link
              to={`${location.pathname}${item.path}`}
              className=" !no-underline"
            >
              <img
                className="object-cover shadow-md drop-shadow-md bg-cover rounded-2xl place-content-center mx-auto h-64 w-72"
                src={item.image}
                alt={item.title}
                title={item.title}
              />
            </Link>
            <Box className=" py-4 px-10 md:px-4 mx-auto text-center grid">
              <Link
                to={`${location.pathname}${item.path}`}
                className=" !no-underline"
              >
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "18px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" capitalize sidebar-glide"
                >
                  {item.title}
                </Typography>
              </Link>

              <Typography
                variant="body"
                fontSize={{ xs: "16px", sm: "16px" }}
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="opacity-60 line-clamp-3 text-center"
              >
                {item.text}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default MainCard;
