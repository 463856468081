import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_GALLERY } from "../../graphql/other-queries";
import { filterDataByType } from "../../hook/arrange";

const GalleryWeb = () => {
  const { loading, data, error } = useQuery(GET_GALLERY);
  const [galleries, setgalleries] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      // console.log("Gallery", data.getGallery);
      const fileteredDataz = data?.getGallery
        ? filterDataByType(data.getGallery, "gallery")
        : [];
      setgalleries(fileteredDataz);
    }
  }, [data]);

  /* change media to galleries when db is populated */

  // React.useEffect(() => {
  //   if (media) {
  //     // console.log("Gallery", data.getGallery);
  //     // change the media to data and data.gallery when gallery db is populated
  //     setgalleries(media);
  //   }
  // }, [media]);

  // State variables to manage pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 4; // Change this to the number of items you want per page

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = galleries.slice(startIndex, endIndex);

  return (
    <React.Fragment>
      {/* <MainPagesHeader
        imgSrc={
          "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
        }
        header="Gallery"
        text=" Check out our amazing galleria - engage, learn, and connect with our vibrant community. Join us in shaping unforgettable experiences."
      /> */}

      <Box className="hidden md:block">
        <ImageList
          sx={{ width: "100%", height: "100%" }}
          className="!py-5 !overflow-y-hidden"
          cols={2}
        >
          {currentPageData.map((item) => (
            <ImageListItem key={item.imgSrc}>
              <img
                src={`${item.imgSrc}?w=248&fit=crop&auto=format`}
                srcSet={`${item.imgSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.description}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>

        {/* Pagination controls */}
        <Box className="text-center mt-4 mx-auto flex  place-content-center ">
          {currentPage > 1 && (
            // <CRCbutton
            //   variant="outlined"
            //   color="primary"
            //   onClick={() => setCurrentPage(currentPage - 1)}
            //   label={"Prev"}
            // />
            <Box
              color="primary"
              aria-label="Next"
              onClick={() => setCurrentPage(currentPage - 1)}
              className="ml-2 !mr-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
              Prev
            </Box>
          )}
          {endIndex < galleries.length && (
            <Box
              color="primary"
              aria-label="Next"
              onClick={() => setCurrentPage(currentPage + 1)}
              className="mr-2 !ml-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
            >
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </Box>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default GalleryWeb;
