import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import MainCard from "../components/Partners/MainCard";

const Partners = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/partnership.jpg`}
        header="Partners"
        text="Dedicated partners of our organization, united by faith, strive to serve our community through love, compassion, and unwavering commitment to God's work."
      />
      <MainCard />
    </React.Fragment>
  );
};

export default Partners;
