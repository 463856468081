import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";

import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Import an icon for the "Profile" route, you can change this to your preferred icon
import SettingsIcon from "@mui/icons-material/Settings"; // Import an icon for the "Account" route
import DashboardIcon from "@mui/icons-material/Dashboard"; // Import an icon for the "Dashboard" route
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // Import an icon for the "Logout" route
import { HouseOutlined, Theaters } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CameraFrontOutlinedIcon from "@mui/icons-material/CameraFrontOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import TheaterComedyOutlinedIcon from "@mui/icons-material/TheaterComedyOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";

// You can use these icons in your components as needed.

export const pagesIcon = [
  {
    name: "Home",
    path: "",
    icon: <HouseOutlined className="!text-xl" />,
  },
  {
    name: "About",
    path: "about",
    icon: <DashboardIcon className="!text-xl" />,
  },

  {
    name: "Community",
    path: "community",
    icon: <ForumOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Blog",
    path: "blog",
    icon: <ArticleOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Partners",
    path: "partners",
    icon: <HandshakeOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Contact",
    path: "contact",
    icon: <CallOutlinedIcon className="!text-xl" />,
  },
];
export const pagesExtraIcon = [
  {
    name: "Gallery",
    path: "gallery",
    icon: <CollectionsOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Events",
    path: "events",
    icon: <EventOutlinedIcon className="!text-xl" />,
  },
  {
    name: "CCC Evangelists",
    path: "celestial/evangelism",
    icon: <EmojiPeopleOutlinedIcon className="!text-xl" />,
  },
  {
    name: "CCC Artistes",
    path: "celestial/artiste",
    icon: <InterpreterModeOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Bible Search",
    path: "/bible-search",
    icon: <AutoStoriesOutlinedIcon className="!text-xl" />,
  },
  {
    name: "CCC Hymns",
    path: "/ccc-hymns",
    icon: <ImportContactsOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Order of Service",
    path: "/ccc-order-of-service",
    icon: <ListAltOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Constitution",
    path: "/ccc-constitution",
    icon: <GavelOutlinedIcon className="!text-xl" />,
  },

  {
    name: "Weekly Show",
    path: "weekly-show",
    icon: <TheaterComedyOutlinedIcon className="!text-xl" />,
  },
];

export const pagesMobileIcon = [
  {
    name: "Home",
    path: "",
    icon: <HouseOutlined className="!text-xl" />,
  },
  {
    name: "About",
    path: "about",
    icon: <DashboardIcon className="!text-xl" />,
  },

  {
    name: "Community",
    path: "community",
    icon: <ForumOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Blog",
    path: "blog",
    icon: <ArticleOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Partners",
    path: "partners",
    icon: <HandshakeOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Contact",
    path: "contact",
    icon: <CallOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Gallery",
    path: "gallery",
    icon: <CollectionsOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Events",
    path: "events",
    icon: <EventOutlinedIcon className="!text-xl" />,
  },
  {
    name: "CCC Evangelists",
    path: "celestial/evangelism",
    icon: <EmojiPeopleOutlinedIcon className="!text-xl" />,
  },
  {
    name: "CCC Artistes",
    path: "celestial/artiste",
    icon: <InterpreterModeOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Bible Search",
    path: "/bible-search",
    icon: <AutoStoriesOutlinedIcon className="!text-xl" />,
  },
  {
    name: "CCC Hymns",
    path: "/about/ccc",
    icon: <ImportContactsOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Order of Service",
    path: "/ccc-order-of-service",
    icon: <ListAltOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Constitution",
    path: "/about/ccc",
    icon: <AutoStoriesOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Weekly Show",
    path: "weekly-show",
    icon: <TheaterComedyOutlinedIcon className="!text-xl" />,
  },
];

export const userPagesIcon = [
  {
    name: "Profile",
    path: "profile",
    icon: <AccountCircleIcon className="!text-xl" />,
  },
  {
    name: "Settings",
    path: "settings",
    icon: <SettingsIcon className="!text-xl" />,
  },

  {
    name: "Logout",
    path: "",
    icon: <ExitToAppIcon className="!text-xl" />,
  },
];

export const featuresData = [
  {
    color: "text-red-600",
    title: "Transparency",
    icon: VisibilityOutlinedIcon,
    content:
      "Enhancing openness and ethics within the Church, promoting responsible governance, and fostering a culture of transparency in decision-making processes.",
  },
  {
    color: "text-green-600",
    title: "Advocacy for Rights ",
    icon: ThumbUpAltOutlinedIcon,
    content:
      "Championing freedom of expression, speech, and active participation of parishioners in Church governance, ensuring inclusivity and democratic principles.",
  },
  {
    color: "text-blue-600",
    title: "Image Preservation",
    icon: CameraFrontOutlinedIcon,
    content:
      "Correcting actions that harm the Church's reputation through counseling and public opinion, preserving its positive image in alignment with faith and principles.",
  },
];

export const footerMenus = [
  {
    name: "useful links",
    items: [
      {
        name: "Community",
        path: "community",
      },

      { name: "Blog", path: "/blog" },
      {
        name: "Partners",
        path: "partners",
      },
      { name: "About Us", path: "/about" },
    ],
  },
  {
    name: "other routes",
    items: [
      {
        name: "Gallery",
        path: "/gallery",
      },

      {
        name: "Events",
        path: "events",
      },
      {
        name: "CCC Evangelists",
        path: "celestial/evangelism",
      },
      {
        name: "CCC Artistes",
        path: "celestial/artiste",
      },
    ],
  },
  {
    name: "other resources",
    items: [
      { name: "Bible", path: "/bible" },
      {
        name: "Order of Service",
        path: "/ccc-order-of-service",
      },
      { name: "CCC Hymns", path: "/about/ccc" },
      { name: "CCC Constitution", path: "/about/ccc" },
      {
        name: "Weekly Show",
        path: "weekly-show",
      },
    ],
  },
];

export const footerSocials = [
  {
    color: "#1877F2",
    bgColor: "#fff",
    name: "facebook",
    icon: <FacebookOutlinedIcon color="#1877F2" />,
    path: "https://www.facebook.com/profile.php?id=100066679125437&mibextid=2JQ9oc",
  },
  {
    color: "#d62976",
    bgColor: "#fff",
    name: "instagram",
    icon: <InstagramIcon color="#d62976" />,
    path: "https://instagram.com/celestialrealitycheck?igshid=MzRlODBiNWFlZA==",
  },
];

export function getRandomColor() {
  // Generate a random hex color code
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const mainABoutData = [
  {
    title: "CCC History",
    image: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/ccc_elr9qb.jpeg`,
    text: "Celestial Church of Christ is a spiritual, world-wide, united, indivisible Holy Church which came into the world from heaven by DIVINE ORDER on the 29th of September 1947 in Porto Novo, Republic of Benin through the founder of the Church, the Late Reverend, Pastor, Prophet, Founder Samuel Bilehou Joseph Oshoffa. The Church is well known with Parishes, Dioceses all over the world with its International Headquarters in Nigeria.",
    path: "/ccc",
  },
  {
    title: "CRC Biography",
    image: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/crc-image_eeutus.jpg`,
    text: "Celestial Reality Check started on March 2021 as a platform to share information, review activities, and checkmate bad eggs within the Celestial Church of Christ and the Christian fold. The platform is championing the course for transparency and accountability for social development in the Church across all levels. CRC is promoting advocacy for positive changes in Celestial Church of Christ to protect right to expression, freedom of speech, and parishioners' active participation in the governance and spiritual leadership process. According to 2 Samuel 7:14 I will be a father to him, and he will be a son to me; when he commits iniquity, I will correct him with the rod of men and the strokes of the sons of men. CRC is a platform made of young people from different parishes across the globe who are chastising others who are going against the positive image of the Church through correction, counseling, and public opinion. Psalm 94:12 Blessed is the man whom You chasten, O Lord, And whom You teach out of Your law",
    path: "/crc",
  },
  {
    title: "Biblical Justifications",
    image: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/bible_bnigao.jpeg`,
    text: "Here we have the biblical justification and assurance of our tenets in Celestial Church of Christ",
    path: "/justification",
  },
  {
    title: "Rules & Tenets",
    image: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/rules_lbjqwa.jpeg`,
    text: "Here is a summary of rules, regulations and tenets of Celestial Church of Christ",
    path: "/tenets",
  },
];

export const justifyItems = [
  {
    doct: "Wearing white garment - Sutana",
    bible:
      "Rev 7:9, Rev 4:4,Lev 16:14, Rev 19:8, Matt. 17:1-2, Matt. 28:2-3, Mark 9:2-3,Daniel 7:9,John 20:11-12,",
  },
  {
    doct: "Wearing no shoes",
    bible: "Acts 7:33, Joshua 5:15, Exodus 3:5",
  },
  {
    doct: "Lighting candles in the altar",
    bible: "Rev 4:5; Zech 4:2-5",
  },
  {
    doct: "Bowing down before the Altar",
    bible: "Rev 4:3-10, 14:7",
  },
  {
    doct: "Chanting Holy, Holy, Holy to the Lord of Hosts",
    bible: "Rev 4:8",
  },
  {
    doct: "The eye on the Celestial logo",
    bible: "Rev 5:6",
  },
  {
    doct: "Using incense during services",
    bible:
      "Rev 5:8 & 8:3-5; Exodus 30:8-9; Luke 1:9-11; Exodus 30:24-38; Exodus 40:5; Exodus 31:10-11; Malachi 1:11",
  },
  {
    doct: "The salvation of C.C.C.",
    bible: "Rev 7:14; Romans 8:28-30",
  },
  {
    doct: "Using of the Holy water",
    bible: "Exodus 13:17-21; Number 8:6-7; Exodus 27:20",
  },
  {
    doct: "Keeping the Altar light burning regularly",
    bible: "Lev. 24:4-6; Exodus 27:20-21",
  },
  {
    doct: "Making announcements during services	",
    bible: "Exodus 29:7",
  },
  {
    doct: "Uses of Church oil",
    bible: "Exodus 29:36; James 5:13-15; Mark 6:13; Number 10:1",
  },
  {
    doct: "Stream bath",
    bible: "2 Kings 5:10-15; John 9:6-11",
  },
  {
    doct: "Conducting New Moon services",
    bible: "Number 28:9-15; Isaiah 1:13-14; Numbers 10:1",
  },
  {
    doct: "Washing of feet",
    bible: "John 3:5, 14 & 15; II Kings 5:10-15; John 9:6-11",
  },
  {
    doct: "Use of palm front",
    bible: "Rev 7:9; John 12:12-18",
  },
  {
    doct: "Not allowing corpses in the sanctuary",
    bible: "Ezekiel 43:7-9",
  },
  {
    doct: "Using salt in thanks offering",
    bible: "Lev. 2:13; Matthew 5:18",
  },
  {
    doct: "No alcohol",
    bible: "Leviticus 10:8-11; Genesis 9:2-25",
  },
  {
    doct: "Mandatory purification for women after menstrual period",
    bible: "Lev 12:2-3 & 15:4-9",
  },
  {
    doct: "Use of Perfume",
    bible: "Matthew 26: 6-13",
  },
  {
    doct: "The seven candles = the 7 spirits of God",
    bible:
      "Rev 4:1-5, Rev 1:12&20, Zechariah 4:2, Exodus 25:31-37, Exodus 37:23, Number 8:2",
  },
  {
    doct: "Purification for service conductor",
    bible: "Lev. 10:3, 6:9; Exodus 10:15",
  },
  {
    doct: "Need for thanks offering",
    bible: "Exodus 34:20",
  },
  {
    doct: "The church as meeting place between God & His believers",
    bible: "Exodus 29:12-45; Hymn 5",
  },
  {
    doct: "Celestial Church and their fellow men",
    bible: "Roman 12:17-19; Hebrew 12:14",
  },
  {
    doct: "Holy Michael - Head of Angels of God",
    bible: "Rev 12:7-19; Daniel 10:21",
  },
  {
    doct: "Annual Juvenile Harvest",
    bible: "Mark 10:13-16; Matthew 19:13-15",
  },
  {
    doct: "Shouting of Halleluyah",
    bible: "Rev 19:1-7",
  },
  {
    doct: "Naming Ceremony",
    bible: "Lev. 12:1-8",
  },
  {
    doct: "Loin and Girdle",
    bible:
      "Daniel 10:5, Rev. 1:13, Exodus 29:5, Matthew 3:4, Acts 21:11, Psalm 109:19, Lev. 16:4",
  },
  {
    doct: "Meaning of JHS",
    bible:
      "1. Jesus Homini Saviotor 2. Jesus Heals & Saves  3. Jesus, Saviour of Humanity",
  },
];

export const forbiddenList = [
  {
    id: 1,
    text: "Commit Adultery or Fornication",
  },
  {
    id: 2,
    text: "Drink Alcohol, Wine, and Smoke Cigarettes",
  },
  {
    id: 3,
    text: "Eat Pork & Crawling Animals",
  },
  {
    id: 4,
    text: "Merry-go-round at night",
  },
  {
    id: 5,
    text: "Wear transparent dress, especially for Soutana while in the Church premises",
  },
  {
    id: 6,
    text: "Wear Red or Black apparel, except for professional reasons",
  },
  {
    id: 7,
    text: "Sit male & female members side by side inside the Church",
  },
  {
    id: 8,
    text: "Female members are forbidden to leave their heads uncovered",
  },
  {
    id: 9,
    text: "Female members are forbidden to paint their lips and nails",
  },
  {
    id: 10,
    text: "Male members are forbidden to wear their hair too long",
  },
  {
    id: 11,
    text: "Women NEED to be Sanctified by an elder in the Church after the 7th day of their monthly cycle",
  },
];

export const sanctuaryRulesList = [
  {
    id: 1,
    text: "Upon entering the church, at all times, but especially during church services, an individual should remember that he or she is in the House of God. (1 Tim. 3:15). Reverence and good manners are required so as not to disturb those who are already engaged in prayer and worship, but even more importantly, as an expression of sincere fear, faith, and awareness of the presence of the Sovereign God of Celestial and the Universe.",
  },
  {
    id: 2,
    text: "You must be properly and appropriately dressed without any loose buttons before coming into the Sanctuary, otherwise, the warden will politely ask that you do it right.",
  },
  {
    id: 3,
    text: "All must not wear transparent or tight regalia (Sutana) to the church. It is ungodly. Therefore, appropriate and respectful clothing should be worn at all times. We do this not to impress or spiritually deny each other but to show our Lord that we offer our best to mirror the saints; for He deserves only our reverence, fear, and holiness. Psalm 96:9; 1 Peter 1: 15-17.",
  },
  {
    id: 4,
    text: "No Male not yet anointed is allowed into the altar. No female shall under any circumstance go beyond the threshold close to the altar and no female shall be allowed to touch the sacred items such as sanctification water vessel, the in-censer or the Cross in the altar.",
  },
  {
    id: 5,
    text: "Women who are still in their flow must of necessity go through sanctification on the eighth day of the commencement of their cycle. If the flow exceeds seven days, then they must add an additional one day after the end of the flow before they can come to the church for sanctification. Please keep off the environment of the church for a clear seven days.",
  },
  {
    id: 6,
    text: "Children should be kept effectively under control by their parents during church services. No child shall be allowed to run up and down the sanctuary of God. Such children will be taken out and controlled by the warden.",
  },
  {
    id: 7,
    text: "No matter your status, you are not allowed to move out or in during prayer sessions and Sermon period unless your child is during the solemnity of the service. No walking during prayer sessions at all.",
  },
  {
    id: 8,
    text: "Three elders' prayer in the church is reserved for those who are anointed and should progress between them in ascending order.",
  },
  {
    id: 9,
    text: "There is no singing when you are asked to pray.",
  },
  {
    id: 10,
    text: "Musical instruments used for the glory of God shall remain out of bounds to children. No child shall be allowed to play with any musical instrument.",
  },
  {
    id: 11,
    text: "There shall be a passable column between male and female members when thanks-offering is being brought before the Lord.",
  },
  {
    id: 12,
    text: "No food except water or fruit shall be taken inside the Sanctuary. No such thing should happen in the altar. If you need to drink or eat fruit, please do so outside the altar of God.",
  },
  {
    id: 13,
    text: "No chewing of gum under any circumstances in the Sanctuary of God.",
  },
  {
    id: 14,
    text: "No spraying or pasting of money in the Sanctuary under any circumstances.",
  },
  {
    id: 15,
    text: "Use of cell phones is prohibited in the Sanctuary.",
  },
  {
    id: 16,
    text: "All jokes shall strictly be reserved to the endow the Service and all spiritual assignments for the day. Moreover, jokes should be restrained and confined to within Christian discipline.",
  },
  {
    id: 17,
    text: "There should be no talking or discussion when the Service is in progress. No irrelevant conversations shall take place either in the Alter or in any other part of the Sanctuary.",
  },
  {
    id: 18,
    text: "There can be no interruption or interjection during the flow of Worship services. The only period for interjection or interruption, if it is compelling, is during the time of announcement, thanks offering, or after the Service. This is crucial.",
  },
  {
    id: 19,
    text: "Members can be allowed in during Sermon by the warden or the preacher.",
  },
  {
    id: 20,
    text: "Tattoos are not encouraged; ear-rings and weaving of hair on men are to be discouraged.",
  },
  {
    id: 21,
    text: "In-fighting for whatever reason is prohibited and employment of unpolished words is unacceptable in the sanctuary of God.",
  },
  {
    id: 22,
    text: "Complete reverence for the presence of God in the Sanctuary is a MUST at all times.",
  },
];

export const missionListData = [
  {
    id: 1,
    text: "To worship God Almighty in holiness and reverence",
  },
  {
    id: 2,
    text: "To Preach the good news of the Messiah – Jesus Christ and the imminent Kingdom of God through evangelism, Bible Classes, Bible Lectures, organized spiritual forums, print and electronic media.",
  },
  {
    id: 3,
    text: "To prepare believers for the coming of the Lord Jesus Christ.",
  },
  {
    id: 4,
    text: "To work for the unity of all Christians",
  },
  {
    id: 5,
    text: "To organize and run schools, colleges, seminaries, and research centers.",
  },
  {
    id: 6,
    text: "Inculcation and restoration of Christian values into home/families, to encourage family cohesion, peace and stability in homes, neighborhoods, and nations through prayers, counseling and spiritual ministration.",
  },
  {
    id: 7,
    text: "Organize charities and relief operations.",
  },
  {
    id: 8,
    text: "Promote crime-free and drug-free environments.",
  },
];
export const yearlyItems = [
  {
    program: "PALM SUNDAY",
    period: "10:00am",
  },
  {
    program:
      "PASSION WEEK - Washing Of Feet, Passover Night, Holy Communion, Good Friday",
    period: "9:00am",
  },
  {
    program: "EASTER SUNDAY",
    period: "10:00am",
  },
  {
    program: "JUVENILE HARVEST - Every First Sunday in June",
    period: "10:00am",
  },
  {
    program: "IMEKO CHRISTMAS CONVOCATION - Every 24th of December",
    period: "10:00am",
  },
];

export const forumCateg = ["all", "biblical", "doctrine"];
export const artCateg = ["all", "gist", "article"];

export const carouselImg = [
  {
    image:
      "https://crc-resources.s3.us-east-2.amazonaws.com/designs/media_vucugz.jpg",
  },
  {
    image: "https://crc-resources.s3.amazonaws.com/designs/slider1.jpeg",
  },
  {
    image:
      "https://crc-resources.s3.us-east-2.amazonaws.com/designs/slider2.jpeg",
  },
  {
    image:
      "https://crc-resources.s3.us-east-2.amazonaws.com/designs/slider3.jpeg",
  },
  {
    image:
      "https://crc-resources.s3.us-east-2.amazonaws.com/designs/slider4.jpeg",
  },
];

// // Function to filter data for the current month
// export const filterDataByCurrentMonth = (data) => {
//   const currentDate = new Date();
//   const filteredData = data.filter((item) => {
//     const itemDate = new Date(item.dob);
//     return itemDate.getMonth() === currentDate.getMonth();
//   });
//   return filteredData;
// };

// export const sortByMutableDescending = (data, date) => {
//   // Convert the data array to a mutable array using slice()
//   const mutableData = data.slice();

//   // Sort the mutable array
//   mutableData.sort((a, b) => {
//     const dateA = new Date(a[date]);
//     const dateB = new Date(b[date]);
//     return dateB - dateA;
//   });

//   return mutableData;
// };

// export const sortByMutableAscending = (data, date) => {
//   // Convert the data array to a mutable array using slice()
//   const mutableData = data.slice();

//   // Sort the mutable array
//   mutableData.sort((a, b) => {
//     const dateA = new Date(a[date]);
//     const dateB = new Date(b[date]);
//     return dateA - dateB;
//   });

//   return mutableData;
// };

// export const sortByCreatedAtDescending = (data, sortOption) => {
//   return data.sort((a, b) => {
//     const dateA = new Date(a[sortOption]);
//     const dateB = new Date(b[sortOption]);
//     return dateB - dateA;
//   });
// };

// export const sortByCreatedAtAscending = (data) => {
//   return data.sort((a, b) => {
//     const dateA = new Date(a.createdAt);
//     const dateB = new Date(b.createdAt);
//     return dateA - dateB;
//   });
// };

// export function filterDataByNotType(data, targetType) {
//   // Check if data is an array or can be converted to an array
//   if (!Array.isArray(data)) {
//     console.error("Input data is not an array.");
//     return [];
//   }

//   // Use the filter method to filter the data
//   const filteredData = data.filter((item) => item.type !== targetType);
//   return filteredData;
// }

// export function filterDataByType(data, targetType) {
//   // Check if data is an array or can be converted to an array
//   if (!Array.isArray(data)) {
//     console.error("Input data is not an array.");
//     return [];
//   }

//   // Use the filter method to filter the data by the targetType
//   const filteredData = data.filter((item) => item.type === targetType);
//   return filteredData;
// }

// export function filterArrayDataByNotTypes(data, targetTypes) {
//   // Check if data is an array or can be converted to an array
//   if (!Array.isArray(data)) {
//     console.error("Input data is not an array.");
//     return [];
//   }

//   // Use the filter method to filter the data by the target types
//   const filteredData = data.filter((item) => !targetTypes.includes(item.type));
//   return filteredData;
// }

// export function filterArrayDataByTypes(data, targetTypes) {
//   // Check if data is an array or can be converted to an array
//   if (!Array.isArray(data)) {
//     console.error("Input data is not an array.");
//     return [];
//   }

//   // Use the filter method to filter the data by the target types
//   const filteredData = data.filter((item) => targetTypes.includes(item.type));
//   return filteredData;
// }

// export function filterDataByCateg(data, targetCateg) {
//   // Check if data is an array or can be converted to an array
//   if (!Array.isArray(data)) {
//     console.error("Input data is not an array.");
//     return [];
//   }

//   // Use the filter method to filter the data by the targetCateg
//   const filteredData = data.filter((item) => item.category === targetCateg);
//   return filteredData;
// }
