import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function PDFViewer({ fileUrl }) {
  // Create a new instance of the defaultLayoutPlugin
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div style={{ height: 800 }}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={fileUrl}
          plugins={[
            // Register plugins
            defaultLayoutPluginInstance,
            // Add any other plugins you want to use here
          ]}
        />
      </Worker>
    </div>
  );
}

export default PDFViewer;
