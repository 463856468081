import React from "react";
import { Link } from "react-router-dom";

import { Avatar, Box, IconButton, Typography } from "@mui/material";

import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { PersonOutline } from "@mui/icons-material";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";

import ReadMore from "../Article/ReadMore";
import { GET_ARTICLES } from "../../graphql/other-queries";
import { useQuery } from "@apollo/client";
import {
  sortByMutableAscending,
  sortByMutableDescending,
} from "../../hook/arrange";

const ArticleCard = () => {
  //   Query to fetch getArticlesByID
  const { loading, error, data } = useQuery(GET_ARTICLES, {
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // Ensure data.getArticles exists before using it
  const articles = data?.getArticles || [];

  // Sort the filtered events by date
  const sortedEvent = sortByMutableDescending(articles, "createdAt");

  const latestArticle = sortedEvent[0];
  console.log("Last Article", latestArticle);

  return (
    <React.Fragment>
      <Box className=" lg:w-[80%] md:w-[80%] mx-auto !text-left">
        <Box className="flex place-content-center lg:place-content-start">
          <Box className="" display={"flex"} alignItems={"center"}>
            <Avatar
              sx={{ bgcolor: "secondary.light", p: 3 }}
              className=" flex  my-2 shadow-md drop-shadow-md"
            >
              <IconButton
                variant="gradient"
                size="lg"
                color="#fff"
                className="pointer-events-none mb-6 rounded-full "
              >
                <TrendingUpOutlinedIcon className=" !text-white" />
              </IconButton>
            </Avatar>

            <Typography
              variant="subheader"
              fontSize={{ xs: "18px", sm: "26px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="pl-4 capitalize"
            >
              Latest Article
            </Typography>
          </Box>
        </Box>
        {latestArticle === undefined || "" ? (
          <>
            <Box className=" py-4 text-center md:text-start">
              <Typography
                variant="subheader"
                fontSize={{ xs: "18px", sm: "24px" }}
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className=" capitalize sidebar-glide"
              >
                Coming soon
              </Typography>
            </Box>
            <Box className=" text-center md:text-start">
              <Typography
                variant="body"
                fontSize={{ xs: "13px", sm: "13px" }}
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className=" opacity-60 line-clamp-3"
              >
                Content loading...
              </Typography>
            </Box>
            <Box className=" pt-3 pb-8" display={"flex"} alignItems={""}>
              <Box className=" mx-auto md:mx-0">
                <PersonOutline className="!text-xl" />

                <Typography
                  variant="caption"
                  fontSize={{ xs: "13px", sm: "13px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="capitalize pl-2"
                >
                  CRC Editorial Team
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box className=" py-4">
              <Link
                className=" !no-underline"
                to={`/blog/${latestArticle.slug}/${latestArticle.id}`}
              >
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "18px", sm: "24px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" capitalize sidebar-glide"
                >
                  {latestArticle.title}
                </Typography>
              </Link>
            </Box>
            <Box className="">
              <Typography
                variant="body"
                fontSize={{ xs: "13px", sm: "13px" }}
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className=" opacity-60 line-clamp-3"
              >
                Thoroughly engage with this outstanding piece of content,
                absorbing its wealth of information and insights, and immersing
                yourself in its compelling and enlightening narrative.
              </Typography>

              <ReadMore
                label={" Read More"}
                className="cursor-pointer"
                to={`/blog/${latestArticle.slug}/${latestArticle.id}`}
              />
            </Box>
            <Box className=" py-3" display={"flex"} alignItems={""}>
              <Box>
                <PersonOutline className="!text-xl" />

                <Typography
                  variant="caption"
                  fontSize={{ xs: "13px", sm: "13px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="capitalize pl-2"
                >
                  {latestArticle.author}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ArticleCard;
