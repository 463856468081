import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { Box } from "@mui/material";
import { RulesTenetsCard } from "../components/About/RulesTenetsCard";
import { CCCHistoryCard } from "../components/About/CCCHistoryCard";
import { AnnualEvents } from "../components/Events/AnnualEvents";
import { JustificationCard } from "../components/About/JustificationCard";
import OtherEvents from "../components/Events/OtherEvents";

const Event = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/event_vbbeev.jpg`}
        header="Events"
        text=" Discover upcoming events - engage, learn, and connect with our vibrant community. Join us in shaping unforgettable experiences."
      />
      <Box className="" display="flex" justifyContent="center">
        <Tabs value={value} onChange={handleChange} className="">
          <Tab label="Upcoming Events" />
          <Tab label="Annual Events" />
        </Tabs>
      </Box>
      {/* Content for the selected tab */}
      <Box className=" py-12">
        {value === 0 && <OtherEvents />}
        {value === 1 && <AnnualEvents />}
      </Box>
    </Box>
  );
};

export default Event;
