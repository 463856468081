import React from "react";
import OtherForumComments from "./OtherForumComments";
import UserForumComments from "./UserForumComments";
import { useAuth } from "../../../context/app.context";

const ForumComments = ({ message }) => {
  const { isAuthenticated, userData } = useAuth();
  // console.log("UserData ID: ", String(userData.id));
  // console.log("UserData ID: ", Number(userData.id));
  // console.log("Messages ", message);

  return (
    <React.Fragment>
      {message &&
        message.map((comment) =>
          comment.userId === userData.id ? (
            <UserForumComments comment={comment} key={comment.userId} />
          ) : (
            <OtherForumComments comment={comment} key={comment.userId} />
          )
        )}
    </React.Fragment>
  );
};

export default ForumComments;
