import { gql } from "@apollo/client";

// Get all Users
export const GET_USERS = gql`
  query User {
    getUsers {
      id
      firstname
      lastname
      email
      avatar
      gender
      dob
      social
      country
      totalInteractions
      createdAt
    }
  }
`;

// View a single User by id
export const GET_USER = gql`
  query User($id: ID!) {
    getUserById(id: $id) {
      id
      firstname
      lastname
      email
      avatar
      gender
      dob
      social
      country
      totalInteractions
      createdAt
    }
  }
`;

// Login User by email and password
export const LOGIN_USER = gql`
  query User($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      token
    }
  }
`;

// Refresh User Session Token
export const REFRESH_USER_SESSION = gql`
  query User($userId: ID!) {
    refreshUserToken(userId: $userId) {
      token
    }
  }
`;

//  User Forgot Password Token
export const USER_FORGOT_PASSWORD = gql`
  query User($email: String!) {
    forgotUserPassword(email: $email) {
      token
    }
  }
`;

// Sign up User
export const SIGNUP_USER = gql`
  mutation SignUpUserMutation(
    $firstname: String!
    $lastname: String!
    $avatar: Upload!
    $dob: Date!
    $email: String!
    $password: String!
    $gender: String!
    $country: String!
    $social: String!
  ) {
    signUpUser(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
      dob: $dob
      avatar: $avatar
      gender: $gender
      country: $country
      social: $social
    ) {
      email
    }
  }
`;

// Update User Details
export const UPDATE_USER_AVATAR = gql`
  mutation UpdateUserMutation($email: String!, $avatar: Upload!) {
    updateUserAvatar(email: $email, avatar: $avatar) {
      email
      avatar
    }
  }
`;

// Change User Email
export const USER_CHANGE_EMAIL = gql`
  mutation changeUserEmailMutation(
    $email: String!
    $newemail: String!
    $password: String!
  ) {
    changeUserEmail(email: $email, newemail: $newemail, password: $password) {
      firstname
      lastname
      email
    }
  }
`;
// Update User Details
export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUserMutation(
    $firstname: String!
    $lastname: String!
    $country: String!
    $email: String!
    $gender: String!
    $social: String!
    $dob: Date!
  ) {
    updateUserDetails(
      email: $email
      gender: $gender
      firstname: $firstname
      lastname: $lastname
      country: $country
      social: $social
      dob: $dob
    ) {
      id
      email
      firstname
      lastname
      country
      gender
      social
      dob
      createdAt
    }
  }
`;

// Renew User Password
export const RENEW_USER_PASSWORD = gql`
  mutation RenewUserPasswordMutation($token: String!, $password: String!) {
    renewUserPassword(token: $token, password: $password) {
      email
    }
  }
`;

// Get all Partners
export const GET_PARTNERS = gql`
  query Partner {
    getPartners {
      id
      name
      description
      imgSrc
      slug
      createdAt
    }
  }
`;

// View a single Partner by id
export const GET_PARTNER = gql`
  query Partner($id: ID!) {
    getPartnerById(id: $id) {
      id
      name
      description
      imgSrc
      slug
      createdAt
    }
  }
`;

// // Delete a User
// export const DELETE_USER = gql`
//   mutation DeleteUserMutation($id: ID!) {
//     deleteUser(id: $id) {
//       id
//     }
//   }
// `;
