import { useMutation } from "@apollo/client";
import React from "react";
import useUserIP from "../../hook/useUserIP";
import { ADD_VISITOR } from "../../graphql/other-queries";
import Cookies from "universal-cookie";

const GetVisitors = () => {
  const [addVisitor, { loading, error, data }] = useMutation(ADD_VISITOR);
  const ipaddress = useUserIP();

  const cookies = new Cookies();

  const getUserIpAddress = async () => {
    const iptoken = cookies.get("crc-ip-token");

    if (!iptoken) {
      if (ipaddress !== "") {
        try {
          const { data } = await addVisitor({
            variables: { ipaddress },
          });

          const expirationDate = new Date();
          expirationDate.setTime(
            expirationDate.getTime() + 24 * 60 * 60 * 1000
          ); // Expires after 24 hours

          cookies.set("crc-ip-token", ipaddress, {
            path: "/",
            expires: expirationDate,
          });

          // Handle successful form submission (if needed)
          console.log("Ip Address added:", data);
        } catch (error) {
          // Display error alert for server error
          console.error("Ip Address error:", error.message);
        }
      }
    }
  };

  React.useEffect(() => {
    getUserIpAddress();
  }, [ipaddress]);

  return null;
};

export default GetVisitors;
