import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

const FeatureCards = ({ color, icon, title, content }) => {
  return (
    <React.Fragment>
      <Card className="rounded-2xl shadow-lg shadow-gray-500/10 mx-auto md:w-[70%] lg:h-72 lg:w-[100%]">
        <CardContent className="px-8 text-center bg-[#fff]">
          <Avatar
            sx={{ bgcolor: "#eeeeee", p: 4 }}
            className=" flex place-content-center mx-auto"
          >
            <IconButton
              variant="gradient"
              size="xl"
              color={color}
              className="pointer-events-none mb-6 rounded-full "
            >
              {icon}
            </IconButton>
          </Avatar>
          <br />
          <Typography
            variant="subheader"
            fontSize={{ xs: "24px", sm: "28px", md: "24px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="mb-2"
            color={color}
          >
            {title}
          </Typography>
          <br />
          <Typography
            variant="body"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className=" opacity-60 line-clamp-3"
            color={color}
          >
            {content}
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default FeatureCards;
