import React, { useContext, useState } from "react";
import { Box, FormGroup, Grid, Alert } from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { LoaderContext } from "../../context/loader.context";
import { useQuery } from "@apollo/client";
import { USER_FORGOT_PASSWORD } from "../../graphql/user-queries";
import { useNavigate } from "react-router-dom";

const ForgotPassForm = () => {
  let navigate = useNavigate();
  const [submitClicked, setSubmitClicked] = React.useState(false);
  const loaderContext = useContext(LoaderContext);
  const [email, setEmail] = useState("");
  const [msg, setmsg] = useState("");

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  // Using the LOGIN_ADMIN query conditionally based on submitClicked
  const { loading, error, data } = useQuery(USER_FORGOT_PASSWORD, {
    variables: { email: email },
    skip: !submitClicked,
    onCompleted: (data) => {
      if (data && data.forgotUserPassword) {
        const token = data.forgotUserPassword.token;
        console.log("Password Token", token);
        // senMailToEmail(token)
        closeLoader();
        setmsg("Check your email for instructions");
        setTimeout(() => {
          navigate(`/signin`);
        }, 3000);
      }
    },
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === "") {
      // console.log("Empty fields:");
      return;
    }

    // console.log("Email", email);

    // Now added refresh Token just like sign in
    setSubmitClicked(true);
    openLoader();
    // console.log("Forgot Password clicked");
  };

  return (
    <form onSubmit={handleSubmit} className="w-full md:w-3/4 lg:w-2/5">
      {error && (
        <Alert variant="filled" severity="error" className="mt-4">
          {error.message === "Unexpected error."
            ? "Email not found"
            : error.message}
        </Alert>
      )}
      {msg === "Check your email for instructions" ? (
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      ) : null}
      <FormGroup className="">
        <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className="">
          <Grid item xs={12} md={12}>
            <CRCTextField
              label="Email"
              name="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box className="mx-auto mt-8">
              <CRCbutton label={"Submit"} type={"submit"} />
            </Box>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default ForgotPassForm;
