import { Box, Typography } from "@mui/material";
import React from "react";
import TextHeader from "../../custom/Layout/TextHeader";

const AboutCRCmini = ({ name, text, bio }) => {
  return (
    <React.Fragment>
      <TextHeader header={name} text={text} />
      <Box className="w-[90%] text-center mx-auto py-4">
        <Typography
          variant="subtitle"
          fontSize={{ xs: "15px", sm: "18px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="mx-auto place-content-center "
        >
          {bio}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default AboutCRCmini;
