import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { Box } from "@mui/material";
import PDFViewer from "../custom/Layout/PDFViewer";

const OrderOfService = () => {
  let pdfFileUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/designs/CCC-Order-Of-Service.pdf`;
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/rules_lbjqwa.jpeg`}
        header="CCC Order of Service"
        text="Our church services adhere to a structured order of worship, ensuring a meaningful and organized spiritual experience for our congregation."
      />
      <Box className=" !mx-auto py-12 place-content-center">
        {/* Embed React PDF here */}
        <PDFViewer fileUrl={pdfFileUrl} />
      </Box>
    </React.Fragment>
  );
};

export default OrderOfService;
