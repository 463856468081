import React from "react";
import PersonnelCard from "../components/About/PersonnelCard";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";

const Evangelism = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/new-bible.jpeg`}
        header="Evangelism in CCC"
        text="Dedicated evangelists in our church organization, spreading God's word with unwavering zeal and compassion, leading souls to a deeper connection with faith and salvation."
      />
      <PersonnelCard
        title="Men of God of our time"
        content="We are thrilled to collaborate with dedicated evangelists to passionately spread the Gospel, sharing God's message of love, hope, and salvation."
        personnelType="ccc-evangelist"
      />
    </React.Fragment>
  );
};

export default Evangelism;
