import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ADD_SUBSCRIBER } from "../../graphql/other-queries";
import { useContext } from "react";
import { LoaderContext } from "../../context/loader.context";

const SubscribeForm = () => {
  let navigate = useNavigate();

  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [addSubscriber, { loading, error, data }] = useMutation(ADD_SUBSCRIBER);
  const [msg, setMsg] = useState("");

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Email:", email);
    try {
      const { data } = await addSubscriber({
        variables: { email },
      });

      // Handle successful form submission (if needed)
      console.log("Subscribed successfully:", data);
      closeLoader();
      setMsg("Subscribed successfully");
      setTimeout(() => {
        // navigate(`/profile`);
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Display error alert for server error
      console.error("Post Creating error:", error.message);
    }
  };

  return (
    <React.Fragment>
      {msg === "Subscribed successfully" ? (
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      ) : null}
      <form onSubmit={handleSubmit}>
        <FormGroup className="w-full">
          <Grid
            container
            spacing={{ xs: 0, sm: 2, md: 2 }}
            className=" w-[100%] flex place-content-center"
          >
            <Grid item xs={6} md={6}>
              <CRCTextField
                label="Email"
                name="email"
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Box className="mx-auto mt-8">
            <Grid item xs={12} md={12}>
              <CRCbutton label={"Subscribe"} type={"submit"} />
            </Grid>
          </Box>
        </FormGroup>
      </form>
    </React.Fragment>
  );
};

export default SubscribeForm;
