import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCradio from "../../custom/Forms/CRCradio";
import CRCSelect from "../../custom/Forms/CRCSelect";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { countries } from "../../static/constant";
import { Link, useNavigate } from "react-router-dom";
import CRCFileUpload from "../../custom/Forms/CRCFileUpload";
import { useMutation } from "@apollo/client";
import { SIGNUP_USER } from "../../graphql/user-queries";
import { useContext } from "react";
import { LoaderContext } from "../../context/loader.context";
import { formatDBdate } from "../../hook/date-format";

const SignUpForm = () => {
  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [signUpUser, { loading, error, data }] = useMutation(SIGNUP_USER);
  const [msg, setMsg] = useState("");
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    avatar: "",
    email: "",
    password: "",
    gender: "",
    dob: "",
    social: "",
    country: "",
  });

  const handleCountryChange = (selectedCountry) => {
    setFormData({ ...formData, country: selectedCountry });
    setMsg("");
  };

  const handleGenderChange = (selectedGender) => {
    setFormData({ ...formData, gender: selectedGender });
    setMsg("");
  };

  const [inputAvatarValue, setInputAvatarValue] = useState("");

  const handleAvatarChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      setFormData({ ...formData, avatar: selectedFile });
      setInputAvatarValue(selectedFile.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      setMsg("Please input all fields");
      return;
    }

    console.log(formData);

    try {
      const { data } = await signUpUser({
        variables: formData,
      });

      openLoader();
      // Handle successful form submission (if needed)
      // console.log("Signup success:", data);

      if (data.signUpUser.email === null) {
        closeLoader();
        setMsg("Email already exists");
      } else {
        closeLoader();
        setMsg("Profile created successfully");
        setTimeout(() => {
          navigate(`/signin`);
        }, 3000);
      }
    } catch (error) {
      // Display error alert for server error
      console.error("Signup error:", error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        {loading && <CircularProgress />} {/* Display loading indicator */}
        {msg === "Email already exists" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : msg === "Please input all fields" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : msg === "Profile created successfully" ? (
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        ) : null}
        <br />
        {/* Display error alert */}
        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="First Name"
              name="fname"
              type="name"
              onChange={(e) => {
                setFormData({ ...formData, firstname: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="Last Name"
              name="lname"
              type="name"
              onChange={(e) => {
                setFormData({ ...formData, lastname: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 2 }} className="">
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="Email"
              name="email"
              type="email"
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="Password"
              name="password"
              type="password"
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className="">
          <Grid item xs={12} md={6}>
            <CRCSelect
              label="Select a country"
              values={countries} // Assuming country is an array of countries
              placeholder="Select a country"
              onChange={handleCountryChange} // Pass the handler to update the selected country
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CRCTextField
              label="Facebook e.g: https://www.facebook.com/johndoe"
              name="social"
              type="name"
              onChange={(e) => {
                setFormData({ ...formData, social: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 2 }} className=" pl-2">
          <Grid item xs={12} md={6}>
            <CRCTextField
              label={formData.dob === "" ? "" : formData.dob}
              name="dob"
              type="date"
              onChange={(e) => {
                let date = formatDBdate(e.target.value);
                setFormData({ ...formData, dob: date });
                console.log("DOB", date);
                setMsg("");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CRCradio
              value="Gender"
              option1={"male"}
              option2={"female"}
              onChange={handleGenderChange}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <CRCFileUpload
              label="Select an image"
              name="avatar"
              type="file"
              value={inputAvatarValue}
              onChange={handleAvatarChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box className="mx-auto mt-8">
            <CRCbutton label={"Submit"} type={"submit"} />
          </Box>
        </Grid>
        <Grid item className="px-2 flex place-content-end">
          <Typography variant="subtitle" color="initial">
            Already have an account?
          </Typography>
          <Link to="/signin" variant="body">
            <Typography variant="subtitle" color="initial">
              <span className="underline pl-2">Sign In</span>
            </Typography>
          </Link>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default SignUpForm;
