// src/Routes.js
import React, { useContext } from "react";
import Home from "../pages/Home";
import SampleApp from "../theme/mui/sample";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../context/app.context";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ForgotPassword from "../pages/auth/ForgotPassword";
import NewPassword from "../pages/auth/NewPassword";
import About from "../pages/About";
import Forum from "../pages/user/routes/Forum";
import Profile from "../pages/user/routes/Profile";
import Settings from "../pages/user/routes/Settings";
import ForumRoom from "../pages/user/forum/ForumRoom";
import Event from "../pages/Event";
import Article from "../pages/Article";
import TokenSessionChecker from "../custom/Layout/TokenSessionChecker";
import { LoaderContext } from "../context/loader.context";
import PageLoader from "../custom/Layout/PageLoader";
import ViewArticle from "../components/Article/ViewArticle";
import AboutCRC from "../pages/AboutCRC";
import CCChistory from "../pages/CCChistory";
import BiblicalJustification from "../pages/BiblicalJustification";
import RulesTenets from "../pages/RulesTenets";
import Gallery from "../pages/Gallery";
import GetVisitors from "../custom/Layout/GetVisitors";
import Contact from "../pages/Contact";
import ChangeEmail from "../pages/auth/ChangeEmail";
import Partners from "../pages/Partners";
import ViewPartners from "../pages/ViewPartners";
import Evangelism from "../pages/Evangelism";
import Artiste from "../pages/Artiste";
import { PageError } from "../pages/PageError";
import BibleSearch from "../pages/BibleSearch";
import Constitutions from "../pages/Constitutions";
import Hymns from "../pages/Hymns";
import OrderOfService from "../pages/OrderOfService";
import WeeklyShow from "../pages/WeeklyShow";
import Reprimanded from "../pages/Reprimanded";

const ProtectedRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/" replace />;
  }
};
const ForumProtectedRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/signin" replace />;
  }
};

const Navigator = () => {
  const loaderContext = useContext(LoaderContext);

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/sample" element={<SampleApp />} /> */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/update-email" element={<ChangeEmail />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:slug" element={<NewPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/about/crc" element={<AboutCRC />} />
        <Route path="/about/tenets" element={<RulesTenets />} />
        <Route path="/about/ccc" element={<CCChistory />} />
        <Route
          path="/about/justification"
          element={<BiblicalJustification />}
        />
        <Route path="/events" element={<Event />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Article />} />
        <Route path="/blog/:slug/:id" element={<ViewArticle />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/partners/:slug/:id" element={<ViewPartners />} />
        <Route path="/bible-search" element={<BibleSearch />} />
        <Route path="/ccc-constitution" element={<Constitutions />} />
        <Route path="/ccc-hymns" element={<Hymns />} />
        <Route path="/ccc-order-of-service" element={<OrderOfService />} />
        <Route path="/celestial/evangelism" element={<Evangelism />} />
        <Route path="/celestial/artiste" element={<Artiste />} />
        <Route path="/weekly-show" element={<WeeklyShow />} />
        <Route path="/reprimanded" element={<Reprimanded />} />

        {/* User Protected Routes Start */}
        <Route
          path="/community"
          element={<ForumProtectedRoute element={<Forum />} />}
        />
        <Route
          path="/community/:slug/:id"
          element={<ForumProtectedRoute element={<ForumRoom />} />}
        />
        <Route
          path="/profile"
          element={<ProtectedRoute element={<Profile />} />}
        />
        <Route
          path="/settings"
          element={<ProtectedRoute element={<Settings />} />}
        />

        <Route path="*" element={<PageError />} />

        {/* User Protected Routes End */}
        {/* <Route component={ErrorPage} /> */}
      </Routes>
      <TokenSessionChecker />
      <GetVisitors />
      {loaderContext.showState && <PageLoader />}
    </React.Fragment>
  );
};

export default Navigator;
