import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { artCateg } from "../../static/static";

const SortArticle = ({ onSortChange }) => {
  const [activeCategory, setActiveCategory] = React.useState(artCateg[0]);

  const handleSortChange = (newSortValue) => {
    // console.log("Sort clicked:", newSortValue);
    setActiveCategory(newSortValue); // Update the active category when clicked
    onSortChange(newSortValue); // Call the onSortChange prop with the selected sorting criteria
  };

  return (
    <React.Fragment>
      <Box
        className="h-12 bg-white text-center drop-shadow-md space-x-6 shadow-md z-30"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {artCateg.map((categ, index) => (
          <Link
            key={index}
            onClick={() => handleSortChange(categ)}
            className="category-link"
          >
            <Typography
              variant={`${categ === activeCategory ? "header" : "subheader"}`}
              fontSize={{ xs: "15px", sm: "15px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className={`uppercase ${
                categ === activeCategory ? "!text-red-500 " : "text-primary"
              }`}
            >
              {categ}
            </Typography>
          </Link>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default SortArticle;
