import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { formatDay, formatDayMonth } from "../../hook/date-format";

const CongratCard = ({ user }) => {
  // Inside your component
  const isMobileOrTablet = useMediaQuery("(max-width: 600px)"); // Adjust the max-width as needed
  const avatarSize = isMobileOrTablet ? 150 : 150;

  return (
    <React.Fragment>
      <Card
        sx={{ width: 300, height: 450 }}
        className="!mx-auto shadow-2xl drop-shadow-2xl rounded-3xl place-content-center"
      >
        <CardHeader sx={{ height: "150px", bgcolor: "#ff9800" }} />
        <Box className="absolute top-32 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Avatar
            alt={user.firstname}
            src={user.avatar}
            sx={{ width: avatarSize, height: avatarSize }}
          />
        </Box>
        <CardContent className="mt-10 text-center grid">
          <Typography
            variant="body"
            color="primary"
            fontSize={{ xs: "13px", sm: "16px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="capitalize"
          >
            🎊 Happy Birthday 🎊
          </Typography>
          <Typography
            variant="header"
            color="primary"
            fontSize={{ xs: "16px", sm: "20px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            {user.firstname} {user.lastname}
          </Typography>
          <Typography
            variant="caption"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className=""
          >
            {formatDayMonth(user.dob)}
          </Typography>
          <Typography
            variant="body"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className=" opacity-60"
          >
            May God's grace surround you on this special day, filling your life
            with joy, love, and purpose. Happy birthday! May your journey be
            blessed with His light always. Amen.
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default CongratCard;
