import React from "react";
import { sortByMutableAscending } from "../../hook/arrange";
import { GET_PARTNERS } from "../../graphql/user-queries";
import { useQuery } from "@apollo/client";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import Marquee, { MarqueeItem } from "../../global/Marquee";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";

const PartnersMarquee = () => {
  //   Query to fetch getEventsByID
  const { loading, error, data } = useQuery(GET_PARTNERS, {
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // Ensure data.getEvents exists before using it
  const partners = data?.getPartners || [];

  // Use the sorting function to sort the data
  const newData = sortByMutableAscending(partners, "createdAt");
  return (
    <Box>
      <Box
        className=" py-4"
        justifyContent={"center"}
        display={"flex"}
        alignItems={"center"}
      >
        <Avatar
          sx={{ bgcolor: "secondary.light", p: 3 }}
          className=" flex  my-2 shadow-md drop-shadow-md"
        >
          <IconButton
            variant="gradient"
            size="lg"
            color=""
            className="pointer-events-none mb-6 rounded-full "
          >
            <HandshakeOutlinedIcon className=" !text-white" />
          </IconButton>
        </Avatar>
        <Typography
          variant="header"
          //   fontSize={{ xs: "18px", sm: "32px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="pl-4 capitalize"
        >
          Partners
        </Typography>
      </Box>
      <Marquee continuous>
        {newData.map((imag, index) => (
          <MarqueeItem>
            <img src={imag.imgSrc} key={index} alt="" className="w-40 h-40" />
          </MarqueeItem>
        ))}
      </Marquee>
    </Box>
  );
};

export default PartnersMarquee;
