// import { ApolloClient, InMemoryCache } from "@apollo/client";

// const client = new ApolloClient({
//   uri: process.env.REACT_APP_SERVER_URL, // Your GraphQL server URL
//   cache: new InMemoryCache(),
// });

// export default client;

// apollo.js
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const link = createUploadLink({
  uri: process.env.REACT_APP_SERVER_URL, // Replace with your GraphQL server URL
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
