import React from "react";
import MainPagesHeader from "../../../custom/Layout/MainPagesHeader";
import ForumCard from "../forum/ForumCard";
import SortForum from "../forum/SortForum";
import { Box } from "@mui/material";

const Forum = () => {
  const [sortCriteria, setSortCriteria] = React.useState(""); // State to hold the selected sorting criteria

  const handleSortChange = (newSortCriteria) => {
    setSortCriteria(newSortCriteria);
  };
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/forum_mbfwym.jpg`}
        header="Celestial Parliament Community"
        text=" Explore our dynamic community for the latest trending discussions, from contemporary topics to insightful biblical dialogues."
      />
      <SortForum
        onSortChange={handleSortChange}
        activeCategory={sortCriteria}
      />
      <Box className="lg:px-32 md:px-6 px-6 ">
        <ForumCard sortCriteria={sortCriteria} />
      </Box>
    </React.Fragment>
  );
};

export default Forum;
