import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CRCDialog from "../../custom/Dialog/CRCDialog";
import TextHeader from "../../custom/Layout/TextHeader";

const AboutCEO = () => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box className=" py-20">
        <TextHeader header="Editor-in-Chief" text="" />

        <Box
          className=" lg:flex lg:justify-center lg:gap-6 h-full"
          alignItems={"center"}
        >
          <Box className="order-first md:h-[500px] md:w-[500px] h-[343px] w-[343px] mx-auto lg:mx-0 ">
            <img
              src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/prophet_victor.jpeg`}
              alt="Image"
              className="h-[350px] md:h-[450px] lg:h-[570px] w-full rounded-[6px] object-contain lg:object-cover md:mt-10 lg:ml-16"
            />
          </Box>
          <Box
            alignItems={"center"}
            className="order-last flex text-center mx-auto  w-[343px] h-auto md:w-[630px] "
          >
            <Box className="grid md:mx-auto lg:mx-0">
              <Typography
                variant="h1"
                color="subItalic"
                fontSize={{ xs: "20px", sm: "32px" }}
                lineHeight={{ xs: "143%", sm: "120.5%" }}
                letterSpacing={"-0.04em"}
                className="w-[302px] md:w-[560px] mx-auto text-left md:text-center lg:text-left pt-6 pb-2"
              >
                Prophet Victor Ola-Ore Omoshehin.
              </Typography>
              <Typography
                // variant="body2"
                color="primary"
                fontSize={{ xs: "13px", sm: "14px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="md:w-[560px] w-[340px] mx-auto text-start line-clamp-6"
              >
                Victor Ola-Ore Omoshehin was born on July 10th, 1979, to the
                family of Sup. Evang. Thompson Abiodun Omoshehin and MC. Esther
                Olayinka Omoshehin from Ilaje Ondo State. He is a trained
                viralogist, a human rights advocate, and a treatment activist.
                He started as a community advocate for the poor masses in the
                slum communities of Lagos State. Prophet Victor Omoshehin has
                served with doctors without boarders (MSF- France and Canada)
                from 2006-2010. He worked at the General Hospital Lagos Island
                and Nigerian Institute of Medical Research (NIMR), Yaba Lagos,
                before proceeding to the Federal Capital territory.
              </Typography>{" "}
              <Link onClick={handleClickOpen} className=" !no-underline">
                <Typography
                  variant="caption"
                  fontSize={{ xs: "13px", sm: "13px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="capitalize flex"
                  color="primary"
                >
                  ...Read more
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <CRCDialog
        title={"About Prophet Victor Ola-Ore Omoshehin"}
        onClose={handleClose}
        open={open}
      >
        <Box className="grid">
          <Typography
            variant="body"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className=" capitalize"
          >
            Victor Ola-Ore Omoshehin was born on July 10th, 1979, to the family
            of Sup. Evang. Thompson Abiodun Omoshehin and MC. Esther Olayinka
            Omoshehin from Ilaje Ondo State.
            <br />
            <br />
            He is a trained viralogist, a human rights advocate, and a treatment
            activist. He started as a community advocate for the poor masses in
            the slum communities of Lagos State.
            <br />
            <br />
            Prophet Victor Omoshehin has served with doctors without boarders
            (MSF- France and Canada) from 2006-2010. He worked at the General
            Hospital Lagos Island and Nigerian Institute of Medical Research
            (NIMR), Yaba Lagos, before proceeding to the Federal Capital
            territory.
            <br />
            <br />
            Prophet Victor has traveled across 16 countries and has visited
            general and state hospitals across 34 states of Nigeria in 437 Local
            Government Areas (LGA) for monitoring, evaluation, and supportive
            supervision.
            <br />
            <br />
            He was an active expert who pushed the course and human rights of
            patients across public healthcare centers.
            <br />
            <br />
            He was an ambassador of Peace Mission of Christian Aid to Ghana to
            strengthen her healthcare systems on service deliverables for people
            living with HIV in 2005. He was a Nigeria delete to the World Health
            Organization (WHO) Conference on the establishment of public policy
            on people living with HIV and cancer to sexual reproductive health
            and rights in Addis Ababa in 2006.
            <br />
            <br />
            He was a former member of the Global Fund for AID, TB and Malaria,
            Country Coordinating Mechanisms (CCM). He was appointed by Mr.
            President Mohamed Buhari as a member of the Board of Trustees of
            National Agency for the Control of AIDS ( NACA), where he
            represented the Federal Ministry of Health until his retirement in
            2019.
            <br />
            <br />
            He was the immediate past Vice Chairman of International Treatment
            Preparedness Coaliation (ITPC), West African Region.
            <br />
            <br />
            Victor Omoshehin was the immediate past National Secretary of
            Treatment Action Movement ( TAM), an advocates platform promoting
            access to comprehensive medications, treatment, care, and support
            services for patients across West Africa Region.
            <br />
            <br />
            He was the immediate past National Coordinator of NEPWHAN and APYIN
            in Nigeria.
            <br />
            <br />
            He worked at Heartland Alliance International as Program Director
            and Project Manager both at FCT and Rivers States from 2010 through
            2013.
            <br />
            <br />
            Victor had trained over 3,000 young people in Northern Nigeria on
            promotion of sexual reproductive health and right educations among
            young people with physical and developmental disabilities, sex
            workers, prison inmates, and sexual minorities.
            <br />
            <br />
            Prophet Victor Ola-Ore is the current director of Celestial
            International College in Apapa, Lagos. He is the founder of
            Celestial Welfare and the medical team, a group of professional
            healthcare delivering free access to routine primary health care
            education, testing, and treatment among CCC and their host
            communities.
            <br />
            <br />
            Victor is blessed with family (wife and children). He is a loving
            father of both biological and adopted children whose
            responsibilities he has to shoulder.
            <br />
            <br />
            He has granted access to free education to 30 children for all their
            years in the nursery, primary, secondary, and at the Universities
            Level.
            <br />
            <br />
            Victor is a Venerable most Superior Evang of the Celestial Church of
            Christ, but he loved to be addressed as Prophet Victor.
            <br />
            <br />
            He is a philanthropist and a lover of the gospel.
            <br />
            <br />
            He was awarded by the American Embassy in Nigeria and United States
            President Emergency Response Plan for AIDS Relief (PEPFAR), Federal
            Ministry of Health, and National Agency for the Control of AIDS
            (NACA) among others.
            <br />
            <br />
            He is on the Board of Trustees of Chartered Institute of Leadership
            and Governance Institute, a fellow member of the Institute of
            Humanitarian Studies, a member Chartered Institute of Administration
            (CIA), a fellow of the Project Management College United Kingdom
            among others.
          </Typography>
        </Box>
      </CRCDialog>
    </React.Fragment>
  );
};

export default AboutCEO;
