import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CardMedia,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { formatDay } from "../../hook/date-format";
import { sortByCreatedAtDescending } from "../../hook/arrange";
import { GET_ARTICLES } from "../../graphql/other-queries";
import ReadMore from "./ReadMore";

const ArticleCard = ({ sortCriteria }) => {
  const [word, setWord] = React.useState("");
  let location = useLocation();

  // State variables to manage pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 9; // Change this to the number of items you want per page

  // Query to fetch getArticles data
  const { loading, error, data } = useQuery(GET_ARTICLES, {
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // Filter the data based on the keyword
  const filteredData = data.getArticles.filter(
    (item) =>
      item.title.toLowerCase().includes(word.toLowerCase()) ||
      item.content.toLowerCase().includes(word.toLowerCase()) ||
      item.createdAt.toLowerCase().includes(word.toLowerCase())
  );

  // Use the sorting function to sort the data
  sortByCreatedAtDescending(filteredData, "createdAt");

  const categoryFilteredData =
    sortCriteria === "all" || sortCriteria === ""
      ? filteredData // Return all data when sortCriteria is "All" or empty
      : filteredData.filter((item) => item.category === sortCriteria);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = categoryFilteredData.slice(startIndex, endIndex);

  return (
    <React.Fragment>
      <Box className="">
        <CssBaseline />
        <Grid
          container
          spacing={2}
          className="!pt-6 md:!mx-auto place-content-center"
        >
          {currentPageData.length > 0 ? (
            currentPageData.map((post) => (
              <React.Fragment key={post.id}>
                <Grid item xs={12} sm={6} md={4}>
                  <Link
                    to={`${location.pathname}/${post.slug}/${post.id}`}
                    className="cursor-pointer !no-underline"
                  >
                    <img
                      className="object-cover bg-cover shadow-md drop-shadow-md place-content-center mx-auto !h-64 !w-96"
                      src={post.contentpic}
                      alt={post.title}
                      title="Click to view more"
                    />
                  </Link>
                  <Box className=" py-4 mx-auto text-start grid">
                    <Typography
                      variant="body"
                      fontSize={{ xs: "13px", sm: "13px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className=" opacity-60 text-start line-clamp-3"
                    >
                      {formatDay(post.createdAt)}
                    </Typography>
                    <Link
                      to={`${location.pathname}/${post.slug}/${post.id}`}
                      className="cursor-pointer !no-underline"
                    >
                      <Typography
                        variant="header"
                        //   lineHeight={{ xs: "200%", sm: "180%" }}
                        fontSize={{ xs: "20px", sm: "20px" }}
                        letterSpacing={"0.02em"}
                        color="text.primary"
                        className="line-clamp-2 capitalize text-start"
                      >
                        {post.title}
                      </Typography>
                    </Link>
                    <Link
                      className=" !no-underline !text-sm"
                      target="_blanc"
                      to={`${post.social}`}
                    >
                      Author: {post.author}
                    </Link>

                    <ReadMore
                      to={`${location.pathname}/${post.slug}/${post.id}`}
                      className="cursor-pointer sidebar-glide w-[30%] md:w-[27%]"
                      label={"Read Article"}
                    />
                  </Box>
                </Grid>
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body" color="primary">
              No Post available
            </Typography>
          )}
        </Grid>

        {/* Pagination controls */}
        <Box className="text-center mt-4 mx-auto flex  place-content-center ">
          {currentPage > 1 && (
            // <CRCbutton
            //   variant="outlined"
            //   color="primary"
            //   onClick={() => setCurrentPage(currentPage - 1)}
            //   label={"Prev"}
            // />
            <IconButton
              color="primary"
              aria-label="Next"
              onClick={() => setCurrentPage(currentPage - 1)}
              className="ml-0 !mr-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </IconButton>
          )}
          {endIndex < categoryFilteredData.length && (
            <IconButton
              color="primary"
              aria-label="Next"
              onClick={() => setCurrentPage(currentPage + 1)}
              className="mr-0 !ml-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </IconButton>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ArticleCard;
