import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CRCFileUpload = ({ label, type, onChange, value }) => {
  return (
    <Box
      component="div"
      sx={{
        "& .MuiTextField-root": {
          m: 1,
          width: "100%",
          fontFamily: "Bricolage Grotesque",
          maxWidth: "100%",
        },
      }}
      noValidate
      autoComplete="on"
    >
      {/* Add an input for file uploads if the type is "file" */}
      {type === "file" && (
        <>
          <input
            type="file"
            accept="image/*"
            style={{
              position: "absolute",
              left: "-9999px",
            }}
            onChange={onChange}
          />
          <label>
            <TextField
              label={label}
              variant="standard"
              fullWidth
              value={value}
              InputProps={{
                readOnly: true,
                onClick: () => {
                  // Trigger the hidden file input
                  document.querySelector("input[type='file']").click();
                },
              }}
            />
          </label>
          {/* {imagePath && (
            <img
              src={imagePath}
              alt="Selected Image"
              style={{ maxWidth: "100%" }}
            />
          )} */}
        </>
      )}
    </Box>
  );
};

export default CRCFileUpload;
