import React from "react";
import AboutCRCmini from "../components/About/AboutCRCmini";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import AboutCEO from "../components/About/AboutCEO";

const AboutCRC = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/about-crc_sgt9sl.jpg`}
        header="Celestial Reality Check"
        text="CRC promotes transparency, accountability, and social development in the Celestial Church of Christ, advocating for freedom of speech and parishioner involvement in governance."
      />
      <AboutCRCmini
        name="CRC Biography"
        text="A concise overview of Celestial Reality Check"
        bio={`Celestial Reality Check started on March 2021 as a platform to share
        information, review activities, and checkmate bad eggs within the
        Celestial Church of Christ and the Christian fold. The platform is
        championing the course for transparency and accountability for social
        development in the Church across all levels. CRC is promoting advocacy
        for positive changes in Celestial Church of Christ to protect right to
        expression, freedom of speech, and parishioners' active participation
        in the governance and spiritual leadership process. According to 2
        Samuel 7:14 "I will be a father to him, and he will be a son to me;
        when he commits iniquity, I will correct him with the rod of men and
        the strokes of the sons of men." CRC is a platform made of young
        people from different parishes across the globe who are chastising
        others who are going against the positive image of the Church through
        correction, counseling, and public opinion. Psalm 94:12 Blessed is the
        man whom You chasten, O Lord, And whom You teach out of Your law.`}
      />

      <AboutCEO />
    </React.Fragment>
  );
};

export default AboutCRC;
