import React from "react";
import ForgotPassCard from "../../components/Auth/ForgotPassCard";
import PagesHeader from "../../custom/Layout/PagesHeader";

const ForgotPassword = () => {
  return (
    <React.Fragment>
      <PagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/support_u4yjtj.png`}
      >
        <ForgotPassCard />
        <br />
        <br />
        <br />
      </PagesHeader>
    </React.Fragment>
  );
};

export default ForgotPassword;
