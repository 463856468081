import React from "react";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContentCopyRounded, WhatsApp } from "@mui/icons-material";
import { Typography } from "@mui/material";

const ShareContent = ({ contentUrl }) => {
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    contentUrl
  )}`;

  const whatsappShareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    contentUrl
  )}`;

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      console.log("Copied!");
      toast(`Article link copied`, {
        type: "success",
      });
    } catch (err) {
      console.log("Failed to copy!");
    }
  };

  return (
    <div className="flex gap-2 items-center">
      <ToastContainer />
      {/* <p>Share This Article</p> */}
      <Typography
        variant="body"
        color="primary"
        fontSize={{ xs: "18px", sm: "18px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
      >
        Share This Article
      </Typography>
      <div className="flex gap-2">
        <button
          onClick={() => window.open(facebookShareLink, "_blank")}
          className="share-button"
        >
          <FacebookOutlinedIcon className=" text-[#4968ad] !text-3xl" />
        </button>

        <button
          onClick={() => window.open(whatsappShareLink, "_blank")}
          className="share-button"
        >
          <WhatsApp className=" text-[#25D366] !text-3xl" />
        </button>
        <button
          onClick={(e) => copyToClipBoard(contentUrl)}
          className="share-button"
        >
          <ContentCopyRounded className=" text-[#000000] !text-3xl" />
        </button>
      </div>
    </div>
  );
};

export default ShareContent;
