import { Avatar, Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import ProfileInteraction from "../profile/ProfileInteraction";
import { useQuery } from "@apollo/client";
import { GET_USER_ARTICLES } from "../../../graphql/other-queries";
import { useAuth } from "../../../context/app.context";
import { Link } from "react-router-dom";

const PlainUserHeader = ({ children, imgSrc }) => {
  const { isAuthenticated, logoutUser, userData } = useAuth();

  let image = imgSrc;

  // Inside your component
  const isMobileOrTablet = useMediaQuery("(max-width: 600px)"); // Adjust the max-width as needed
  const avatarSize = isMobileOrTablet ? 100 : 200;

  return (
    <>
      <Box className="relative flex h-screen content-center -z-50 items-center justify-center">
        <Box
          className={`absolute top-0 h-[50%] w-full `}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box className="absolute top-0 h-[50%] w-full bg-black/75 bg-cover bg-center" />
      </Box>
      <Box className=" mx-auto place-content-center -mt-[700px] profilecard md:-mt-[900px] lg:-mt-[600px]   !h-full w-[90%]">
        <Box className="bg-white relative h-full rounded-2xl p-6 md:p-12">
          <Box className="">
            <Box className="absolute top-10 left-4 md:left-4 lg:left-24 transform grid grid-cols-3 md:flex">
              <Link to={"/update-email"} className="md:!no-underline">
                <Typography
                  variant="body"
                  fontSize={{ xs: "12px", sm: "16px", md: "20px" }}
                  lineHeight={{ xs: "100%", sm: "120%" }}
                  letterSpacing={"0.02em"}
                  color="primary"
                  className=" text-center w-20 md:w-full"
                >
                  Change user email
                </Typography>
              </Link>
            </Box>

            <Box className="absolute top-10 right-4 md:right-4 lg:right-24 transform flex ">
              <Link to={"/forgotpassword"} className="md:!no-underline">
                <Typography
                  variant="body"
                  fontSize={{ xs: "12px", sm: "16px", md: "20px" }}
                  lineHeight={{ xs: "100%", sm: "120%" }}
                  letterSpacing={"0.02em"}
                  color="primary"
                  className=" text-center w-20 md:w-full"
                >
                  Reset password
                </Typography>
              </Link>
            </Box>
          </Box>

          <Box className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Avatar
              alt={userData.firstname}
              src={userData.avatar}
              sx={{ width: avatarSize, height: avatarSize }}
            />
          </Box>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default PlainUserHeader;
