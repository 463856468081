import { Avatar, Box, Container, CssBaseline, IconButton } from "@mui/material";
import React from "react";
import SubscribeForm from "./SubscribeForm";
import TextHeader from "../../custom/Layout/TextHeader";
import { NewspaperOutlined } from "@mui/icons-material";

const SubscribeCard = () => {
  return (
    <React.Fragment>
      <section id="contactUs">
        <Box className="bg-white">
          <Container component="main" maxWidth="3xl">
            <CssBaseline />
            <Box className=" w-full md:w-[70%] lg:w-[50%] mx-auto py-10">
              <Avatar
                sx={{ bgcolor: "secondary.light", p: 3 }}
                className=" flex place-content-center mx-auto"
              >
                <IconButton
                  variant="gradient"
                  size="lg"
                  className="pointer-events-none mb-6 rounded-full "
                >
                  <NewspaperOutlined className="text-[#fff]" />
                </IconButton>
              </Avatar>

              <TextHeader
                header="Subscribe to Our NewsLetter"
                text="Get updates on latest articles, gist and events"
              />
              <SubscribeForm />
            </Box>
          </Container>
        </Box>
      </section>
    </React.Fragment>
  );
};

export default SubscribeCard;
