import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";

import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import CRCSelect from "../../../custom/Forms/CRCSelect";
import CRCTextField from "../../../custom/Forms/CRCTextField";
import { LoaderContext } from "../../../context/loader.context";
import { useMutation } from "@apollo/client";
import { useAuth } from "../../../context/app.context";
import { ADD_ARTICLE } from "../../../graphql/other-queries";
import CRCeditor from "../../../custom/Forms/CRCeditor";

const category = ["article", "gist"];
const CreateArticle = () => {
  const { isAuthenticated, userData, loginUser, logoutUser } = useAuth();
  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [addArticle, { loading, error, data }] = useMutation(ADD_ARTICLE);
  const [msg, setMsg] = useState("");
  const [authors, setAuthors] = useState("");
  const [socials, setSocials] = useState("");
  const [authorState, setAuthorState] = useState(true);
  const name = userData.firstname + " " + userData.lastname;
  // console.log("Author: ", name);

  const [formData, setFormData] = useState({
    title: "",
    author: "",
    contentpic: "",
    content: "",
    social: "",
    email: userData.email,
    category: "",
    status: "pending",
  });

  const handleCategoryChange = (selectedCategory) => {
    setFormData({ ...formData, category: selectedCategory });
    setMsg("");
  };
  const handleAuthorChange = () => {
    setAuthorState(!authorState);
  };

  React.useEffect(() => {
    if (authorState === true) {
      setFormData({
        ...formData,
        author: authors || name,
        social: socials || userData.social,
      });
    }

    // console.log(authorState, formData.author, formData.social);
  }, [authorState, userData, authors, socials]);

  const handleContent = (content) => {
    setFormData({ ...formData, content });
    setMsg("");
  };

  const [contentPick, setContentPick] = useState("");

  const handleContentPicChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      setFormData({ ...formData, contentpic: selectedFile });
      setContentPick(selectedFile.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // openLoader();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      setMsg("Please input all fields");
      return;
    }
    // if (formData.contentpic === "") {
    //   setMsg("Please Select an Image");
    // }

    console.log("FormData", formData);

    try {
      openLoader();
      const { data, error } = await addArticle({
        variables: formData,
      });

      if (error) {
        console.log(error, "ERROR");
      }

      if (data.addArticle) {
        // Handle successful form submission (if needed)
        console.log("Article Created successfully:", data);
        closeLoader();
        setMsg("Article created successfully");
        setTimeout(() => {
          // navigate(`/profile`);
          window.location.reload();
        }, 3000);
      } else {
        closeLoader();
        console.error("Article upload failed", error);
      }
    } catch (err) {
      // Display error alert for server error
      console.error("Article Creating error:", err.message);
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
        <form onSubmit={handleSubmit}>
          <FormGroup>
            {loading && <CircularProgress />} {/* Display loading indicator */}
            {msg === "Article created successfully" ? (
              <Alert variant="filled" severity="success">
                {msg}
              </Alert>
            ) : msg === "Please input all fields" ? (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            ) : null}
            <br />
            {/* Display error alert */}
            <Grid container spacing={{ xs: 0, md: 2 }}>
              <Grid item xs={12} md={6}>
                <CRCTextField
                  label="Title"
                  name="title"
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {authorState === false ? (
                  <CRCTextField
                    label="Author"
                    name="author"
                    type="name"
                    value={formData.author}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        author: e.target.value,
                      });
                      setMsg("");
                    }}
                  />
                ) : (
                  <TextField
                    fullWidth
                    variant="standard"
                    label={name}
                    name="Author"
                    type="name"
                    className=" !mt-2"
                    disabled
                  />
                )}
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={authorState}
                      onChange={handleAuthorChange}
                      name=""
                    />
                  }
                  label={`${
                    authorState === true
                      ? "I am the author"
                      : "I am not the author"
                  }`}
                /> */}
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className="">
              <Grid item xs={12} md={6}>
                {authorState === false ? (
                  <CRCTextField
                    label="Social"
                    name="social"
                    type="name"
                    value={formData.social}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        social: e.target.value,
                      });
                      setMsg("");
                    }}
                  />
                ) : (
                  <TextField
                    fullWidth
                    variant="standard"
                    label={userData.social}
                    name="Author"
                    type="name"
                    className=" !mt-2"
                    disabled
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={authorState}
                      onChange={handleAuthorChange}
                      name=""
                    />
                  }
                  label={`${
                    authorState === true
                      ? "I am the author"
                      : "I am not the author"
                  }`}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className="">
              <Grid item xs={12} md={6}>
                <CRCSelect
                  label="Select Category"
                  values={category} // Assuming country is an array of countries
                  placeholder="Select Category"
                  onChange={handleCategoryChange} // Pass the handler to update the selected country
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CRCFileUpload
                  label="Select an article image"
                  name="article image"
                  type="file"
                  value={contentPick}
                  onChange={handleContentPicChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 2 }} className="">
              <Grid item xs={12} md={12}>
                <CRCeditor value={formData.content} onChange={handleContent} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="mx-auto mt-8 w-[60%] md:w-[40%]">
                <CRCbutton label={"Publish Article"} type={"submit"} />
              </Box>
            </Grid>
          </FormGroup>
        </form>
      </div>
    </React.Fragment>
  );
};

export default CreateArticle;
