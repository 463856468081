import React from "react";
import { useAuth } from "../../../context/app.context";
import { Avatar, Box, Tab, Tabs, Typography } from "@mui/material";
import { formatDay } from "../../../hook/date-format";
import { useQuery } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_USER_ARTICLES } from "../../../graphql/other-queries";
import UserArticlesTable from "../profile/UserArticlesTable";
import CreateArticle from "../profile/CreateArticle";
import ProfileHeader from "../profile/ProfileHeader";
import Cookies from "universal-cookie";

const Profile = () => {
  const [value, setValue] = React.useState(0);
  const cookies = new Cookies();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { isAuthenticated, userData, loginUser, logoutUser } = useAuth();
  // console.log(userData, "userData");

  const [userArticles, setUserArticles] = React.useState("");

  const { data: articleData } = useQuery(GET_USER_ARTICLES, {
    variables: { email: userData.email },
    pollInterval: 500,
  });

  let tokenbadge = cookies.get("crc-badge");
  let token3rd = cookies.get("crc-token-3rd");
  let token2nd = cookies.get("crc-token-2nd");
  let token1st = cookies.get("crc-token-1st");
  let tokenPlatinum = cookies.get("crc-token-platinum");

  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 31 * 24 * 60 * 60 * 1000); // Expires after 31 day X 24 hours

  React.useEffect(() => {
    // console.log(tokenbadge);
    if (tokenbadge === undefined || "") {
      if (token3rd === "crc-token-3rd") {
        toast(`Congratulations, You are a 3rd tier fan`, {
          type: "success",
        });
        cookies.set("crc-badge", "crc-badge", {
          path: "/",
          expires: expirationDate,
        });
      } else if (token2nd === "crc-token-2nd") {
        toast(`Congratulations, You are a 2nd tier fan `, {
          type: "success",
        });
        cookies.set("crc-badge", "crc-badge", {
          path: "/",
          expires: expirationDate,
        });
      } else if (token1st === "crc-token-1st") {
        toast(`Congratulations, You are a 1st tier fan `, {
          type: "success",
        });
        cookies.set("crc-badge", "crc-badge", {
          path: "/",
          expires: expirationDate,
        });
      } else if (tokenPlatinum === "crc-token-platinum") {
        toast(`Congratulations, You are a Platinum fan `, {
          type: "success",
        });
        cookies.set("crc-badge", "crc-badge", {
          path: "/",
          expires: expirationDate,
        });
      }
    }
  }, [tokenbadge, token3rd, token2nd, token1st, tokenPlatinum]);

  React.useEffect(() => {
    if (articleData) {
      // console.log("USER ARTICLES", articleData.getUserArticles);
      //   Filter Articles by like
      const totalArticle = articleData.getUserArticles;
      setUserArticles(totalArticle);
    }
  }, [articleData]);

  const getBadge = () => {
    if (userData.totalInteractions >= 20 && userData.totalInteractions <= 99) {
      cookies.set("crc-token-3rd", "crc-token-3rd", {
        path: "/",
        expires: expirationDate,
      });
      return (
        <Avatar
          alt="3rd Badge Fan"
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/third_yduzm8.png`}
        />
      );
    } else if (
      userData.totalInteractions >= 100 &&
      userData.totalInteractions <= 199
    ) {
      return (
        <Avatar
          alt="2nd Badge Fan"
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/second_so6fr2.png`}
        />
      );
    } else if (
      userData.totalInteractions >= 200 &&
      userData.totalInteractions <= 399
    ) {
      return (
        <Avatar
          alt="1st Badge Fan"
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/first_o5o14e.png`}
        />
      );
    } else if (userData.totalInteractions >= 2000) {
      return (
        <Avatar
          alt="Platinum Fan"
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/top_l2cgwd.png`}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <ProfileHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/stairs_xvcwfq.jpg`}
      >
        <ToastContainer />
        <Box className="mt-24 md:mt-16 mx-auto flex place-content-center">
          <Typography
            variant="header"
            color="primary"
            fontSize={{ xs: "20px", sm: "30px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            {userData.firstname} {userData.lastname}
          </Typography>
          {getBadge()}
        </Box>

        <Box className=" mx-auto flex place-content-center items-center gap-1">
          <Typography
            variant="header"
            color="primary"
            fontSize={{ xs: "15px", sm: "16px", md: "18px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            Memeber Since :
          </Typography>

          <Typography
            variant="body"
            color="primary"
            fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            {formatDay(userData.createdAt)}
          </Typography>
        </Box>
        <Box className=" mx-auto flex place-content-center items-center gap-1">
          <Typography
            variant="body"
            color="primary"
            fontSize={{ xs: "14px", sm: "16px", md: "20px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            {userData.email}
          </Typography>
        </Box>

        <Box className="pt-5" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="My Articles" className="" />
            <Tab label="Create Article" />
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <UserArticlesTable data={userArticles} />}
          {value === 1 && <CreateArticle />}
        </Box>
      </ProfileHeader>
    </React.Fragment>
  );
};

export default Profile;
