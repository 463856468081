import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  GET_ALL_BIBLE_BOOKS_CHAPTERS,
  GET_VERSES,
} from "../graphql/other-queries";

import { useQuery } from "@apollo/client";
import CRCbutton from "../custom/Forms/CRCbutton";
import { Link } from "react-router-dom";

const SearchBibleVerse = () => {
  const [bookChapter, setBookChapter] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [formData, setFormData] = useState({
    bookChapter: "",
    chapter: 0, // Initialize as 0
    verse: "", // Initialize as null
  });

  const [verses, setVerses] = useState([]); // Store verses here

  // Query to fetch getBibleBooks data
  const { loading, error, data } = useQuery(GET_ALL_BIBLE_BOOKS_CHAPTERS, {
    pollInterval: 500,
  });

  // Query to fetch getBibleVerses data
  const {
    loading: verseLoading,
    error: verseError,
    data: verseData,
  } = useQuery(GET_VERSES, {
    variables: {
      book_name: formData.bookChapter,
      chapter: formData.chapter,
      verse: formData.verse,
    },
    skip: !clicked,
    onCompleted: (data) => {
      if (data && data.getBibleVerses) {
        setVerses(verseData.getBibleVerses); // Store verses
      }
    },
  });

  useEffect(() => {
    if (data) {
      setBookChapter(data.getBibleBookChapters);
    }
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.length === 0) {
      console.log("Please input data");
    } else {
      setClicked(true);
    }
  };

  const clearSearch = () => {
    setClicked(false);
    setFormData({
      bookChapter: "",
      chapter: 0,
      verse: "",
    });
    setVerses([]);
  };

  const selectedBookObject = bookChapter.find(
    (book) => book.book_name === formData.bookChapter
  );

  const handleChapterChange = (e) => {
    const chapter = e.target.value;
    setFormData({ ...formData, chapter });
  };

  // if (loading) return <p>Loading...</p>;
  if (loading) {
    return (
      <div className="text-center">
        <Typography
          variant="subItalic"
          fontSize={{ xs: "18px", sm: "22px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="pl-4  capitalize text-center"
        >
          Loading Bible Data...
        </Typography>
      </div>
    );
  }
  if (error) return <p>Error : {error.message}</p>;
  return (
    <React.Fragment>
      <Box className=" mx-auto">
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent={"center"}
          // justifySelf={"center"}
        >
          <Grid item>
            <Select
              labelId="book-select-label"
              id="book-select"
              value={formData.bookChapter}
              onChange={(e) =>
                setFormData({ ...formData, bookChapter: e.target.value })
              }
            >
              {bookChapter
                .slice() // Create a shallow copy of the original array to avoid mutating it
                .sort((a, b) => a.book - b.book) // Sort by id in ascending order
                .map((bookName, index) => (
                  <MenuItem value={bookName.book_name} key={bookName.book}>
                    {bookName.book_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item>
            {selectedBookObject && (
              <Select
                labelId="chapter-select-label"
                id="chapter-select"
                value={formData.selectedChapter}
                onChange={handleChapterChange}
              >
                <MenuItem value="">Select a Chapter</MenuItem>
                {selectedBookObject.chapters
                  .map((chapter) => parseInt(chapter)) // Convert chapters to integers for sorting
                  .sort((a, b) => a - b) // Sort the chapters in ascending order
                  .map((chapter, index) => (
                    <MenuItem value={chapter} key={index}>
                      {chapter}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="1 OR 1-12"
              name="content"
              type="text"
              value={formData.verse}
              onChange={(e) =>
                setFormData({ ...formData, verse: e.target.value })
              }
            />
          </Grid>
          <Grid item>
            <CRCbutton
              label={"Search"}
              type={"submit"}
              onClick={handleSubmit}
              rounded
            />
          </Grid>
        </Grid>
      </Box>

      <Box className=" w-full mx-auto p-4 lg:px-40">
        <Box className=" place-content-end">
          {clicked &&
            selectedBookObject &&
            formData.chapter &&
            formData.verse && (
              <Typography
                variant="subItalic"
                fontSize={{ xs: "25px", sm: "30px" }}
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="capitalize pr-2"
                title="Click to view more"
              >
                {formData.bookChapter} {formData.chapter}:{formData.verse}
              </Typography>
            )}

          {clicked &&
            selectedBookObject &&
            formData.chapter &&
            formData.verse &&
            verses.map((verse, index) => (
              <Box className="" key={verse?._id}>
                {verse && (
                  <Typography
                    variant="caption"
                    fontSize={{ xs: "20px", sm: "20px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className="grid"
                    title="Click to view more"
                  >
                    {verse.text}
                  </Typography>
                )}
              </Box>
            ))}
          {clicked && <Link onClick={clearSearch}>Clear Search</Link>}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SearchBibleVerse;
