import React from "react";
import PersonnelCard from "../components/About/PersonnelCard";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";

const Artiste = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/microphone.jpeg`}
        header="Celestial Artiste Corners"
        text="Passionate artists within CCC, devoted to using their creative talents to inspire and uplift others through the power of faith and artistry."
      />
      <PersonnelCard
        title="Gospel Arstite that makes the difference"
        content="We are excited to collaborate with the talented artistes within CCC to creatively spread the Gospel, sharing God's love and message of hope through the music and arts."
        personnelType="ccc-artiste"
      />
    </React.Fragment>
  );
};

export default Artiste;
