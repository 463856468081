import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import UserCard from "./UserCard";
import TextHeader from "../../custom/Layout/TextHeader";
import { useQuery } from "@apollo/client";
import { GET_PERSONNELS } from "../../graphql/other-queries";
import { useEffect } from "react";
import {
  filterDataByCateg,
  sortByCreatedAtAscending,
  sortByCreatedAtDescending,
} from "../../hook/arrange";

const PersonnelCard = ({ personnelType, title, content, tech }) => {
  // Query to fetch getPersonnels data
  const { loading, error, data } = useQuery(GET_PERSONNELS, {
    pollInterval: 500,
  });
  const [personnels, setPersonnels] = useState([]);
  const team = ["Oyeleye", "Asolo"];
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error : {error.message}</p>;

  const fileteredDataz = data?.getPersonnels
    ? filterDataByCateg(data.getPersonnels, personnelType)
    : [];

  const sortData = sortByCreatedAtAscending(fileteredDataz);

  const filteredTechTeam = sortData.some((person) =>
    team.includes(person.lastname)
  )
    ? sortData.filter((person) => team.includes(person.lastname))
    : [];

  useEffect(() => {
    if (data) {
      if (tech === "tech_team") {
        // console.log(data.getPersonnels);
        setPersonnels(filteredTechTeam);
        // console.log("Tech  Data", filteredTechTeam);
        // console.log("Tech", personnels);
      } else {
        setPersonnels(sortData);
        // console.log("Normal Data", fileteredDataz);
        // console.log("Normal Member", personnels);
      }
    }
  }, [data, tech]);

  return (
    <React.Fragment>
      <TextHeader header={title} text={content} />

      <Box className=" grid text-center ml-auto mr-auto"></Box>

      {personnels && <UserCard users={personnels} />}
    </React.Fragment>
  );
};

export default PersonnelCard;
