import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Menu, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../context/app.context";
import CRCMiniAppBar from "./CRCMiniAppBar";
import { pagesIcon, userPagesIcon } from "../../static/static";
import AppBarSelector from "../Forms/AppBarSelector";
import AppDrawerSelector from "../Forms/AppDrawerSelector";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function CRCAppBar({ routes }) {
  const [scrollBackground, setScrollBackground] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [moreRes, setMoreRes] = useState("");
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const isBigScreen = window.innerWidth > 700; // Adjust the breakpoint as needed

  // Getting login state
  const { isAuthenticated, logoutUser, userData } = useAuth();
  const loginState = isAuthenticated;

  let navigate = useNavigate();

  const handleExtraChange = (selectedExtra) => {
    setMoreRes(selectedExtra);
  };

  const handleLogout = () => {
    logoutUser();
    setMobileDrawerOpen(!mobileDrawerOpen);
    navigate("/");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrollBackground(true);
    } else {
      setScrollBackground(false);
    }
  };

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: scrollBackground ? "primary" : "transparent",
          transition: "background-color 0.3s",
          boxShadow: scrollBackground
            ? "0px 1px 5px rgba(0, 0, 0, 0.5)"
            : "none",
        }}
      >
        {loginState === false ? (
          <CRCMiniAppBar
            sx={{
              backgroundColor: scrollBackground ? "#e0f2f1" : "transparent",
              transition: "background-color 0.3s",
            }}
            color={scrollBackground ? "#404040" : "#FFF"}
          />
        ) : null}
        <Toolbar disableGutters>
          {/* CRC LOGO */}
          <Box style={{ display: "flex", alignItems: "center" }}>
            {/* <AdbIcon style={{ marginRight: 1 }} /> */}
            <Avatar
              alt="CRC"
              src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/crc512_r3usay.png`}
              style={{ marginRight: 5 }}
            />
            <Typography
              variant="header"
              noWrap
              component={Link}
              to="/"
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              style={{
                marginRight: 1,
                fontFamily: "PlayFair Display",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: scrollBackground ? "#FFF" : "#FFF",
                textDecoration: "none",
              }}
            >
              CRC
            </Typography>
          </Box>
          {isBigScreen && (
            <>
              <Box
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
                className="gap-4"
              >
                {routes.map((page, index) => (
                  <Link
                    key={page.name}
                    component={Link}
                    to={page.path}
                    onClick={handleCloseNavMenu}
                    style={{
                      marginTop: 8,
                      color: "#FFC107",
                      textDecoration: "none",
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#FFC107",
                      },
                    }}
                    className="header-glide"
                  >
                    <IconButton
                      style={{
                        padding: 0,
                        marginRight: 5,
                        color: scrollBackground ? "#FFF" : "#FFF",
                      }}
                    >
                      {page.icon}
                    </IconButton>

                    <Typography
                      variant="subtitle"
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      color={scrollBackground ? "#FFF" : "#FFF"}
                    >
                      {page.name}
                    </Typography>
                  </Link>
                ))}
                <AppBarSelector />
              </Box>
              <Box style={{ flexGrow: 0 }}>
                {loginState === true ? (
                  <IconButton
                    onClick={toggleMobileDrawer}
                    style={{ padding: 0, marginRight: 5 }}
                  >
                    <Avatar alt={userData.firstname} src={userData.avatar} />
                  </IconButton>
                ) : null}
              </Box>
            </>
          )}
          <Box style={{ marginLeft: "auto" }}>
            {!isBigScreen && ( // Only show on mobile screens
              <IconButton
                onClick={toggleMobileDrawer}
                sx={{ padding: 0, marginRight: 1 }} // Remove marginLeft and set padding to 0
              >
                <MenuIcon style={{ color: "#FFF" }} />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile */}
      <Drawer
        anchor="right"
        open={mobileDrawerOpen}
        onClose={toggleMobileDrawer}
        sx={{
          display: {
            sm: "block",
            xs: "block",
            fontFamily: "Bricolage Grotesque",
          },
        }} // Show only on mobile
      >
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            paddingTop: 2,
          }}
        >
          {loginState === true ? (
            <>
              <Box className="flex place-content-center">
                <Avatar
                  alt={userData.firstname}
                  src={userData.avatar}
                  sx={{ width: 60, height: 60, marginBottom: 1 }}
                />
              </Box>
              <Typography
                variant="subheader"
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="!mb-4"
                align="center"
                gutterBottom
              >
                {userData.firstname}
              </Typography>
            </>
          ) : (
            // CRC LOGO
            <Box
              style={{ display: "flex", alignItems: "center" }}
              className="place-content-center py-4"
            >
              {/* <AdbIcon style={{ marginRight: 1 }} /> */}
              <Avatar
                alt="CRC"
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/crc512_r3usay.png`}
                style={{ marginRight: 5 }}
              />
              <Typography
                variant="header"
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                noWrap
                component={Link}
                to="/"
                style={{
                  marginRight: 1,
                  fontFamily: "PlayFair Display",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: scrollBackground ? "#404040" : "#404040",
                  textDecoration: "none",
                }}
              >
                CRC
              </Typography>
            </Box>
          )}

          <Box className="mx-auto ">
            {isBigScreen ? null : (
              <>
                <>
                  {pagesIcon.map((page) => (
                    <Link
                      key={page.name}
                      to={page.path} // Assuming your routes follow this pattern
                      onClick={toggleMobileDrawer}
                      style={{ textDecoration: "none", color: "white" }}
                      className=" cursor-pointer"
                    >
                      <Box className="sidebar-glide py-2 space-x-1 mx-auto">
                        <IconButton
                          style={{
                            color: scrollBackground ? "#40404" : "#40440",
                          }}
                        >
                          {page.icon}
                        </IconButton>
                        <Typography
                          variant="body"
                          lineHeight={{ xs: "200%", sm: "180%" }}
                          letterSpacing={"0.02em"}
                          align="center"
                        >
                          {page.name}
                        </Typography>
                      </Box>
                    </Link>
                  ))}
                </>
                <AppDrawerSelector />
              </>
            )}

            {loginState === true ? (
              <Box className="mx-auto pt-1">
                {userPagesIcon.map((page) => (
                  <Link
                    key={page.name}
                    to={page.path} // Assuming your routes follow this pattern
                    onClick={
                      page.name === "Logout" ? handleLogout : toggleMobileDrawer
                    }
                    style={{ textDecoration: "none", color: "inherit" }}
                    className=" py-2 cursor-pointer"
                  >
                    <Box className="sidebar-glide py-2 space-x-1">
                      <IconButton
                        style={{
                          color: scrollBackground ? "#40404" : "#40440",
                        }}
                      >
                        {page.icon}
                      </IconButton>
                      <Typography
                        variant="body"
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        className=" "
                        align="center"
                      >
                        {page.name}
                      </Typography>
                    </Box>
                  </Link>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default CRCAppBar;
