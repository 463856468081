import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

function CRCMiniAppBar({ color, sx }) {
  return (
    <>
      <Box style={sx} className="flex gap-4 place-content-end pr-4 w-full h-6">
        <Typography
          variant="subtitle"
          // fontSize={{ xs: "13px", sm: "13px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          color={color}
          noWrap
          component={Link}
          to="/signin"
        >
          Sign in
        </Typography>
        <Typography
          variant="subtitle"
          // fontSize={{ xs: "13px", sm: "13px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          noWrap
          color={color}
          component={Link}
          to="/signup"
        >
          Sign up
        </Typography>
      </Box>
    </>
  );
}

export default CRCMiniAppBar;
