import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ForumHeader = ({ header, text, imgSrc, icon, date, author }) => {
  let image = imgSrc;

  return (
    <>
      <Box className="relative flex h-[400px] content-center -z-50 items-center justify-center">
        <Box
          className={`absolute top-0 h-[100%] w-full `}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box className="absolute top-0 h-[100%] w-full bg-black/75 bg-cover bg-center" />
        <Box className="max-w-8xl container relative mx-auto -mt-1">
          <Box className="flex flex-wrap items-center place-content-center">
            <Box className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
              <Typography
                variant="subheader"
                color="white"
                fontSize={{ xs: "30px", sm: "40px" }}
                // lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="mb-6 font-black capitalize  line-clamp-2"
              >
                {header}
              </Typography>
            </Box>
            <Box className=" w-[80%] lg:w-[50%] text-center">
              <Typography
                variant="subtitle"
                fontSize={{ xs: "13px", sm: "13px" }}
                // lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                color="white"
                className="opacity-80 line-clamp-2"
              >
                {text}
              </Typography>
              <Typography
                variant="caption"
                fontSize={{ xs: "16px", sm: "18px" }}
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                color="white"
                className="grid"
              >
                {author && `Author: ${author}`}
              </Typography>

              <Typography
                variant="body"
                fontSize={{ xs: "15px", sm: "15px" }}
                // lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                color="white"
                className="opacity-80"
              >
                {date && date}
              </Typography>
            </Box>
          </Box>
          <Box className="flex flex-wrap items-center place-content-center">
            {icon}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForumHeader;
