import { useQuery } from "@apollo/client";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { GET_POSTS } from "../../../graphql/other-queries";
import { Link } from "react-router-dom";
import { formatDay } from "../../../hook/date-format";

const OtherPosts = ({ handleClick }) => {
  const [word, setWord] = React.useState("");

  // Query to fetch getPosts data
  const { loading, error, data } = useQuery(GET_POSTS, { pollInterval: 500 });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  //   Filter the data based on the keyword
  const filteredData = data.getPosts.filter(
    (item) =>
      item.title.toLowerCase().includes(word.toLowerCase()) ||
      item.content.toLowerCase().includes(word.toLowerCase()) ||
      item.createdAt.toLowerCase().includes(word.toLowerCase())
  );
  // console.log(filteredData);

  return (
    <React.Fragment>
      <Box className="h-full border-r-4 border-r-gray-200 bg-gray-50 hidden md:block">
        <Box
          //   className=" gap-4"
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
        >
          <Box
            className="w-[90%]"
            component="div"
            sx={{
              "& .MuiTextField-root": {
                width: "100%",
                // maxWidth: "100%",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Bricolage Grotesque",
              },
            }}
          >
            <TextField
              variant="standard"
              label={`${word === "" ? "search posts" : ""}`}
              name="word"
              type="name"
              onChange={(e) => setWord(e.target.value)}
              InputProps={{
                sx: {
                  fontFamily: "Bricolage Grotesque",
                },
              }}
              inputProps={{
                minLength: 4,
              }}
            />
          </Box>
        </Box>
        <Box>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              maxHeight: 815,
              overflowY: "auto",
              bgcolor: "background.paper",
            }}
          >
            {filteredData.map((post) => (
              <>
                <Link
                  to={`/community/${post.slug}/${post.id}
                      `}
                  key={post.id}
                  className=" !no-underline !list-none"
                >
                  <ListItem className="cursor-pointer hover:bg-slate-200 !ml-0">
                    <ListItemAvatar>
                      <Avatar alt={post.title} src={post.contentpic} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            // component="span"
                            variant="subheader"
                            lineHeight={{ xs: "200%", sm: "180%" }}
                            fontSize={{ xs: "15px", sm: "15px" }}
                            letterSpacing={"0.02em"}
                            color="text.primary"
                            className=" !line-clamp-2"
                          >
                            {post.title}
                          </Typography>

                          <Typography
                            sx={{ display: "grid" }}
                            variant="body"
                            lineHeight={{ xs: "200%", sm: "180%" }}
                            fontSize={{ xs: "13px", sm: "13px" }}
                            letterSpacing={"0.02em"}
                            color="text.primary"
                            className=""
                          >
                            {formatDay(post.createdAt)}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <Typography
                          sx={{ display: "inline" }}
                          variant="body"
                          lineHeight={{ xs: "200%", sm: "180%" }}
                          fontSize={{ xs: "15px", sm: "15px" }}
                          letterSpacing={"0.02em"}
                          color="text.primary"
                          className=" !line-clamp-2 opacity-60"
                        >
                          {post.content}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider
                    variant="inset"
                    component="li"
                    className=" !list-none"
                  />
                </Link>
              </>
            ))}
          </List>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default OtherPosts;
