import Typography from "@mui/material/Typography";
import CRCErrorBadge from "../../custom/Badge/CRCErrorBadge";
import CRCSuccessBadge from "../../custom/Badge/CRCSuccessBadge";
import CRCWarningBadge from "../../custom/Badge/CRCWarningBadge";
import CRCDefaultBadge from "../../custom/Badge/CRCDefaultBadge";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { Send } from "@mui/icons-material";
import CRCradio from "../../custom/Forms/CRCradio";
import CRCregister from "../../custom/UI/CRCregister";
import CRCimageButton from "../../custom/Forms/CRCimageButton";
import CRCPagination from "../../custom/Pagination/CRCPagination";
import CRCDialog from "../../custom/Dialog/CRCDialog";
import { useState } from "react";
import { countries } from "../../static/constant";

const images = [
  {
    url: "/static/images/buttons/breakfast.jpg",
    title: "Breakfast",
    width: "33.3%",
  },
  {
    url: "/static/images/buttons/burgers.jpg",
    title: "Burgers",
    width: "33.3%",
  },
  {
    url: "/static/images/buttons/camera.jpg",
    title: "Camera",
    width: "33.3%",
  },
];

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function SampleApp() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10; // Total number of pages

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // You can perform other actions, like fetching data for the new page, here.
  };
  return (
    <div className="SampleApp">
      <Typography
        variant="header"
        // fontSize={{ xs: "13px", sm: "13px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        color="primary"
        className="text-green-800"
      >
        Celestial Reality Check
      </Typography>{" "}
      <br />
      <br />
      <Typography
        variant="subheader"
        // fontSize={{ xs: "13px", sm: "13px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        color="primary"
        className="text-green-800"
      >
        Celestial Reality Check
      </Typography>{" "}
      <br />
      <br />
      <Typography
        variant="subItalic"
        // fontSize={{ xs: "13px", sm: "13px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        color="primary"
        className="text-green-800"
      >
        Celestial Reality Check
      </Typography>{" "}
      <br />
      <br />
      <Typography
        variant="body"
        // fontSize={{ xs: "13px", sm: "13px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        color="primary"
        className="text-green-800"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
        obcaecati aliquid perferendis. Fugiat iste ipsa facilis nisi neque,
        consequuntur architecto repellat illum doloremque earum quo nemo
        accusantium culpa dignissimos quia.
      </Typography>{" "}
      <br />
      <br />
      <Typography
        variant="caption"
        // fontSize={{ xs: "13px", sm: "13px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        color="primary"
        className="text-green-800"
      >
        Celestial Reality Check
      </Typography>
      <br />
      <br />
      <Typography
        variant="subtitle"
        // fontSize={{ xs: "13px", sm: "13px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        color="primary"
        className="text-green-800"
      >
        Celestial Reality Check
      </Typography>
      <br />
      <br />
      <CRCErrorBadge status="incomplete" />
      <CRCSuccessBadge status="successful" />
      <CRCWarningBadge status="try again" />
      <CRCDefaultBadge status="completed" />
      <CRCTextField label={"Name"} fullwidth={true} />
      <CRCbutton
        icon={<Send />}
        label={"Submit"}
        value={true}
        width={"300px"}
        onClick={() => {
          console.log("Hello");
        }}
      />
      <CRCregister />
      <CRCimageButton images={images} />
      <CRCradio value="What are you?" option1={"man"} option2={"woman"} />
      <br />
      <br />
      <CRCDialog />
      <br />
      <br />
      <CRCPagination data={countries} itemsPerPage={10} />
    </div>
  );
}

export default SampleApp;
