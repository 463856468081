import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Typography } from "@mui/material";

export default function CRCradio({ value, option1, option2, onChange }) {
  const typographyStyle = {
    fontFamily: "Bricolage Grotesque, sans-serif",
  };

  const [selectedValue, setSelectedValue] = React.useState(value);

  const handleRadioChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);
    onChange(selectedOption);
  };

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">
        <Typography
          variant="body"
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          color="primary"
          style={typographyStyle}
        >
          {value}
        </Typography>
      </FormLabel>

      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        value={selectedValue}
        name="row-radio-buttons-group"
        required
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value={option1}
          control={<Radio />}
          label={<Typography style={typographyStyle}>{option1}</Typography>}
        />
        <FormControlLabel
          value={option2}
          control={<Radio />}
          label={<Typography style={typographyStyle}>{option2}</Typography>}
        />
      </RadioGroup>
    </FormControl>
  );
}
