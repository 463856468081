import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import GalleryWeb from "../components/Gallery/GalleryWeb";
import GalleryMobile from "../components/Gallery/GalleryMobile";
import { GET_GALLERY } from "../graphql/other-queries";
import { useQuery } from "@apollo/client";
import { filterDataByType } from "../hook/arrange";

// export const media = [
//   {
//     title: "CRC Medical Services",
//     description: "We offer free medical services",
//     imgSrc: "assets/medical-checkup.jpeg",
//   },
//   {
//     title: "Rev.Pastor Emmanuel M. Oshoffa",
//     description: "The Pastor & Spiritual Head of CCC W/W",
//     imgSrc: "assets/emf2022.jpeg",
//   },
//   {
//     title: "Pastor's Interview at Emmanuel 2022",
//     description: "Recap of the Emmanuel 2022 event",
//     imgSrc: "assets/emf-2022.jpeg",
//   },
//   {
//     title: "Celestial Soccer Challenge 2023",
//     description: "Winner of the Celestial Soccer Challenge 2023 edition",
//     imgSrc: "assets/csc-winner.jpeg",
//   },
//   {
//     title: "CRC Media Team",
//     description: "Faces behind our amazing shoots and video coverage",
//     imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/crc-media_yh9pnx.jpg`,
//   },
//   {
//     title: "CRC Food Drive 2022",
//     description: "We offer free medical services",
//     imgSrc: "assets/food2022.jpeg",
//   },
//   {
//     title: "Prophet Evans at Illumination 2023",
//     description: "Prophet Evans ministration at Illumination 2023",
//     imgSrc: "assets/pro-evans.jpeg",
//   },
//   {
//     title: "Evang Ekunola Davies at Illumination 2023",
//     description: "Evang Ekunola Davies ministration at Illumination 2023",
//     imgSrc: "assets/evang-davies.jpeg",
//   },
//   {
//     title: "Evang Ajidara at Illumination 2023",
//     description: "Evang Ajidara ministration at Illumination 2023",
//     imgSrc: "assets/ajidara.jpeg",
//   },
//   {
//     title: "Relive the moments at Illumination 2023",
//     description: "Recap of Illumination 2023",
//     imgSrc: "assets/forum1.jpeg",
//   },
//   {
//     title: "Happy moments at Illumination 2023",
//     description: "Recap of Illumination 2023",
//     imgSrc: "assets/forum2.jpeg",
//   },
// ];

const Gallery = () => {
  const { loading, data, error } = useQuery(GET_GALLERY);
  const [galleries, setgalleries] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      // console.log("Gallery", data.getGallery);
      const fileteredDataz = data?.getGallery
        ? filterDataByType(data.getGallery, "gallery")
        : [];
      setgalleries(fileteredDataz);
    }
  }, [data]);

  console.log(galleries);
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/crc-media_yh9pnx.jpg`}
        header="Gallery"
        text="Explore our captivating gallery and media, where modern and traditional art converge in vivid colors and compelling narratives."
      />
      {/* change media to galleries when db is populated */}
      {galleries.length === 0 ? null : (
        <>
          <GalleryWeb />
          <GalleryMobile />
        </>
      )}
    </React.Fragment>
  );
};

export default Gallery;
