import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ScrollToTopOnPageChange = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount (page load)
  }, []);

  return null;
};

export default ScrollToTopOnPageChange;
