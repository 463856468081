import React from "react";
import Slider from "react-slick";

import { Box, CssBaseline, Grid } from "@mui/material";
import CongratCard from "./CongratCard";
import TextHeader from "../../custom/Layout/TextHeader";
import { GET_PERSONNELS } from "../../graphql/other-queries";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { monthNames } from "../../static/static";
import { GET_USERS } from "../../graphql/user-queries";
import { filterDataByCurrentMonth } from "../../hook/arrange";

const Celebrant = () => {
  const currentDate = new Date();
  const currentMonth = monthNames[currentDate.getMonth()];

  const [slidesToShow, setSlidesToShow] = React.useState(4);

  const settings = {
    centerMode: true,
    centerPadding: "22px",
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow, // Use the dynamic number of slides to show
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  // Function to set the number of slides to show based on screen size
  const updateSlidesToShow = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 600) {
      // Mobile
      setSlidesToShow(1);
    } else if (screenWidth <= 1024) {
      // Tablet
      setSlidesToShow(2);
    } else {
      // Laptop and larger screens
      setSlidesToShow(4);
    }
  };

  // Update the number of slides to show when the window size changes
  React.useEffect(() => {
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery(GET_USERS);

  // Use Apollo Client to fetch data
  const { loading, error, data } = useQuery(GET_PERSONNELS);
  const [personnels, setPersonnels] = useState([]);

  // Use useEffect to filter and set data when it's loaded
  React.useEffect(() => {
    if (data && dataUsers) {
      const filteredPersonnelData = filterDataByCurrentMonth(
        data.getPersonnels
      );
      const filteredUsersData = filterDataByCurrentMonth(dataUsers.getUsers);

      // Combine personnels and users arrays
      const combinedData = [...filteredPersonnelData, ...filteredUsersData];

      setPersonnels(combinedData);
    }
  }, [data, dataUsers]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <React.Fragment>
      <Box
        className={`${
          personnels.length === 0 ? "hidden" : "block !overflow-x-hidden"
        }`}
      >
        <CssBaseline />
        <TextHeader
          header={`${currentMonth} Celebrants`}
          text="Today, we come together with joy and gratitude to celebrate and honor you on this special day."
        />

        {/* Web */}
        <Grid
          container
          spacing={2}
          className="mt-4 md:!mx-auto place-content-center pt-12 !overflow-x-hidden"
        >
          {personnels.length === 0 ? null : personnels.length <= 3 ? (
            personnels.map((user, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className="py-10 hidden md:hidden lg:block"
                key={index}
              >
                <CongratCard user={user} />
              </Grid>
            ))
          ) : personnels.length >= 4 ? (
            <Box className="slider w-full overflow-x-hidden hidden md:hidden lg:block">
              <Slider {...settings}>
                {personnels.map((user, index) => (
                  <Grid item xs={12} sm={6} md={3} className="" key={index}>
                    <CongratCard user={user} />
                  </Grid>
                ))}
              </Slider>
            </Box>
          ) : null}
        </Grid>

        {/* Tab */}
        <Grid
          container
          spacing={2}
          className="mt-4 md:!mx-auto place-content-center pt-4 !overflow-x-hidden"
        >
          {personnels.length === 0 ? null : personnels.length <= 2 ? (
            personnels.map((user, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className="py-10 block lg:hidden"
                key={index}
              >
                <CongratCard user={user} />
              </Grid>
            ))
          ) : personnels.length >= 3 ? (
            <Box className="slider w-full overflow-x-hidden block lg:hidden">
              <Slider {...settings}>
                {personnels.map((user, index) => (
                  <Grid item xs={12} sm={6} md={3} className="" key={index}>
                    <CongratCard user={user} />
                  </Grid>
                ))}
              </Slider>
            </Box>
          ) : null}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Celebrant;
