import { Box, Grid } from "@mui/material";
import React from "react";
import FeatureCards from "../../custom/Card/FeatureCards";
import { featuresData } from "../../static/static";

const Features = () => {
  return (
    <React.Fragment>
      <Box className=" mx-auto place-content-center z-30 absolute -mt-40 md:-mt-80 featcard lg:-mt-32 px-8 md:px-20">
        <Grid container spacing={4}>
          {featuresData.map(({ color, title, icon, content }) => (
            <Grid item xs={12} md={6} lg={4} key={title}>
              <FeatureCards
                key={title}
                title={title}
                icon={React.createElement(icon, {
                  className: `!text-5xl ${color}`,
                })}
                content={content}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box className="lg:h-[200px] md:h-[600px] artEvent h-[700px]"></Box>
    </React.Fragment>
  );
};

export default Features;
