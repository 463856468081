import React, { useState } from "react";
import Slider from "react-slick";
import { carouselImg } from "../../static/static";
import { Box, Typography } from "@mui/material";

export const Carousel = () => {
  // const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    // beforeChange: (current, next) => setSlideIndex(next),
  };
  return (
    <Box className="slider relative !w-full overflow-x-hidden -mb-2">
      <Slider {...settings}>
        {carouselImg.map((caro, index) => (
          <Box key={index} className="relative">
            <img
              src={caro.image}
              alt=""
              className=" object-cover drop-shadow-md h-[80vh] md:h-screen w-full "
              // className="object-cover drop-shadow-md !w-[500px] !h-[542px] md:!w-[1000px] md:!h-[542px] lg:!w-[1440px] lg:!h-[742px] "
              //   h-[400px] w-[500px] object-cover
            />
            <Box className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
            <Box className="absolute w-full top-1/3 items-center mx-auto">
              <Box className="ml-auto mx-auto mr-auto w-full px-4 text-center lg:w-8/12">
                <Typography
                  variant="subheader"
                  color="white"
                  className="mb-6 font-black text-center mx-auto"
                  fontSize={{ xs: "48px", sm: "60px" }}
                  lineHeight={{ xs: "120%", sm: "180%" }}
                  // letterSpacing={"0.02em"}
                >
                  Celestial Reality Check
                </Typography>
              </Box>
              <Box className="md:w-[70%] lg:w-[50%] mx-auto text-center">
                <Typography
                  variant="subtitle"
                  // fontSize={{ xs: "30px", sm: "30px" }}
                  // lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  color="white"
                  className="opacity-80 text-center mx-auto"
                >
                  CRC champions transparency, accountability, and societal
                  progress within the Celestial Church of Christ, supporting
                  free expression and parishioner participation in governance.
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
