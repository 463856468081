import { useQuery } from "@apollo/client";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect, useState } from "react";
import { GET_GALLERY } from "../../graphql/other-queries";
import { filterDataByType } from "../../hook/arrange";

const ExtraView = ({ gallerytype }) => {
  const [gallery, setGallery] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryVideos, setGalleryVideos] = useState([]);

  const { data: gallerydata } = useQuery(GET_GALLERY);

  const fileteredDataz = gallerydata?.getGallery
    ? filterDataByType(gallerydata.getGallery, gallerytype)
    : [];

  // Get All Gallery
  useEffect(() => {
    if (gallerydata) {
      // console.log(gallerydata.getGallery);
      setGallery(fileteredDataz);
    }
  }, [gallerydata, fileteredDataz]);

  useEffect(() => {
    if (gallery) {
      // Filter the gallery array to include only items where all children imgSrc include the specified URL
      const filteredGalleryImg = gallery.filter((item) => {
        if (
          item.imgSrc?.includes(
            "https://crc-resources.s3.amazonaws.com/gallery"
          )
        )
          return item;
      });

      const filteredGalleryVid = gallery.filter((item) => {
        if (
          item.imgSrc?.includes(
            "https://crc-resources.s3.amazonaws.com/gallery"
          )
        ) {
          return null;
        } else {
          return item;
        }
      });

      // const imgMedia = filteredGalleryImg.length > 0;
      // console.log("filteredGalleryImg", filteredGalleryImg);
      // console.log("imgMedia", imgMedia);
      setGalleryImages(filteredGalleryImg);

      // const videoMedia = filteredGalleryVid.length > 0;
      // console.log("filteredGalleryVid", filteredGalleryVid);
      // console.log("videoMedia", videoMedia);
      setGalleryVideos(filteredGalleryVid);
    }
  }, [gallery]);

  // State variables to manage pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 4; // Change this to the number of items you want per page

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentImgPageData = galleryImages.slice(startIndex, endIndex);

  // State variables to manage pagination
  const [currentVidPage, setCurrentVidPage] = React.useState(1);
  const itemsPerPageVid = 4; // Change this to the number of items you want per page

  // Calculate the index range for the current page
  const startVidIndex = (currentVidPage - 1) * itemsPerPageVid;
  const endVidIndex = startVidIndex + itemsPerPageVid;
  const currentVidPageData = galleryVideos.slice(startVidIndex, endVidIndex);

  return (
    <React.Fragment>
      <Box className="hidden md:block">
        <Box
          className={`${
            currentImgPageData.length > 0
              ? "block text-center py-20 underline"
              : "hidden"
          }`}
        >
          <Typography
            variant="subheader"
            fontSize={{ xs: "25px", sm: "30px" }}
            lineHeight={{ xs: "120%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="mx-auto capitalize place-content-center pt-2 "
          >
            Images
          </Typography>
        </Box>
        <Box className="hidden md:block">
          <ImageList
            sx={{ width: "100%", height: "100%" }}
            className="!py-5 !overflow-y-hidden"
            cols={2}
          >
            {currentImgPageData &&
              currentImgPageData.map((item) => (
                <ImageListItem key={item.imgSrc}>
                  <img
                    src={`${item.imgSrc}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.imgSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={item.description}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${item.title}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
          </ImageList>

          {/* Image Pagination controls */}
          <Box className="text-center mt-4 mx-auto flex  place-content-center ">
            {currentPage > 1 && (
              // <CRCbutton
              //   variant="outlined"
              //   color="primary"
              //   onClick={() => setCurrentPage(currentPage - 1)}
              //   label={"Prev"}
              // />
              <Box
                color="primary"
                aria-label="Next"
                onClick={() => setCurrentPage(currentPage - 1)}
                className="ml-2 !mr-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
                Prev Image
              </Box>
            )}
            {endIndex < galleryImages.length && (
              <Box
                color="primary"
                aria-label="Next"
                onClick={() => setCurrentPage(currentPage + 1)}
                className="mr-2 !ml-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
              >
                Next Image
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </Box>
            )}
          </Box>

          {/* Image Pagination Ends */}

          <Box
            className={`${
              currentVidPageData.length > 0
                ? "block  text-center py-20 underline"
                : "hidden"
            } `}
          >
            <Typography
              variant="subheader"
              fontSize={{ xs: "25px", sm: "30px" }}
              lineHeight={{ xs: "120%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="mx-auto capitalize place-content-center pt-2 "
            >
              Videos
            </Typography>
          </Box>
          <Grid
            container
            spacing={{ xs: 0, sm: 2, md: 2 }}
            className=" px-0"
            //   display={"flex"}
            //   alignItems={"center"}
            //   justifyContent={"center"}
            //   alignContent={"center"}
          >
            {currentVidPageData &&
              currentVidPageData.map((item) => (
                <Box className=" place-content-center mx-auto py-4">
                  <Grid item xs={12} md={6} className="mx-auto ">
                    <iframe
                      width="560"
                      height="315"
                      src={item.imgSrc}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </Grid>
                </Box>
              ))}
          </Grid>

          {/* Video Pagination controls */}

          <Box className="text-center mt-4 mx-auto flex  place-content-center ">
            {currentVidPage > 1 && (
              // <CRCbutton
              //   variant="outlined"
              //   color="primary"
              //   onClick={() => setCurrentVidPage(currentVidPage - 1)}
              //   label={"Prev"}
              // />
              <Box
                color="primary"
                aria-label="Next"
                onClick={() => setCurrentVidPage(currentVidPage - 1)}
                className="ml-2 !mr-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
                Prev Video
              </Box>
            )}
            {endVidIndex < galleryVideos.length && (
              <Box
                color="primary"
                aria-label="Next"
                onClick={() => setCurrentVidPage(currentVidPage + 1)}
                className="mr-2 !ml-auto flex cursor-pointer gap-2 " // ring-2 ring-[#263238] hover:text-white hover:bg-[#263238] p-3 rounded-xl
              >
                Next Video
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </Box>
            )}
          </Box>
          {/* Video Pagination End */}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ExtraView;
