import React, { useContext, useRef, useState } from "react";
import {
  IconButton,
  CssBaseline,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { Send, CameraAlt, EmojiEmotions } from "@mui/icons-material";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import CRCTextFieldOutline from "../../../custom/Forms/CRCTextFieldOutline";
import { useAuth } from "../../../context/app.context";
import ForumComments from "./ForumComments";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_COMMENT, GET_POST } from "../../../graphql/other-queries";
import { useParams } from "react-router-dom";
import { formatDate, formatDay } from "../../../hook/date-format";
import { LoaderContext } from "../../../context/loader.context";

const ChatUI = () => {
  const { isAuthenticated, userData } = useAuth();
  const params = useParams();

  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const imageInputRef = useRef(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [contentimage, setcontentimage] = useState("");

  const [formData, setFormData] = useState({
    content: "",
    contentpic: "",
    userId: userData.id,
    firstname: userData.firstname,
    lastname: userData.lastname,
    postId: params.id,
    avatar: userData.avatar,
  });

  const handleCameraClick = () => {
    imageInputRef.current.click();
  };

  const handleImageInputChange = (event) => {
    const selectedImage = event.target.files[0];
    // setcontentimage(selectedImage);
    if (selectedImage) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        contentpic: selectedImage,
      }));
      setcontentimage(selectedImage.name);
    }
  };

  const handleEmojiClick = (emoji) => {
    const emojiNative = emoji.native || "";
    setFormData((prevFormData) => ({
      ...prevFormData,
      content: prevFormData.content + emojiNative,
    }));
  };

  React.useEffect(() => {
    if (userData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: userData.id,
        postId: params.id,
        avatar: userData.avatar,
        firstname: userData.firstname,
        lastname: userData.lastname,
      }));
    }
  }, [userData, params.id]);

  const { data: postData } = useQuery(GET_POST, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  const messageAreaRef = useRef();

  React.useEffect(() => {
    // Scroll to the bottom when a new comment is added
    messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
  }, [postData]);

  // console.log("PostData", postData.getPostById);
  const posts = postData.getPostById;

  function scrollToBottom() {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }

  // Add a comment on Post
  const [addComment] = useMutation(ADD_COMMENT);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if any of the form fields are empty
    // const emptyFields = Object.keys(formData).filter(
    //   (key) => formData[key] === ""
    // );

    // if (emptyFields.length < 0) {
    //   // setError("Please fill out all fields.");
    //   return;
    // }

    try {
      openLoader();
      console.log(formData);
      const { data, error } = await addComment({
        variables: formData,
      });
      // refetch();

      if (data.addComment) {
        // Handle successful form submission (if needed)
        closeLoader();
        setFormData({
          ...formData,
          content: "",
          contentpic: "",
        });
        setcontentimage("");
        console.log("Commenting success:", data);
      } else {
        console.error("Article upload failed", error);
      }
    } catch (error) {
      // Display error alert for server error
      console.error("Commenting error:", error);
    }
  };

  // Initialize a variable to keep track of the last displayed date
  let lastDisplayedDate = null;

  // Get the current date as a string in the format "YYYY-MM-DD"
  const currentDateStr = new Date().toLocaleDateString();

  // Get the date for yesterday as a string in the same format
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayDateStr = yesterday.toLocaleDateString();

  return (
    <React.Fragment>
      <CssBaseline />
      <Box elevation={0} className="h-auto p-2 ">
        {/* Chat messages */}
        {/* You can map through an array of messages and display them here */}
        <Box
          className="md:p-4 h-[565px] md:h-[720px] overflow-y-auto"
          style={{ scrollBehavior: "smooth" }}
          ref={messageAreaRef}
          onLoad={() =>
            setTimeout(function () {
              scrollToBottom();
            }, 100)
          }
        >
          {posts.comments &&
            posts.comments.map((comment, index) => {
              // Extract the date (date portion only) from the comment
              const commentDate = comment.createdAt.split("T")[0];

              // Check if this is the first comment or a new day's comment
              const isNewDayComment = commentDate !== lastDisplayedDate;

              // Update the last displayed date
              lastDisplayedDate = commentDate;

              return (
                <React.Fragment key={index}>
                  {/* Render the date as a divider if it's a new day */}
                  {isNewDayComment && (
                    <Divider className="px-20">
                      <Typography
                        variant="subtitle"
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        fontSize={{ xs: "13px", sm: "13px" }}
                        letterSpacing={"0.02em"}
                        className="pl-6"
                      >
                        {formatDate(commentDate) === currentDateStr
                          ? "Today"
                          : formatDate(commentDate) === yesterdayDateStr
                          ? "Yesterday"
                          : formatDay(commentDate)}
                      </Typography>
                    </Divider>
                  )}

                  {/* Render the comment */}
                  <ForumComments message={[comment]} />
                </React.Fragment>
              );
            })}
        </Box>

        <form onSubmit={handleSubmit}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
            className="w-[100%] pt-2 pb-0 place-content-between"
          >
            <Box
              className={`relative ${
                formData.content === ""
                  ? "w-[100%] ease-in duration-300"
                  : "w-[83%] md:w-[94%]"
              }`}
            >
              {/* Adjust the column layout as needed */}
              <CRCTextFieldOutline
                label={`${formData.content === "" ? "" : ""}`}
                // sx={{ width: "10%" }}
                name="content"
                type="text"
                value={formData.content}
                onChange={(e) =>
                  setFormData({ ...formData, content: e.target.value })
                }
                endAdornment={
                  <>
                    <IconButton color="primary" onClick={handleCameraClick}>
                      <CameraAlt className="!text-gray-400 !text-2xl" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}
                    >
                      <EmojiEmotions className="!text-gray-400 !text-2xl" />
                    </IconButton>
                  </>
                }
              />
              {contentimage && (
                <Typography
                  variant="subtitle"
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  fontSize={{ xs: "13px", sm: "13px" }}
                  letterSpacing={"0.02em"}
                  color="error"
                  className="pl-6 absolute top-0"
                >
                  {contentimage}
                </Typography>
              )}
            </Box>
            <Box className="!pt-0 ">
              {formData.content === "" && formData.contentpic === "" ? null : (
                <IconButton
                  type="submit"
                  color="primary"
                  // className="!p-3 !bg-blue-500 drop-shadow-2xl shadow-2xl"
                >
                  <Send className="!text-gray-400 !text-3xl" />
                </IconButton>
              )}
            </Box>
          </Box>
          <input
            ref={imageInputRef}
            type="file"
            id="imageInput"
            className="hidden"
            accept="image/*"
            onChange={handleImageInputChange}
          />
        </form>
      </Box>
      {isEmojiPickerOpen && (
        <Picker
          data={data}
          onEmojiSelect={handleEmojiClick}
          style={{
            position: "fixed", // Change this to "fixed"
            marginTop: "-280px", // Adjust the positioning as needed
            right: "12px", // Position it next to the input
            maxWidth: "320px",
            borderRadius: "20px",
            zIndex: 1, // Ensure it's above other elements
          }}
          theme="auto" // auto or dark or light
        />
      )}
    </React.Fragment>
  );
};

export default ChatUI;
