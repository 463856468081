// Function to format a date to "Day, DayOfMonth Month" format e.g 12/04/2023
export const formatDate = (dateStr) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return new Date(dateStr).toLocaleDateString(undefined, options);
};

// Function to format a date to "Date & Month" format e.g 18, Sept
export const formatDayMonth = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const dayStr =
    day +
    (day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th");
  return `${dayStr} ${month}`;
};

// Function to format a date to "Day, DayOfMonth Month" format e.g 18, Sept 2023
export const formatDay = (dateStr) => {
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return new Date(dateStr).toLocaleDateString(undefined, options);
};

// Function to format a date to 1993-08-16 01:00:00+01
export function formatDBdate(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
  const timeZoneOffset = date.getTimezoneOffset();
  const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60))
    .toString()
    .padStart(2, "0");
  const timeZoneOffsetMinutes = (Math.abs(timeZoneOffset) % 60)
    .toString()
    .padStart(2, "0");
  const timeZoneSign = timeZoneOffset < 0 ? "+" : "-";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${timeZoneSign}${timeZoneOffsetHours}${timeZoneOffsetMinutes}`;
}
