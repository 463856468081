import { Avatar, Box, Container, CssBaseline, IconButton } from "@mui/material";
import React from "react";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ContactForm from "./ContactForm";
import TextHeader from "../../custom/Layout/TextHeader";

const ContactCard = () => {
  return (
    <React.Fragment>
      <section id="contactUs">
        <Box className="bg-white">
          <Container component="main" maxWidth="3xl">
            <CssBaseline />
            <Box className=" md:w-[70%] lg:w-[50%] mx-auto py-10">
              <Avatar
                sx={{ bgcolor: "secondary.light", p: 3 }}
                className=" flex place-content-center mx-auto"
              >
                <IconButton
                  variant="gradient"
                  size="lg"
                  className="pointer-events-none mb-6 rounded-full "
                >
                  <CallOutlinedIcon className="text-[#fff]" />
                </IconButton>
              </Avatar>

              <TextHeader
                header="We can help"
                text="How may we assist you? Please share your inquiries."
              />
              <ContactForm />
            </Box>
          </Container>
        </Box>
      </section>
    </React.Fragment>
  );
};

export default ContactCard;
