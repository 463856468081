import { Avatar, Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import ProfileInteraction from "./ProfileInteraction";
import { useQuery } from "@apollo/client";
import { GET_USER_ARTICLES } from "../../../graphql/other-queries";
import { useAuth } from "../../../context/app.context";

const ProfileHeader = ({ children, imgSrc }) => {
  let image = imgSrc;

  // Inside your component
  const isMobileOrTablet = useMediaQuery("(max-width: 600px)"); // Adjust the max-width as needed
  const avatarSize = isMobileOrTablet ? 100 : 200;

  // Getting login state
  const { isAuthenticated, logoutUser, userData } = useAuth();
  const loginState = isAuthenticated;

  const [userArticles, setUserArticles] = React.useState("");
  const [verifiedArticles, setVerifiedArticles] = React.useState("");
  const [pendingArticles, setPendingArticles] = React.useState("");

  const { data: articleData } = useQuery(GET_USER_ARTICLES, {
    variables: { email: userData.email },
    pollInterval: 500,
  });

  React.useEffect(() => {
    if (articleData) {
      // console.log("USER ARTICLES", articleData.getUserArticles);
      //   Filter Articles by like
      const totalArticle = articleData.getUserArticles;
      setUserArticles(totalArticle);
    }
  }, [articleData]);

  React.useEffect(() => {
    if (articleData) {
      //   Filter the data based on the keyword
      const verifiedArticle = articleData.getUserArticles.filter((item) =>
        item.status.toLowerCase().includes("verified".toLowerCase())
      );
      setVerifiedArticles(verifiedArticle);
      //   Filter the data based on the keyword
      const pendingArticle = articleData.getUserArticles.filter((item) =>
        item.status.toLowerCase().includes("pending".toLowerCase())
      );
      setPendingArticles(pendingArticle);
    }
  }, [articleData]);

  return (
    <>
      <Box className="relative flex h-screen content-center -z-50 items-center justify-center">
        <Box
          className={`absolute top-0 h-[50%] w-full `}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box className="absolute top-0 h-[50%] w-full bg-black/75 bg-cover bg-center" />
      </Box>
      <Box className=" mx-auto place-content-center -mt-[700px] profilecard md:-mt-[900px] lg:-mt-[600px]   !h-full w-[90%]">
        <Box className="bg-white relative h-full rounded-2xl p-6 md:p-12">
          <Box className="">
            <Box className="absolute top-3 left-0 md:left-2 lg:left-24 transform grid grid-cols-3 md:flex">
              <ProfileInteraction
                count={pendingArticles.length}
                text={"Pending Aricles"}
              />

              <ProfileInteraction
                count={verifiedArticles.length}
                text={"Verified Articles"}
              />
            </Box>

            <Box className="absolute top-3 right-0 md:right-2 lg:right-24 transform flex">
              <ProfileInteraction
                count={userData.totalInteractions}
                text={"Total Interactions"}
              />
            </Box>
          </Box>
          <Box className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Avatar
              alt={userData.firstname}
              src={userData.avatar}
              sx={{ width: avatarSize, height: avatarSize }}
            />
          </Box>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default ProfileHeader;
