import React, { useContext, useEffect, useState } from "react";
import PlainUserHeader from "./PlainUserHeader";
import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../context/app.context";
import { LoaderContext } from "../../../context/loader.context";
import CRCTextField from "../../../custom/Forms/CRCTextField";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCradio from "../../../custom/Forms/CRCradio";
import { formatDBdate, formatDay } from "../../../hook/date-format";
import CRCSelect from "../../../custom/Forms/CRCSelect";
import { countries } from "../../../static/constant";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import CRCOrdinarySelect from "../../../custom/Forms/CRCOrdinarySelect";
import { UPDATE_USER_DETAILS } from "../../../graphql/user-queries";

const FormSettings = () => {
  const { isAuthenticated, userData, loginUser, logoutUser } = useAuth();
  const loaderContext = useContext(LoaderContext);
  let navigate = useNavigate();
  const [msg, setMsg] = useState("");

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [gender, setgender] = useState("");
  const [social, setsocial] = useState("");
  const [country, setcountry] = useState("");
  const [dob, setdob] = useState("");

  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    gender: "",
    social: "",
    country: "",
    email: "",
    dob: "",
  });

  React.useEffect(() => {
    setFormData({
      ...formData,
      firstname: firstname || userData.firstname,
      lastname: lastname || userData.lastname,
      email: userData.email,
      gender: gender || userData.gender,
      social: social || userData.social,
      country: country || userData.country,
      dob: dob || userData.dob,
    });

    console.log("formData: ", formData);
  }, [firstname, lastname, gender, social, country, dob, userData]);

  const [updateUserDetails, { loading, error, data }] =
    useMutation(UPDATE_USER_DETAILS);

  const handleCountryChange = (selectedCountry) => {
    // setFormData({ ...formData, country: selectedCountry });
    setcountry(selectedCountry);
    setMsg("");
  };

  const handleGenderChange = (selectedGender) => {
    // setFormData({ ...formData, gender: selectedGender });
    setgender(selectedGender);
    setMsg("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      return;
    }

    // console.log("FormData: ", formData);

    try {
      const { data } = await updateUserDetails({
        variables: formData,
      });

      openLoader();
      // Handle successful form submission (if needed)
      // console.log("Signup success:", data);

      if (data.updateUserDetails.email === null) {
        closeLoader();
        setMsg("Email does not exists");
      } else {
        closeLoader();
        setMsg("Profile updated successfully");
        setTimeout(() => {
          navigate(`/signin`);
        }, 3000);
      }
    } catch (error) {
      // Display error alert for server error
      console.error("Signup error:", error.message);
    }
  };

  return (
    <React.Fragment>
      <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto ">
        <form onSubmit={handleSubmit}>
          <FormGroup>
            {msg === "Profile updated successfully" ? (
              <Alert variant="filled" severity="success">
                {msg}
              </Alert>
            ) : null}
            <br />
            {/* Display error alert */}
            <Grid
              container
              spacing={{ xs: 0, sm: 2, md: 3 }}
              className="md:flex items-center"
            >
              <Grid item xs={12} md={6} className="py-2">
                <TextField
                  label=""
                  fullWidth
                  variant="standard"
                  name="fname"
                  type="text"
                  placeholder={userData.firstname}
                  value={firstname || formData.firstname}
                  onChange={(e) => {
                    // setFormData({ ...formData, firstname: e.target.value });
                    setfirstname(e.target.value);
                    setMsg("");
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} className="py-2">
                <TextField
                  label=""
                  fullWidth
                  variant="standard"
                  placeholder={userData.lastname}
                  value={lastname || userData.lastname}
                  name="lname"
                  type="name"
                  onChange={(e) => {
                    // setFormData({ ...formData, lastname: e.target.value });
                    setlastname(e.target.value);
                    setMsg("");
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={{ xs: 0, sm: 2, md: 3 }}
              className="md:flex !items-center !justify-center py-2 w-full"
            >
              <Grid item xs={12} md={6}>
                <CRCOrdinarySelect
                  label={formData.country || userData.country}
                  values={countries} // Assuming country is an array of countries
                  placeholder="Select a country"
                  onChange={handleCountryChange} // Pass the handler to update the selected country
                />
              </Grid>

              <Grid item xs={12} md={6} className="md:!pt-2 lg:!pt-10">
                <TextField
                  label=""
                  fullWidth
                  variant="standard"
                  placeholder={userData.social}
                  value={formData.social}
                  name="social"
                  type="name"
                  onChange={(e) => {
                    // setFormData({ ...formData, social: e.target.value });
                    setsocial(e.target.value);
                    setMsg("");
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{ xs: 0, sm: 2, md: 3 }}
              className="py-2 md:flex items-center"
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  value={formData.dob}
                  placeholder={userData.dob}
                  label={formData.dob === "" ? "" : formData.dob}
                  name="dob"
                  type="date"
                  onChange={(e) => {
                    let date = formatDBdate(e.target.value);
                    // setFormData({ ...formData, dob: date });
                    setdob(e.target.value);
                    console.log("DOB", date);
                    setMsg("");
                  }}
                />

                {formatDay(formData.dob || userData.dob)}
              </Grid>
              <Grid item xs={12} md={6}>
                <CRCradio
                  value={`Gender: ${formData.gender || userData.gender}`}
                  option1={"male"}
                  option2={"female"}
                  onChange={handleGenderChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="mx-auto mt-8">
                <CRCbutton label={"Submit"} type={"submit"} />
              </Box>
            </Grid>
          </FormGroup>
        </form>
      </div>
    </React.Fragment>
  );
};

export default FormSettings;
