import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import ContactCard from "../components/Contact/ContactCard";

const Contact = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/contact_kxupri.jpg`}
        header="Contact Us"
        text="Ready to make a change? Contact us now and let's make it happen together. "
      />

      <ContactCard />
    </React.Fragment>
  );
};

export default Contact;
