import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { RulesTenetsCard } from "../components/About/RulesTenetsCard";

const RulesTenets = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={
          "https://alchetron.com/cdn/celestial-city-imeko-12c5442c-2818-4a2a-9f2e-222eadbcf4e-resize-750.jpeg"
        }
        header="Rules & Tenets"
        text="The rules and tenets of the Celestial Church of Christ are firmly grounded in its doctrines, emphasizing adherence to its spiritual teachings, worship practices, and moral principles."
      />

      <RulesTenetsCard />
    </React.Fragment>
  );
};

export default RulesTenets;
