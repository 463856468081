import { gql } from "@apollo/client";

// Get all POSTS
export const GET_POSTS = gql`
  query Post {
    getPosts {
      id
      title
      content
      contentpic
      author
      category
      slug
      createdAt
      comments {
        id
        userId
        postId
        firstname
        lastname
        content
        contentpic
        avatar
        createdAt
        reactions {
          category
          categoryId
          type
          createdAt
        }
      }
      reactions {
        category
        categoryId
        type
        createdAt
      }
    }
  }
`;

// Get a single POST
export const GET_POST = gql`
  query Post($id: ID!) {
    getPostById(id: $id) {
      id
      title
      content
      contentpic
      author
      category
      slug
      createdAt
      comments {
        id
        userId
        postId
        firstname
        lastname
        content
        contentpic
        avatar
        createdAt
        reactions {
          category
          categoryId
          type
          createdAt
        }
      }
      reactions {
        category
        categoryId
        type
        createdAt
      }
    }
  }
`;

// Get all ARTICLES
export const GET_ARTICLES = gql`
  query Articles {
    getArticles {
      id
      title
      content
      contentpic
      author
      category
      slug
      social
      status
      createdAt
      artcomments {
        id
        name
        content
        articleId
        createdAt
      }
    }
  }
`;

// Get A SINGLE ARTICLE
export const GET_ARTICLE = gql`
  query Articles($id: ID!) {
    getArticlesById(id: $id) {
      id
      title
      content
      contentpic
      author
      category
      slug
      social
      status
      createdAt
      artcomments {
        id
        name
        content
        articleId
        createdAt
      }
    }
  }
`;

export const GET_ALL_BIBLE_BOOKS = gql`
  query GetAllBibleBooks {
    getBibleBooks {
      book_name
      book
    }
  }
`;
export const GET_ALL_BIBLE_BOOKS_CHAPTERS = gql`
  query GetAllBibleBooks {
    getBibleBookChapters {
      book_name
      book
      chapters
    }
  }
`;

export const GET_VERSE = gql`
  query GetVerse($book_name: String!, $chapter: Int!, $verse: Int!) {
    getBibleVerse(book_name: $book_name, chapter: $chapter, verse: $verse) {
      book_name
      chapter
      verse
      text
    }
  }
`;
export const GET_VERSES = gql`
  query GetVerses($book_name: String!, $chapter: Int!, $verse: String!) {
    getBibleVerses(book_name: $book_name, chapter: $chapter, verse: $verse) {
      book_name
      chapter
      verse
      text
    }
  }
`;

// View POST_REACTIONS by id
export const GET_POST_REACTIONS = gql`
  query Reaction($categoryId: ID!) {
    getPostReaction(categoryId: $categoryId) {
      category
      categoryId
      type
      userId
      createdAt
    }
  }
`;

// View COMMENT_REACTIONS by id
export const GET_COMMENT_REACTIONS = gql`
  query Reaction($categoryId: ID!) {
    getCommentReaction(categoryId: $categoryId) {
      category
      categoryId
      type
      userId
      createdAt
    }
  }
`;

// View COMMENT_REACTIONS by id
export const GET_USER_REACTIONS = gql`
  query Reaction($userId: ID!) {
    getUserReactions(userId: $userId) {
      category
      categoryId
      type
      userId
      createdAt
    }
  }
`;

// View  COMMENT_REACTIONS by id
export const GET_USER_ARTICLES = gql`
  query Article($email: String!) {
    getUserArticles(email: $email) {
      id
      email
      title
      author
      category
      social
      status
      slug
      content
      contentpic
      createdAt
    }
  }
`;

// View  COMMENT_REACTIONS by id
export const GET_USER_COMMENTS = gql`
  query Comment($userId: ID!) {
    getUserComments(userId: $userId) {
      userId
      postId
      firstname
      lastname
      content
      contentpic
      avatar
      createdAt
    }
  }
`;

// View  Personnels
export const GET_PERSONNELS = gql`
  query Personnel {
    getPersonnels {
      id
      firstname
      lastname
      avatar
      dob
      social
      category
      bio
      createdAt
    }
  }
`;

// View  GET_GALLERY
export const GET_GALLERY = gql`
  query Gallery {
    getGallery {
      id
      title
      imgSrc
      description
      type
      createdAt
    }
  }
`;

// View  GET_GALLERY
export const GET_VISITORS = gql`
  query Gallery {
    getGallery {
      id
      ipaddress
      createdAt
    }
  }
`;
// View  Events
export const GET_EVENTS = gql`
  query Event {
    getEvents {
      id
      title
      date
      content
      imgSrc
      venue
      slug
    }
  }
`;

// Login User by email and password
export const CONTACT_US = gql`
  query User(
    $email: String!
    $fname: String!
    $subject: String!
    $message: String!
  ) {
    contactUs(
      email: $email
      fname: $fname
      subject: $subject
      message: $message
    ) {
      token
    }
  }
`;

// MUTATIONS

// Add Article
export const ADD_ARTICLE = gql`
  mutation AddArticleMutation(
    $title: String!
    $category: String!
    $author: String!
    $content: String!
    $contentpic: Upload!
    $email: String!
    $social: String
    $status: String!
  ) {
    addArticle(
      content: $content
      contentpic: $contentpic
      title: $title
      category: $category
      author: $author
      email: $email
      social: $social
      status: $status
    ) {
      title
      author
      email
      category
      content
      contentpic
      social
      status
      createdAt
    }
  }
`;

// Add Post Reactions
export const ADD_ARTICLE_COMMENTS = gql`
  mutation AddArticleCommentMutation(
    $articleId: ID!
    $name: String!
    $content: String!
  ) {
    addArtComment(name: $name, articleId: $articleId, content: $content) {
      articleId
      name
      content
      createdAt
    }
  }
`;

// Add Comment to Post Comments
export const ADD_COMMENT = gql`
  mutation AddCommentMutation(
    $firstname: String!
    $lastname: String!
    $avatar: String!
    $content: String!
    $contentpic: Upload
    $userId: ID!
    $postId: ID!
  ) {
    addComment(
      content: $content
      contentpic: $contentpic
      firstname: $firstname
      lastname: $lastname
      avatar: $avatar
      userId: $userId
      postId: $postId
    ) {
      userId
      postId
      firstname
      lastname
      content
      contentpic
      avatar
      createdAt
    }
  }
`;

// Add Post Reactions
export const ADD_POST_REACTION = gql`
  mutation AddPostReactionMutation(
    $type: String!
    $userId: ID!
    $categoryId: ID!
  ) {
    addPostReaction(type: $type, userId: $userId, categoryId: $categoryId) {
      userId
      categoryId
      type
      createdAt
    }
  }
`;

// Add Comment Reactions
export const ADD_COMMENT_REACTION = gql`
  mutation AddCommentReactionMutation(
    $type: String!
    $userId: ID!
    $categoryId: ID!
  ) {
    addCommentReaction(type: $type, userId: $userId, categoryId: $categoryId) {
      userId
      categoryId
      type
      createdAt
    }
  }
`;

// Add Gallery
export const ADD_GALLERY = gql`
  mutation AddGalleryMutation(
    $title: String!
    $imgSrc: Upload!
    $description: String!
  ) {
    addGallery(title: $title, imgSrc: $imgSrc, description: $description) {
      id
      title
      imgSrc
      description
      createdAt
    }
  }
`;

// Add Page Visitors IP Address
export const ADD_VISITOR = gql`
  mutation AddVisitorMutation($ipaddress: String!) {
    addVisitor(ipaddress: $ipaddress) {
      id
      ipaddress
      createdAt
    }
  }
`;

// Add ADD_SUBSCRIBER
export const ADD_SUBSCRIBER = gql`
  mutation AddSubscriberMutation($email: String!) {
    addSubscriber(email: $email) {
      email
      createdAt
    }
  }
`;
