import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import EventsCard from "./EventsCard";
import ArticleCard from "./ArticleCard";
import SearchBibleVerse from "../../global/SearchBibleVerse";
import { GET_VERSE } from "../../graphql/other-queries.js";
import TextHeader from "../../custom/Layout/TextHeader";

const ArtEvents = () => {
  const handleVerseSelect = (data) => {
    if (data && data.getBibleVerse) {
      // Handle the search results here
      console.log(data.getBibleVerse);
    }
  };
  return (
    <React.Fragment>
      <Box
        className=" bg-white px-4 pb-20 md:pb-20 lg:py-20 py-6 md:py-12 h-auto"
        display={"flex"}

        // bg-[#f6f5f5]
      >
        <Grid
          container
          spacing={{ xs: 0, md: 4 }}
          className=" place-content-center mx-auto"
        >
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className=" !mx-auto place-content-center grid"
          >
            <Typography
              variant="subheader"
              fontSize={{ xs: "18px", sm: "26px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="pl-4 capitalize text-center"
            >
              Bible Search
            </Typography>
            <SearchBibleVerse />
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            display={"flex"}
            alignItems={"center"}
          >
            <ArticleCard />
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={4}
            display={"flex"}
            alignItems={"center"}
          >
            <EventsCard />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ArtEvents;
