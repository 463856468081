import React from "react";
import PagesHeader from "../../custom/Layout/PagesHeader";
import NewPasswordCard from "../../components/Auth/NewPasswordCard";

const NewPassword = () => {
  return (
    <React.Fragment>
      <PagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/support_u4yjtj.png`}
      >
        <NewPasswordCard />
        <br />
        <br />
        <br />
      </PagesHeader>
    </React.Fragment>
  );
};

export default NewPassword;
