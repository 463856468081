import React, { useEffect, useState } from "react";
import EventCard from "./EventCard";
import { Box, Fab, Grid, Typography } from "@mui/material";

import { useQuery } from "@apollo/client";
import { GET_EVENTS } from "../../graphql/other-queries";
import { convertUTCToDate, sortByMutableAscending } from "../../hook/arrange";
import { monthNames } from "../../static/static";
import CRCSelect from "../../custom/Forms/CRCSelect";
import { formatDBdate } from "../../hook/date-format";

const OtherEvents = () => {
  // Query to fetch getEventsByID
  const { loading, error, data } = useQuery(GET_EVENTS, {
    pollInterval: 500,
  });

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      // Ensure data.getEvents exists before using it
      const events = data.getEvents || [];

      // Filter events for the selected month
      const filteredEvents = events.filter((evento) => {
        const eventDate = new Date(evento.date);
        const currentDate = new Date();
        // Compare the year, month, day, hours, minutes, and seconds
        return (
          eventDate.getMonth() === selectedMonth && eventDate >= currentDate
        );
      });

      // Sort the filtered events by date
      const sortedEvents = sortByMutableAscending(filteredEvents, "date");

      setFilteredEvents(sortedEvents);
    }
  }, [data, loading, selectedMonth]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  const handleMonthChange = (monthName) => {
    const index = monthNames.indexOf(monthName);
    setSelectedMonth(index);
  };

  return (
    <React.Fragment>
      {/* Web */}

      <Box className="mx-auto place-content-center hidden gap-4 mb-6 lg:flex">
        {monthNames.map((month, index) => (
          <Fab
            key={index}
            variant="extended"
            size="small"
            sx={{
              bgcolor: index === selectedMonth ? "white" : "secondary.light",
              color: index === selectedMonth ? "secondary.light" : "white",
            }}
            className="hover:text-[#ab47bc]"
            onClick={() => setSelectedMonth(index)}
          >
            {month}
          </Fab>
        ))}
      </Box>
      {/* Tab and Mobile */}
      <Box className="ml-auto mr-2 md:mr-6 w-32 block mb-6 lg:hidden overflow-x-hidden">
        <CRCSelect
          label="Sort by month"
          values={monthNames} // Assuming monthNames is an array of months
          placeholder="Select a month"
          value={monthNames[selectedMonth]} // Pass the selectedMonth as the value
          onChange={handleMonthChange} // Pass the handler to update the selected month
        />
      </Box>

      <Grid
        container
        spacing={{ sm: 1, xs: 1, md: 1 }}
        className="md:!mx-auto place-content-center"
      >
        {filteredEvents.length === 0 ? (
          <Grid item xs={12} className="mx-auto place-content-center flex">
            <Typography variant="subheader" align="center">
              No events for this month
            </Typography>
          </Grid>
        ) : (
          filteredEvents.map((evento, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <EventCard
                church={evento.title}
                content={evento.content}
                image={evento.imgSrc}
                date={evento.date}
                venue={evento.venue}
              />
            </Grid>
          ))
        )}
      </Grid>
    </React.Fragment>
  );
};

export default OtherEvents;
