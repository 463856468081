import React, { useEffect, useState } from "react";
import TextHeader from "../../custom/Layout/TextHeader";
import { Box, Fab } from "@mui/material";
import { Report } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_GALLERY } from "../../graphql/other-queries";
import { filterDataByType } from "../../hook/arrange";

const Reprimanded = () => {
  let navigate = useNavigate();
  const [reprimand, setReprimand] = useState([]);

  const { data: reprimandeddata } = useQuery(GET_GALLERY, {
    pollInterval: 500,
  });

  const fileteredDataz = reprimandeddata?.getGallery
    ? filterDataByType(reprimandeddata.getGallery, "reprimanded")
    : [];

  // Get All Gallery
  useEffect(() => {
    if (reprimandeddata) {
      //   console.log(reprimandeddata.getGallery);
      const reprimax = fileteredDataz[0];
      setReprimand(reprimax);
    }
  }, [reprimandeddata, fileteredDataz]);

  console.log(fileteredDataz, "fileteredDataz");
  console.log(reprimand, "Reprimand1");

  return (
    <React.Fragment>
      {reprimand && (
        <>
          <TextHeader
            header="Revisit Reprimanded Content"
            text="Delving into previously criticized content for valuable insights and growth."
          />

          <Box
            className=" place-content-center mx-auto py-4 w-[90%]"
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <iframe
              width="100%"
              className=" h-64 md:h-[500px] lg:h-[800px] "
              src={reprimand && reprimand.imgSrc}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Box>

          <Box
            // className={`pt-5 md:w-[95%] lg:w-[93%] justify-center md:justify-end`}
            // display="flex"
            className="flex justify-center py-5"
          >
            <Fab
              variant="extended"
              // size="small"
              sx={{ bgcolor: "secondary.light" }}
              className={` !text-white hover:!text-black`}
              onClick={() => navigate("/reprimanded")}
            >
              <Report sx={{ mr: 1 }} />
              View more
            </Fab>
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default Reprimanded;
