import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import SortArticle from "../components/Article/SortArticle";
import { Box } from "@mui/material";
import ArticleCard from "../components/Article/ArticleCard";

const Article = () => {
  const [sortCriteria, setSortCriteria] = React.useState(""); // State to hold the selected sorting criteria

  const handleSortChange = (newSortCriteria) => {
    setSortCriteria(newSortCriteria);
  };
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/desks_eriscm.jpg`}
        header="Blog"
        text=" Stay updated with the latest happenings, thought-provoking articles, and trending discussions across a wide spectrum of subjects and interests."
      />
      <SortArticle
        onSortChange={handleSortChange}
        activeCategory={sortCriteria}
      />
      <Box className="lg:px-32 md:px-6 px-6 bg-white">
        <ArticleCard sortCriteria={sortCriteria} />
      </Box>
    </React.Fragment>
  );
};

export default Article;
