import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import React, { Fragment } from "react";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";

import ReadMore from "../Article/ReadMore";
import { Link } from "react-router-dom";
import { PersonOutline } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GET_POSTS } from "../../graphql/other-queries";

export const HotGist = () => {
  //   Query to fetch getArticlesByID
  const { loading, error, data } = useQuery(GET_POSTS, {
    pollInterval: 500,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // Ensure data.getArticles exists before using it
  const forumPost = data?.getPosts || [];

  // Find the last item where category is "gist"
  const lastForumGist = forumPost[0]; // Get the last matching item

  // console.log("Last Gist Article", lastForumGist);
  return (
    <Fragment>
      {lastForumGist && (
        <Box className=" hidden md:hidden lg:block ">
          <Grid item={true} container>
            <Grid
              item={true}
              xs={12}
              sm={12}
              md={6}
              className=" h-[600px] !w-full"
            >
              <img
                src={lastForumGist.contentpic}
                // src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664663476/salvation/bible_vunkuj.jpg"
                alt={lastForumGist.title}
                className="h-full w-full bg-cover !object-cover"
              />
            </Grid>
            <Grid
              item={true}
              xs={12}
              sm={12}
              md={6}
              display={"flex"}
              alignItems={"center"}
              className="pb-10 bg-white"
            >
              <Box className="grid w-5/6 mx-auto">
                <Box className="" display={"flex"} alignItems={"center"}>
                  <Avatar
                    sx={{ bgcolor: "secondary.light", p: 3 }}
                    className=" flex  my-2 shadow-md drop-shadow-md"
                  >
                    <IconButton
                      variant="gradient"
                      size="lg"
                      color=""
                      className="pointer-events-none mb-6 rounded-full "
                    >
                      <WhatshotOutlinedIcon className=" !text-white" />
                    </IconButton>
                  </Avatar>

                  <Typography
                    variant="subheader"
                    fontSize={{ xs: "18px", sm: "26px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className="pl-4 capitalize"
                  >
                    Trending Gist in our community
                  </Typography>
                </Box>

                <Link
                  className=" !no-underline"
                  to={`/community/${lastForumGist.slug}/${lastForumGist.id}`}
                >
                  <Typography
                    variant="subheader"
                    fontSize={{ xs: "18px", sm: "24px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" line-clamp-2 capitalize hover:text-purple-400"
                  >
                    {lastForumGist.title}
                  </Typography>
                </Link>
                <Typography
                  variant="body"
                  fontSize={{ xs: "13px", sm: "15px", md: "15px" }}
                  // lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-70 line-clamp-5"
                  title="Click to view more"
                >
                  {lastForumGist.content}
                </Typography>

                <ReadMore
                  to={`/community/${lastForumGist.slug}/${lastForumGist.id}`}
                  className="cursor-pointer sidebar-glide w-[16%]"
                  label={"Read More"}
                />
                <Box>
                  <PersonOutline className="!text-xl" />

                  <Typography
                    variant="caption"
                    fontSize={{ xs: "13px", sm: "13px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className="capitalize pl-2"
                  >
                    Administrator
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Mobile and Tab */}
      {lastForumGist && (
        <Box className=" block md:block lg:hidden relative">
          <Box className=" h-full !w-full">
            <img
              src={lastForumGist.contentpic}
              // src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664663476/salvation/bible_vunkuj.jpg"
              alt={lastForumGist.title}
              className="h-[400px] md:h-[600px] !w-full bg-cover object-cover"
            />
          </Box>
          <Box className="grid w-[90%] md:w-5/6 rounded-xl shadow-md drop-shadow-md p-4 md:p-6 bg-white opacity-90 absolute right-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Box className="" display={"flex"} alignItems={"center"}>
              <Avatar
                sx={{ bgcolor: "secondary.light", p: 2 }}
                className=" flex  my-2 shadow-md drop-shadow-md"
              >
                <IconButton
                  variant="gradient"
                  size="lg"
                  color=""
                  className="pointer-events-none mb-6 rounded-full "
                >
                  <WhatshotOutlinedIcon className=" !text-white" />
                </IconButton>
              </Avatar>

              <Typography
                variant="subheader"
                fontSize={{ xs: "15px", sm: "26px" }}
                lineHeight={{ xs: "120%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="pl-4 capitalize"
              >
                Trending Gist in our community
              </Typography>
            </Box>
            <Link
              to={`/community/${lastForumGist.slug}/${lastForumGist.id}`}
              className=" !no-underline"
            >
              <Typography
                variant="header"
                fontSize={{ xs: "18px", sm: "20px", md: "30px" }}
                // lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="line-clamp-2"
                title="Click to view more"
              >
                {lastForumGist.title}
              </Typography>
            </Link>
            <Typography
              variant="body"
              fontSize={{ xs: "13px", sm: "15px", md: "15px" }}
              // lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className=" pt-2 opacity-70 line-clamp-3 md:line-clamp-5"
              title="Click to view more"
            >
              {lastForumGist.content}
            </Typography>
            <ReadMore
              to={`/community/${lastForumGist.slug}/${lastForumGist.id}`}
              className="cursor-pointer sidebar-glide w-[30%] md:w-[16%]"
              label={"Read More"}
            />
            <Box>
              <PersonOutline className="!text-xl" />

              <Typography
                variant="caption"
                fontSize={{ xs: "13px", sm: "13px" }}
                lineHeight={{ xs: "200%", sm: "180%" }}
                letterSpacing={"0.02em"}
                className="capitalize pl-2"
              >
                Administrator
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
