import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { Box, Typography } from "@mui/material";
import SearchBibleVerse from "../global/SearchBibleVerse";

const BibleSearch = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/new-bible.jpeg`}
        header="Bible Search"
        text="Explore, find, and study specific verses, passages, or topics for spiritual guidance, learning, and understanding within the Bible."
      />
      <Box className=" !mx-auto py-12 place-content-center">
        <Box className="!mx-auto w-full text-center">
          <Typography
            variant="subheader"
            fontSize={{ xs: "18px", sm: "26px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="pl-4  capitalize text-center"
          >
            Select Bible Passage
          </Typography>
        </Box>

        <SearchBibleVerse />
      </Box>
    </React.Fragment>
  );
};

export default BibleSearch;
