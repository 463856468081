import { Chat, EmojiEmotions, Favorite, ThumbUp } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useAuth } from "../../../context/app.context";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_COMMENT_REACTION,
  ADD_POST_REACTION,
  GET_COMMENT_REACTIONS,
  GET_POST_REACTIONS,
} from "../../../graphql/other-queries";

const ForumReaction = ({ model }) => {
  const { isAuthenticated, userData } = useAuth();
  // console.log("Model", model.__typename);
  const [newmodel, setnewmodel] = React.useState("");
  const [likeCount, setlikeCount] = React.useState(0);
  const [loveCount, setloveCount] = React.useState(0);
  const [laughCount, setlaughCount] = React.useState(0);

  //   Filter Commennts
  const totalComment = model && model.comments ? model.comments.length : 0;

  const {
    loading: reactionLoading,
    data: postReactionData,
    error: reactionError,
    refetch: refetchPost,
  } = useQuery(GET_POST_REACTIONS, {
    variables: { categoryId: model.id },
    pollInterval: 500,
  });
  const {
    loading: commentLoading,
    data: commentReactionData,
    error: commentError,
    refetch: refetchComment,
  } = useQuery(GET_COMMENT_REACTIONS, {
    variables: { categoryId: model.id },
    pollInterval: 500,
  });

  // Post Reactions Start
  //   Filter reactions by like
  const totalPostLike = postReactionData?.getPostReaction?.filter(
    (like) => like.type === "like"
  ).length;

  //   Filter reactions by like
  const totalPostLaugh = postReactionData?.getPostReaction?.filter(
    (laugh) => laugh.type === "laugh"
  ).length;

  //   Filter reactions by like
  const totalPostLove = postReactionData?.getPostReaction?.filter(
    (love) => love.type === "love"
  ).length;
  // Post Reactions End

  // Comment Reactions Start
  //   Filter reactions by like
  const totalCommentLike = commentReactionData?.getCommentReaction?.filter(
    (like) => like.type === "like"
  ).length;

  //   Filter reactions by like
  const totalCommentLaugh = commentReactionData?.getCommentReaction?.filter(
    (laugh) => laugh.type === "laugh"
  ).length;

  //   Filter reactions by like
  const totalCommentLove = commentReactionData?.getCommentReaction?.filter(
    (love) => love.type === "love"
  ).length;

  // Comment Reactions Ends

  React.useEffect(() => {
    setnewmodel(model.__typename);
  }, [model]);

  React.useEffect(() => {
    if (newmodel === "Post" && postReactionData) {
      setlikeCount(totalPostLike);
      setlaughCount(totalPostLaugh);
      setloveCount(totalPostLove);
    } else if (newmodel === "Comment" && commentReactionData) {
      setlikeCount(totalCommentLike);
      setlaughCount(totalCommentLaugh);
      setloveCount(totalCommentLove);
    }
  }, [newmodel, postReactionData, commentReactionData]);

  // Add a comment on Post
  const [
    addPostReaction,
    { loading: loadingPo, error: errorPo, data: dataPo },
  ] = useMutation(ADD_POST_REACTION);
  // Add a comment on Post
  const [
    addCommentReaction,
    { loading: loadingCo, error: errorCo, data: dataCo },
  ] = useMutation(ADD_COMMENT_REACTION);

  const handleLike = async (event) => {
    event.preventDefault();

    if (newmodel === "Post") {
      try {
        const { data } = await addPostReaction({
          variables: {
            categoryId: model.id,
            type: "like",
            userId: userData.id,
          },
        });
        refetchPost();
        // Handle successful form submission (if needed)
        console.log("Liked Post success:", data);
      } catch (error) {
        // Display error alert for server error
        console.error("Like Post error:", error.message);
      }
    } else if (newmodel === "Comment") {
      try {
        const { data } = await addCommentReaction({
          variables: {
            categoryId: model.id,
            type: "like",
            userId: userData.id,
          },
        });
        refetchComment();
        // Handle successful form submission (if needed)
        console.log("Liked Comment success:", data);
      } catch (error) {
        // Display error alert for server error
        console.error("Like Comment error:", error.message);
      }
    }
  };

  const handleLove = async (event) => {
    event.preventDefault();

    if (newmodel === "Post") {
      try {
        const { data } = await addPostReaction({
          variables: {
            categoryId: model.id,
            type: "love",
            userId: userData.id,
          },
        });
        refetchPost();
        // Handle successful form submission (if needed)
        console.log("Loved Post success:", data);
      } catch (error) {
        // Display error alert for server error
        console.error("Love Post error:", error.message);
      }
    } else if (newmodel === "Comment") {
      try {
        const { data } = await addCommentReaction({
          variables: {
            categoryId: model.id,
            type: "love",
            userId: userData.id,
          },
        });
        refetchComment();
        // Handle successful form submission (if needed)
        console.log("Loved Comment success:", data);
      } catch (error) {
        // Display error alert for server error
        console.error("Love Comment error:", error.message);
      }
    }
  };

  const handleLaugh = async (event) => {
    event.preventDefault();

    if (newmodel === "Post") {
      try {
        const { data } = await addPostReaction({
          variables: {
            categoryId: model.id,
            type: "laugh",
            userId: userData.id,
          },
        });
        refetchPost();
        // Handle successful form submission (if needed)
        console.log("Laughd Post success:", data);
      } catch (error) {
        // Display error alert for server error
        console.error("Laugh Post error:", error.message);
      }
    } else if (newmodel === "Comment") {
      try {
        const { data } = await addCommentReaction({
          variables: {
            categoryId: model.id,
            type: "laugh",
            userId: userData.id,
          },
        });
        refetchComment();
        // Handle successful form submission (if needed)
        console.log("Laughed Comment success:", data);
      } catch (error) {
        // Display error alert for server error
        console.error("Laugh Comment error:", error.message);
      }
    }
  };

  return (
    <Box
      display={"flex"}
      alignItems="center"
      className="md:place-content-start"
    >
      <Box>
        <IconButton
          type="submit"
          color="primary"
          className=" !text-lg"
          onClick={handleLaugh}
        >
          {/* <EmojiEmotions className="!text-[#ff9800] !text-lg " /> */}
          😀
          <Typography
            variant="subtitle"
            // fontSize={{ xs: "20px", sm: "25px" }}
            // lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            color="primary"
            className="opacity-80 pl-1"
          >
            {laughCount}
          </Typography>
        </IconButton>
      </Box>
      <Box>
        <IconButton
          type="submit"
          color="primary"
          className=" !text-lg"
          onClick={handleLike}
        >
          {/* <ThumbUp className="!text-[#24a0ed] !text-lg " /> */}
          👍
          <Typography
            variant="subtitle"
            // fontSize={{ xs: "20px", sm: "25px" }}
            // lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            color="primary"
            className="opacity-80 pl-1"
          >
            {likeCount}
          </Typography>
        </IconButton>
      </Box>
      <Box>
        <IconButton
          type="submit"
          color="primary"
          className=" !text-lg"
          onClick={handleLove}
        >
          {/* <Favorite className="!text-[#ff0021] !text-lg " /> */}
          ❤️
          <Typography
            variant="subtitle"
            // fontSize={{ xs: "20px", sm: "25px" }}
            // lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            color="primary"
            className="opacity-80 pl-1"
          >
            {loveCount}
          </Typography>
        </IconButton>
      </Box>
      {totalComment !== 0 ? (
        <Box>
          <IconButton
            type="submit"
            color="primary"
            className="!text-lg"
            // onClick={handleLove}
          >
            {/* <Favorite className="!text-[#ff0021] !text-lg " /> */}
            💬
            <Typography
              variant="subtitle"
              // fontSize={{ xs: "20px", sm: "25px" }}
              // lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              color="primary"
              className="opacity-80 pl-1"
            >
              {totalComment}
            </Typography>
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default ForumReaction;
