import React from "react";
import { GET_POSTS } from "../../../graphql/other-queries";
import { useQuery } from "@apollo/client";
import {
  Box,
  CardMedia,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { formatDay } from "../../../hook/date-format";
import ForumReaction from "./ForumReaction";
import { sortByCreatedAtDescending } from "../../../hook/arrange";

const ForumCard = ({ sortCriteria }) => {
  const [word, setWord] = React.useState("");
  let location = useLocation();

  // State variables to manage pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10; // Change this to the number of items you want per page

  // Query to fetch getPosts data
  const { loading, error, data } = useQuery(GET_POSTS, { pollInterval: 500 });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  // Filter the data based on the keyword
  const filteredData = data.getPosts.filter(
    (item) =>
      item.title.toLowerCase().includes(word.toLowerCase()) ||
      item.content.toLowerCase().includes(word.toLowerCase()) ||
      item.createdAt.toLowerCase().includes(word.toLowerCase())
  );

  // Use the sorting function to sort the data
  sortByCreatedAtDescending(filteredData);

  const categoryFilteredData =
    sortCriteria === "all" || sortCriteria === ""
      ? filteredData // Return all data when sortCriteria is "All" or empty
      : filteredData.filter((item) => item.category === sortCriteria);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = categoryFilteredData.slice(startIndex, endIndex);

  return (
    <React.Fragment>
      <Box className="">
        <List
          sx={{
            width: "100%",
            // maxWidth: 360,
            // maxHeight: 815,
            overflowY: "auto",
            // bgcolor: "#fff",
          }}
        >
          {currentPageData.length > 0 ? (
            currentPageData.map((post) => (
              <React.Fragment key={post.id}>
                <ListItem
                  className="my-4 hover:shadow-xl hover:z-30 hover:drop-shadow-xl bg-white !no-underline"
                  // onClick={(e) => setPostID(post.id)}
                  key={post.id}
                >
                  <Link
                    to={`${location.pathname}/${post.slug}/${post.id}`}
                    className="cursor-pointer"
                  >
                    <Box className="hidden md:block h-40 w-[300px] pr-4 mr-auto ml-0">
                      <CardMedia
                        component="img"
                        src={post.contentpic}
                        alt="Event Image"
                        className="object-cover h-full w-full"
                      />
                    </Box>
                  </Link>
                  <ListItemText
                    className="line-clamp-2"
                    primary={
                      <Box className="grid">
                        <Link
                          to={`${location.pathname}/${post.slug}/${post.id}`}
                          className="cursor-pointer !no-underline"
                        >
                          <Typography
                            variant="header"
                            lineHeight={{ xs: "200%", sm: "180%" }}
                            fontSize={{ xs: "20px", sm: "20px" }}
                            letterSpacing={"0.02em"}
                            color="text.primary"
                            className="line-clamp-2 capitalize"
                          >
                            {post.title}
                          </Typography>
                        </Link>
                        <Box className="grid md:flex items-center">
                          <Box>
                            {/* <Typography
                    variant="body"
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    fontSize={{ xs: "13px", sm: "13px" }}
                    letterSpacing={"0.02em"}
                    color="text.primary"
                    className="pt-2 pr-2"
                  >
                    {post.author}
                  </Typography> */}
                            <Typography
                              variant="body"
                              lineHeight={{ xs: "200%", sm: "180%" }}
                              fontSize={{ xs: "13px", sm: "13px" }}
                              letterSpacing={"0.02em"}
                              color="text.primary"
                              className="pt-2"
                            >
                              {formatDay(post.createdAt)}
                            </Typography>
                          </Box>
                          <Box className="">
                            <ForumReaction model={post} />
                          </Box>
                        </Box>
                      </Box>
                    }
                    secondary={
                      <Box>
                        <Typography
                          variant="body"
                          lineHeight={{ xs: "200%", sm: "180%" }}
                          fontSize={{ xs: "13px", sm: "13px" }}
                          letterSpacing={"0.02em"}
                          color="text.primary"
                          className="opacity-60"
                        >
                          {post.content}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>

                <Divider className="!-mt-2" />
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body" color="primary">
              No Post available
            </Typography>
          )}
          {/* Pagination controls */}
          <Box className="text-center mt-4 mx-auto flex  place-content-center ">
            {currentPage > 1 && (
              // <CRCbutton
              //   variant="outlined"
              //   color="primary"
              //   onClick={() => setCurrentPage(currentPage - 1)}
              //   label={"Prev"}
              // />
              <IconButton
                color="primary"
                aria-label="Next"
                onClick={() => setCurrentPage(currentPage - 1)}
                className="ml-0 !mr-auto"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
              </IconButton>
            )}
            {endIndex < categoryFilteredData.length && (
              <IconButton
                color="primary"
                aria-label="Next"
                onClick={() => setCurrentPage(currentPage + 1)}
                className="mr-0 !ml-auto"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              </IconButton>
            )}
          </Box>

          {/* <Box className="mx-auto mt-8 w-[30%]">
            <CRCbutton label={"Load More"} onClick={loadMoreItems} />
          </Box> */}
        </List>
      </Box>
    </React.Fragment>
  );
};

export default ForumCard;
