import React, { useState } from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import PersonnelCard from "../components/About/PersonnelCard";
import AboutCEO from "../components/About/AboutCEO";
import MainCard from "../components/About/MainCard";

const About = () => {
  // const [tech_team, setTech_team] = useState(["Oyeleye", "Prophet Victor "]);
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={
          "https://alchetron.com/cdn/celestial-city-imeko-12c5442c-2818-4a2a-9f2e-222eadbcf4e-resize-750.jpeg"
          // "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
        }
        header="About"
        text="We are eager to share valuable insights and pertinent information about this religious organization, providing a comprehensive understanding with key details."
      />
      <MainCard />
      <AboutCEO />
      <br />
      <br />
      <PersonnelCard
        title="Introducing our team"
        content="Our Dedicated Team: The Driving Force Behind Our Achievements and
          Exceptional Service."
        personnelType="crc-member"
      />
      <br />
      <br />
      <PersonnelCard
        title="CRC Media team"
        content="Our Media Team: Creators of Stunning Visuals and Engaging Designs."
        personnelType="crc-media"
      />
      <br />
      <br />
      <PersonnelCard
        title="CRC Technical Team"
        content="CRC Tech Team: Architects of Digital Excellence and Technological Innovation."
        personnelType="crc-member"
        tech="tech_team"
      />
    </React.Fragment>
  );
};

export default About;
