import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { JustificationCard } from "../components/About/JustificationCard";

const BiblicalJustification = () => {
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={
          "https://alchetron.com/cdn/celestial-city-imeko-12c5442c-2818-4a2a-9f2e-222eadbcf4e-resize-750.jpeg"
        }
        header="Biblical Justifications"
        text="Celestial Church of Christ is firmly grounded in the Scriptures, emphasizing faithful adherence to biblical teachings, righteousness, and ethical living."
      />

      <JustificationCard />
    </React.Fragment>
  );
};

export default BiblicalJustification;
