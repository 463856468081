import React, { Fragment } from "react";
import { yearlyItems } from "../../static/static";
import { Box, Typography } from "@mui/material";

export const AnnualEvents = () => {
  return (
    <Fragment>
      {/* <Box className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <img
          src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664984357/salvation/romans_iq12op.jpeg"
          alt="Biblical Justification Of CCC Doctrines"
          className="w-full h-72 md:h-[600px] object-cover "
        />
      </Box> */}

      <div className="overflow-x-auto relative sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        <table className="w-full text-sm text-left px-12">
          <thead className=" bg-[#263238]">
            <tr>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "20px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Events
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "20px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Period
                </Typography>
              </th>
            </tr>
          </thead>
          {yearlyItems.map((event, index) => (
            <tbody>
              <tr className="bg-white border-b border-2 text-primary ">
                <th scope="row" className="py-4 px-6" key={index}>
                  <Typography
                    variant="caption"
                    fontSize={{ xs: "20px", sm: "20px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className="text-start"
                  >
                    {event.program}
                  </Typography>
                </th>
                <td className="py-4 px-6">
                  <Typography
                    variant="caption"
                    fontSize={{ xs: "16px", sm: "16px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className="text-start opacity-70"
                  >
                    {event.period}
                  </Typography>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </Fragment>
  );
};
