import React from "react";
import MainPagesHeader from "../custom/Layout/MainPagesHeader";
import { Box } from "@mui/material";
import PDFViewer from "../custom/Layout/PDFViewer";

const Constitutions = () => {
  let pdfFileUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/designs/CCC-Contitution.pdf`;
  return (
    <React.Fragment>
      <MainPagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/rules_lbjqwa.jpeg`}
        header="CCC Constitutions"
        text="Our constitution is a document that outlines our congregation's fundamental beliefs, organizational structure, and governance principles, serving as our organizational framework"
      />
      <Box className=" !mx-auto py-12 place-content-center">
        {/* Embed React PDF here */}
        <PDFViewer fileUrl={pdfFileUrl} />
      </Box>
    </React.Fragment>
  );
};

export default Constitutions;
