import React, { Fragment } from "react";
import { Box, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { forbiddenList, sanctuaryRulesList } from "../../static/static";
import { Link } from "react-router-dom";

export const CCCHistoryCardMini = ({
  text,
  miniTitle,
  title,
  listData = [],
}) => {
  return (
    <Box className="pt-8 grid">
      <Typography
        variant="header"
        fontSize={{ xs: "25px", sm: "30px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        className=" capitalize"
        title="Click to view more"
      >
        {title}
      </Typography>
      <Typography
        variant="subheader"
        fontSize={{ xs: "20px", sm: "20px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        className=" capitalize"
        title="Click to view more"
      >
        {miniTitle}
      </Typography>
      <Typography
        variant="body"
        fontSize={{ xs: "16px", sm: "16px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        className="opacity-60 text-start"
      >
        {text}
      </Typography>
      <List className="">
        {listData.map((item) => (
          <ListItem key={item.id} component="div">
            <ListItemIcon>{item.id}</ListItemIcon>
            <Typography
              variant="body"
              fontSize={{ xs: "16px", sm: "16px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="opacity-60 text-start"
            >
              {item.text}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export const RulesTenetsCard = () => {
  return (
    <Fragment>
      <Box className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <img
          src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/rules_lbjqwa.jpeg`}
          alt="CCC Rules & Tenets"
          className="w-full h-72 md:h-[600px] object-cover "
        />
        {/* <Box className=" space-x-4">
          <Link
            to={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/CCC-Contitution.pdf`}
            target="_blank"
            download
          >
            Download CCC Constitutions
          </Link>
          <Link
            to={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/CCC-Hymns.pdf`}
            target="_blank"
            download
          >
            Download CCC Hymnals
          </Link>
        </Box> */}
        <CCCHistoryCardMini
          title=" Code of Conduct"
          text="The following is a summary of rules, regulations and tenets of Celestial Church of Christ."
        />
        <CCCHistoryCardMini
          miniTitle="Members are forbidden to:"
          listData={forbiddenList}
        />
        <CCCHistoryCardMini
          title="Spiritual Code of Conduct"
          listData={sanctuaryRulesList}
        />
        <Typography
          variant="caption"
          fontSize={{ xs: "16px", sm: "16px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className=" text-start"
        >
          May the good Lord continue to guide and bless your household as you
          endeavor to make His Holy Sanctuary a place of reverence, honor and
          fear. He is after all, the ALMIGHTY GOD!
        </Typography>
      </Box>
    </Fragment>
  );
};
