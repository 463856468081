import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const CRCTextField = ({
  label,
  type,
  onChange,
  startAdornment,
  rows,
  multiline,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    // Update the input value
    setInputValue(newValue);

    // Check for empty input and set error state
    if (newValue.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }

    // For email type, validate email format
    if (type === "email") {
      const isValidEmail =
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(newValue);
      setError(!isValidEmail);
    }

    // Call the provided onChange function
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = () => {
    // Check for empty input on blur and set error state
    if (inputValue.trim() === "") {
      setError(true);
    }
  };

  return (
    <Box
      component="div"
      sx={{
        "& .MuiTextField-root": {
          m: 1,
          width: "100%",
          fontFamily: "Bricolage Grotesque",
          maxWidth: "100%",
        },
      }}
      noValidate
      autoComplete="on"
    >
      <TextField
        id="standard"
        label={label}
        variant="standard"
        type={showPassword ? "text" : type}
        onChange={handleInputChange}
        onBlur={handleBlur}
        error={error}
        required
        multiline={multiline}
        rows={rows}
        helperText={
          error ? (
            <Typography
              variant="subtitle"
              color="error"
              fontSize={{ xs: "10px", sm: "13px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
            >
              {type === "email"
                ? "Please enter a valid email address."
                : `Please enter a valid ${label.toLowerCase()}.`}
            </Typography>
          ) : (
            ""
          )
        }
        InputProps={{
          sx: {
            fontFamily: "Bricolage Grotesque",
          },
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          minLength: 1,
        }}
      />
    </Box>
  );
};

export default CRCTextField;
