import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ForgotPassForm from "./ForgotPassForm";
import { useAuth } from "../../context/app.context";

const ForgotPassCard = () => {
  const { isAuthenticated, logoutUser, userData } = useAuth();
  return (
    <React.Fragment>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ bgcolor: "secondary.light", p: 3 }}
            className=" flex place-content-center mx-auto"
          >
            <IconButton
              variant="gradient"
              size="lg"
              className="pointer-events-none mb-6 rounded-full "
            >
              <LockOutlinedIcon className="text-[#fff]" />
            </IconButton>
          </Avatar>
          <Typography
            variant="subheader"
            className="mx-auto place-content-center flex pt-2 pb-8"
          >
            {userData.firstname === "" ? "Forgot Password?" : "Change Password"}
          </Typography>

          <ForgotPassForm />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ForgotPassCard;
