import React from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import { Avatar, Typography } from "@mui/material";
import { getRandomColor } from "../../../static/static";
import ForumReaction from "./ForumReaction";

const OtherForumComments = ({ comment }) => {
  // Output: "11:09 PM" (or equivalent based on the provided time zone offset)

  return (
    <React.Fragment>
      <Box className="flex ml-4 place-content-start py-8">
        <Box
          className=""
          display={"flex"}
          justifyContent={"end"}
          alignItems={"end"}
        >
          <Avatar
            alt={comment.firstname}
            src={comment.avatar}
            sx={{ width: 40, height: 40, bgcolor: getRandomColor }}
          />
        </Box>
        <Box className=" bg-white w-[80%] md:w-[80%] lg:w-[60%] shadow-lg drop-shadow-lg rounded-md ml-2 p-4">
          <Typography
            variant="body"
            color="primary"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            {comment.content}
          </Typography>

          <Box className="">
            {comment.contentpic && (
              <img
                src={`${comment.contentpic}`}
                alt={`${comment.firstname} ${comment.lastname}`}
                className=" ml-auto object-cover w-[300px] h-[200px] md:w-[350px] md:h-[250px] lg:w-[350px] lg:h-[250px] p-1 border-2 border-ash-100 border-opacity-50"
              />
            )}
          </Box>

          <Box
            className=" place-content-end right-0 flex gap-2 overflow-hidden  "
            justifyContent="start"
            alignItems="center"
          >
            <Box className="mr-auto ml-0">
              <Typography
                variant="caption"
                lineHeight={{ xs: "230%", sm: "102%" }}
                letterSpacing={"0.1em"}
                fontSize={{ xs: "13px", sm: "13px" }}
                className=" opacity-60"
              >
                {`${comment.firstname} ${comment.lastname} `} |{" "}
                {dayjs(comment.createdAt).format("h:mma")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="-mt-1 flex place-content-start">
        <ForumReaction model={comment} />
      </Box>
    </React.Fragment>
  );
};

export default OtherForumComments;
