import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LOGIN_USER } from "../../graphql/user-queries";
import { useAuth } from "../../context/app.context";
import { useContext } from "react";
import { LoaderContext } from "../../context/loader.context";
import PageLoader from "../../custom/Layout/PageLoader";
import { CONTACT_US } from "../../graphql/other-queries";

const ContactForm = () => {
  let navigate = useNavigate();
  const [submitClicked, setSubmitClicked] = React.useState(false);
  const [msg, setmsg] = useState("");

  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [formData, setFormData] = useState({
    email: "",
    fname: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  // Using the LOGIN_ADMIN query conditionally based on submitClicked
  const { loading, error, data } = useQuery(CONTACT_US, {
    variables: formData,
    skip: !submitClicked,
    onCompleted: (data) => {
      if (data && data.contactUs) {
        const email = data.contactUs.token;
        console.log("Email", email);
        // senMailToEmail(token)
        closeLoader();
        setmsg("We will be in touch soon");
        setTimeout(() => {
          navigate(`/`);
        }, 3000);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      console.log("Empty fields:", emptyFields);
      return;
    }

    // console.log("Form data:", formData);
    setSubmitClicked(true);
    openLoader();
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <Alert variant="filled" severity="error" className="mt-4">
          {error.message === "Unexpected error."
            ? "Email not found"
            : error.message}
        </Alert>
      )}
      {msg === "We will be in touch soon" ? (
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      ) : null}
      <FormGroup className="w-full">
        <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className=" w-[100%]">
          <Grid item xs={6} md={6}>
            <CRCTextField
              label="Full Name"
              name="fname"
              type="name"
              onChange={(e) => {
                // setFormData({ ...formData, password: e.target.value })
                handleInputChange("fname", e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <CRCTextField
              label="Email"
              name="email"
              type="email"
              onChange={(e) => {
                // setFormData({ ...formData, email: e.target.value })
                handleInputChange("email", e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <CRCTextField
            label="Subject"
            name="Subject"
            type="name"
            onChange={(e) => {
              setFormData({ ...formData, subject: e.target.value });
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <CRCTextField
            label="Message"
            name="message"
            type="name"
            multiline
            rows={4}
            onChange={(e) => {
              // setFormData({ ...formData, password: e.target.value })
              handleInputChange("message", e.target.value);
            }}
          />
        </Grid>

        <Box className="mx-auto mt-8">
          <Grid item xs={12} md={12}>
            <CRCbutton label={"Send Message"} type={"submit"} />
          </Grid>
        </Box>
      </FormGroup>
    </form>
  );
};

export default ContactForm;
