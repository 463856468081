import { useState, useEffect } from "react";

function useUserIP() {
  const [userIP, setUserIP] = useState("");

  useEffect(() => {
    // Fetch the user's IP address
    fetch("https://api64.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setUserIP(data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  }, []);

  return userIP;
}

export default useUserIP;
