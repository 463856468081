import { Box, Button, Link, Typography } from "@mui/material";
import React, { Fragment } from "react";

const CRCbutton = ({ label, icon, onClick, type, rounded }) => {
  return (
    <Fragment>
      <Box
        component="div"
        noValidate
        autoComplete="on"
        sx={{
          "& .MuiButton-root": {
            m: 1,
            height: "6ch",
            width: "100%", // Set width to 100%
          },
        }}
      >
        <Link>
          <Button
            variant="contained"
            endIcon={icon}
            onClick={onClick}
            className={`${rounded && "!rounded-2xl"} !w-[100%]`}
            sx={{
              "& .MuiButton": { m: 1, width: "100%" }, // Set width to 100%
            }}
            type={type}
          >
            <Typography
              variant="body"
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              color={"#fff"}
              className="capitalize"
            >
              {label}
            </Typography>
          </Button>
        </Link>
      </Box>
    </Fragment>
  );
};

export default CRCbutton;
