import React from "react";
import PlainUserHeader from "../settings/PlainUserHeader";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import FormSettings from "../settings/FormSettings";
import AvatarSettings from "../settings/AvatarSettings";

const Settings = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <PlainUserHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/stairs_xvcwfq.jpg`}
      >
        <Box className="mt-24 md:mt-16 mx-auto flex place-content-center">
          <Typography
            variant="header"
            color="primary"
            fontSize={{ xs: "20px", sm: "30px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            User Settings
          </Typography>
        </Box>

        <Box className="pt-5" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="User Details" className="" />
            <Tab label="User Avatar" />
          </Tabs>
        </Box>

        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <FormSettings />}
          {value === 1 && <AvatarSettings />}
        </Box>
      </PlainUserHeader>
    </React.Fragment>
  );
};

export default Settings;
