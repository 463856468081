import { Typography } from "@mui/material";
import React from "react";
import { formatDay } from "../../../hook/date-format";
import { Link } from "react-router-dom";

const UserArticlesTable = ({ data }) => {
  //   console.log(data);
  return (
    <React.Fragment>
      <div className="overflow-x-auto relative sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        <table className="w-full text-sm text-left px-12">
          <thead className=" bg-[#263238]">
            <tr>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Articles
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Status
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Published
                </Typography>
              </th>
            </tr>
          </thead>
          {data &&
            data.map((article, index) => (
              <tbody key={index}>
                <tr className="bg-white border-b border-2 text-primary hover:bg-gray-200">
                  <th scope="row" className="py-4 px-6">
                    <Link
                      to={`${
                        article.status === "verified"
                          ? `/blog/${article.slug}/${article.id}`
                          : "/profile"
                      } `}
                      className=" !no-underline sidebar-glide"
                    >
                      <Typography
                        variant="caption"
                        fontSize={{ xs: "13px", sm: "16px", md: "20px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        className="text-start"
                      >
                        {article.title}
                      </Typography>
                    </Link>
                  </th>
                  <td className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className={`"text-start opacity-70" ${
                        article.status === "verified"
                          ? "!text-green-700"
                          : "!text-red-600"
                      }`}
                    >
                      {article.status}
                    </Typography>
                  </td>
                  <td className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="text-start opacity-70"
                    >
                      {formatDay(article.createdAt)}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      </div>
    </React.Fragment>
  );
};

export default UserArticlesTable;
