import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ReadMore = ({ to, onClick, onChange, className, label }) => {
  return (
    <Link
      to={to}
      onClick={onClick}
      onChange={onChange}
      className={` !no-underline ${className}`}
    >
      <Typography
        variant="caption"
        fontSize={{ xs: "13px", sm: "13px" }}
        lineHeight={{ xs: "200%", sm: "180%" }}
        letterSpacing={"0.02em"}
        className=" capitalize text-start line-clamp-3 flex"
      >
        {label}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 pl-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
          />
        </svg>
      </Typography>
    </Link>
  );
};

export default ReadMore;
